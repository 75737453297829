<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Sistemista</h1>
      </div>

      <router-link to="/projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <div class="flex gap-4 mb-6 items-end w-[100%]">
      <div class="w-full">
        <label class="text-corBase">Nome</label>
        <input type="text" class="input-form-chapa" v-model="projetista_sistemista" placeholder="Digite aqui...">
      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaProjetistaSistemista(1)">Pesquisar</button>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/projetista-sistemista/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-1 rounded-sm px-4 mt-2 mr-2">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Código
            </th>
            <th scope="col" class="px-8 py-3">
              Nome
            </th>
            <th scope="col" class="px-6 py-3">
              Empresa
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
            <th v-if="isadmin === true" scope="col" class="px-6 py-3">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(projetista, index) in lista_projetista_sistemista" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="projetista.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <td scope="row" class="px-6 py-3 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </td>
            <td class="px-6 py-3 text-sm text-center">
              {{ projetista.codigo }}
            </td>
            <td class="px-8 py-3">
              {{ projetista.nome }}
            </td>
            <td class="px-6 py-3">
              {{ projetista.empresa[0]?.nome_razao || 'Sem empresa definida.' }}
            </td>
            <template v-if="projetista.ativo === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="projetista.ativo === false">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs" v-if="isadmin === true">
              <a @click="selecionarProjetistaEditar(projetista)" href="#"
                class="font-medium text-corBase pl-2 hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_projetista_sistemista.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
      <EditarProjetistaSistemista v-if="mostrarModal === true" :projetistaSistemista="projetistaSelecionado"
        @fecharModal="toggleModal()" @atualizarLista="getListaProjetistaSistemista(paginaAtual)" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EditarProjetistaSistemista from "@/components/midais/Editar/projeto/EditarProjetistaSistemista";
import Pagination from '@/utils/Pagination.vue';

export default {
  name: "ListaProjetistaSistemista",

  components: {
    EditarProjetistaSistemista,
    Pagination
  },

  data() {
    return {
      lista_projetista_sistemista: [],
      projetista_sistemista: '',

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: 0,
      error: [],

      projetistaSelecionado: null,
      mostrarModal: false,
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

  },

  created() {
  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaProjetistaSistemista(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`projeto/projetista-sistemista/?nome=${this.projetista_sistemista}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        console.log(response.data.results)
        this.lista_projetista_sistemista = response.data.results
        this.countItens = response.data.count

      } catch (error) {
        console.error(error)
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina
      this.getListaProjetistaSistemista(pagina)
    },

    selecionarProjetistaEditar(projetista) {
      this.projetistaSelecionado = projetista
      this.toggleModal()
    },

    toggleModal() {
      this.mostrarModal = !this.mostrarModal
    }
  }
}
</script>

<style lang="scss"></style>