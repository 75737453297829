<template>

  <div class="mt-6 block md:hidden">
    <h1 class="text-corBase mb-2 text-xl">Navegar pelas</h1>
    <div class="relative" @click="dropdownDados = !dropdownDados">
      <button class="bg-screenCor px-3 py-1 text-corBase font-bold" v-if="!dropdownDados">
        Opções
      </button>
      <button class="bg-screenCor px-3 py-1 text-corBase font-bold" v-if="dropdownDados">
        Opções
      </button>

      <div class="absolute left-0 mt-1 w-40 bg-white border border-gray-300 shadow-lg z-[9999]" v-if="dropdownDados">
        <!-- Conteúdo do dropdown -->
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessaoDireto('dados')">
          Dados Iniciais
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('configuracoes')">
          Configurações
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('calculos')">
          Cálculos
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('validacoes')">
          Especificações
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('imagens')">
          Imagens
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('produtos')">
          Produtos
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessaoDireto('ensaio')">
          Ensaio
        </button>

      </div>
    </div>
  </div>


  <!-- NAVEGAÇÃO PRINCIPAL DESKTOP -->
  <div class="nav-principal-projetos">

    <div class="w-full block justify-center">
      <div class="flex flex-wrap gap-2 justify-center">
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('dados')
            ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessaoDireto('dados')">
          Dados Iniciais
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('configuracoes')
            ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('configuracoes')">
          Configurações
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('calculos'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('calculos')">
          Cálculos
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('validacoes'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('validacoes')">
          Especificações
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('imagens'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('imagens')">
          Imagens
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('produtos'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('produtos')">
          Produtos
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('ensaio'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessaoDireto('ensaio')">
          Ensaio
        </button>

      </div>
    </div>
  </div>

  <div class="flex justify-center items-center w-full p-2">

    <div v-if="sessoesAtivas.includes('configuracoes')" class="flex gap-2">
      <button class="btn-nav-projeto-inativo" v-if="opcoes.nota_fiscal === false"
        @click.prevent="emitEvent('nota_fiscal')">
        Nota Fiscal
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.nota_fiscal === true"
        @click.prevent="emitEvent('nota_fiscal')">
        Nota Fiscal
      </button>

      <button class="btn-nav-projeto-inativo" v-if="opcoes.vendas === false"
        @click.prevent="emitEvent('vendas')">
        Vendas
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.vendas === true"
        @click.prevent="emitEvent('vendas')">
        Vendas
      </button>

      <button class="btn-nav-projeto-inativo" v-if="opcoes.producao === false"
        @click.prevent="emitEvent('producao')">
        Produção
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.producao === true"
        @click.prevent="emitEvent('producao')">
        Produção
      </button>

      <button class="btn-nav-projeto-inativo" v-if="opcoes.instalacao === false"
        @click.prevent="emitEvent('instalacao')">
        Instalação
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.instalacao === true"
        @click.prevent="emitEvent('instalacao')">
        Instalação
      </button>

    </div>

    <div v-if="sessoesAtivas.includes('calculos')" class="flex gap-2">

      <button class="btn-nav-projeto-inativo" v-if="opcoes.estruturas === false" @click.prevent="emitEvent('estruturas')">
        Estruturas
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.estruturas === true" @click.prevent="emitEvent('estruturas')">
        Estruturas
      </button>

      <button class="btn-nav-projeto-inativo" v-if="opcoes.formulas === false" @click.prevent="emitEvent('formulas')">
        Fórmulas
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.formulas === true" @click.prevent="emitEvent('formulas')">
        Fórmulas
      </button>

      <button class="btn-nav-projeto-inativo" v-if="opcoes.variaveis === false" @click.prevent="emitEvent('variaveis')">
        Variáveis
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.variaveis === true" @click.prevent="emitEvent('variaveis')">
        Variáveis
      </button>

    </div>


    <div v-if="sessoesAtivas.includes('produtos')" class="flex gap-2 flex-wrap sm:flex-nowrap">
  <button
    class="btn-nav-projeto-inativo"
    v-if="opcoes.perfis === false"
    @click.prevent="emitEvent('perfis')"
  >
    Perfis
  </button>
  <button
    class="btn-nav-projeto-ativo"
    v-if="opcoes.perfis === true"
    @click.prevent="emitEvent('perfis')"
  >
    Perfis
  </button>

  <button
    class="btn-nav-projeto-inativo"
    v-if="opcoes.componentes === false"
    @click.prevent="emitEvent('componentes')"
  >
    Componentes
  </button>
  <button
    class="btn-nav-projeto-ativo"
    v-if="opcoes.componentes === true"
    @click.prevent="emitEvent('componentes')"
  >
    Componentes
  </button>

  <button
    class="btn-nav-projeto-inativo"
    v-if="opcoes.vidros === false"
    @click.prevent="emitEvent('vidros')"
  >
    Vidros
  </button>
  <button
    class="btn-nav-projeto-ativo"
    v-if="opcoes.vidros === true"
    @click.prevent="emitEvent('vidros')"
  >
    Vidros
  </button>

  <button
    class="btn-nav-projeto-inativo"
    v-if="opcoes.chapas_telas === false"
    @click.prevent="emitEvent('chapas_telas')"
  >
    Chapas/Telas
  </button>
  <button
    class="btn-nav-projeto-ativo"
    v-if="opcoes.chapas_telas === true"
    @click.prevent="emitEvent('chapas_telas')"
  >
    Chapas/Telas
  </button>

  <button
    class="btn-nav-projeto-inativo"
    v-if="opcoes.insumos === false"
    @click.prevent="emitEvent('insumos')"
  >
    Insumos
  </button>
  <button
    class="btn-nav-projeto-ativo"
    v-if="opcoes.insumos === true"
    @click.prevent="emitEvent('insumos')"
  >
    Insumos
  </button>

  <button
    class="btn-nav-projeto-inativo"
    v-if="opcoes.servicos === false"
    @click.prevent="emitEvent('servicos')"
  >
    Serviços
  </button>
  <button
    class="btn-nav-projeto-ativo"
    v-if="opcoes.servicos === true"
    @click.prevent="emitEvent('servicos')"
  >
    Serviços
  </button>
</div>


    <div v-if="sessoesAtivas.includes('imagens')" class="flex gap-2">
      <button class="btn-nav-projeto-inativo" v-if="opcoes.imagem_capa === false"
        @click.prevent="emitEvent('imagem_capa')">
        Imagem Capa
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.imagem_capa === true"
        @click.prevent="emitEvent('imagem_capa')">
        Imagem Capa
      </button>

      <button class="btn-nav-projeto-inativo" v-if="opcoes.imagens_variaveis === false"
        @click.prevent="emitEvent('imagens_variaveis')">
        Imagens Variáveis
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.imagens_variaveis === true"
        @click.prevent="emitEvent('imagens_variaveis')">
        Imagens Variáveis
      </button>
    </div>

    <div v-if="sessoesAtivas.includes('validacoes')">
      <button class="btn-nav-projeto-inativo" v-if="opcoes.validacoes === false"
        @click.prevent="emitEvent('validacoes')">
        Validações
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.validacoes === true" @click.prevent="emitEvent('validacoes')">
        Validações
      </button>
    </div>

    <div v-if="sessoesAtivas.includes('tipos_vidro')">
      <button class="btn-nav-projeto-inativo" v-if="opcoes.tipo_vidro === false"
        @click.prevent="emitEvent('tipo_vidro')">
        Tipos de Vidro
      </button>
      <button class="btn-nav-projeto-ativo" v-if="opcoes.tipo_vidro === true" @click.prevent="emitEvent('tipo_vidro')">
        Tipos de Vidro
      </button>
    </div>
  </div>


</template>

<script>

import axios from "axios";

export default {

  name: "NavgecacaoProjeto",

  emits: ['navegar-opcoes', 'toggleFormulasVariaveis', 'fecharTodasAbas'],

  data() {

    return {

      opcoes: [],
      dropdownDados: false,

      sessoesAtivas: [],

      formulas: false,

    }

  },

  created() {

    // cria as opções com valores de false

    this.opcoes = {

      nota_fiscal: false,
      vendas: false,
      producao: false,
      instalacao: false,

      estruturas: false,
      formulas: false,
      variaveis: false,

      validacoes: false,

      imagem_capa: false,
      imagens_variaveis: false,

      perfis: false,
      componentes: false,
      vidros: false,
      chapas_telas: false,
      insumos: false,
      servicos: false,


      ensaio: false,

      tipo_vidro: false,
      projeto: {},
    };


    setTimeout(() => {
          this.abrirSessaoDireto('dados')
        }, 500);

  },

  methods: {

    navegarOpcoesFormulasVariaveis(opcao) {
      this.formulas = false;
      this.variaveis = false;

      if (opcao === 'formulas') {
        this.opcoes[formulas] = true;
        this.$emit('toggleFormulasVariaveis', 'Fórmula')
      } else if (opcao === 'variaveis') {
        this.variaveis = false;
        this.$emit('toggleFormulasVariaveis', 'Variável')
        //this.getListaVariaveisLocais();
      }
    },

    abrirSessao(sessao) {
      this.sessoesAtivas = []
      this.$emit('fecharTodasAbas')
      this.fecharOpcoesFilhos()
      this.sessoesAtivas.push(sessao)
    },

    abrirSessaoDireto(sessao) {
      this.sessoesAtivas = []
      this.$emit('fecharTodasAbas')
      this.fecharOpcoesFilhos()
      this.sessoesAtivas.push(sessao)
      this.emitEvent(sessao)
    },

    fecharOpcoesFilhos() {
      Object.keys(this.opcoes).forEach((key) => {
        this.opcoes[key] = false;
      });
    },


    // função para navegar pelos botoes
    navegarOpcoes(nomeVariavel) {
      for (const key in this.opcoes) {
        if (key !== nomeVariavel) {
          this.opcoes[key] = false; // Define todas as opções como false
        }
      }
      this.opcoes[nomeVariavel] = !this.opcoes[nomeVariavel]; // Alterna a opção selecionada
    },

    // busca a lista de perfis relacionados ao projeto
    async getListaPerfis() {
      this.navegarOpcoes('perfis')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-perfil/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_perfis = response.data
          console.log(this.lista_perfis)
        })
        .catch(error => {
          console.log(error)
        })
    },

    // busa a lista de vidros relacionados ao projeto
    async getListaVidros() {
      this.navegarOpcoes('vidros')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-vidro/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_vidros = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    // busca a lista de componentes relacionados ao projeto
    async getListaComponentes() {
      this.navegarOpcoes('componentes')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-componente/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_componentes = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    // busca a lista de serviços relacionados ao projeto
    async getListaServicos() {
      this.navegarOpcoes('serviços')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-servico/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_servicos = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    // chamar evento no pai para trocar de aba
    emitEvent(opcao) {
      // this.sessoesAtivas = []
      this.$emit('navegar-opcoes', opcao);
      this.navegarOpcoes(opcao)
      console.log('Clicado com ' + opcao)
    }

  }

}
</script>

<style lang="scss" scoped>
.nav-principal-projetos {
  @apply hidden md:block md:w-full justify-center
}

.btn-nav-projeto-inativo {
  @apply flex items-center px-3 py-2 text-sm w-full sm:w-48 text-center justify-center bg-corBase text-screenCor rounded-md shadow-sm hover:bg-screenCor hover:text-corBase hover:shadow-md transition-colors;
}

.btn-nav-projeto-ativo {
  @apply flex items-center px-3 py-2 border border-corBase text-sm w-full sm:w-48 text-center justify-center bg-screenCor text-corBase font-semibold rounded-md shadow-md;
}

</style>