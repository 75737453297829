<template>
  <div>
    <!-- Modal de listagem de erros -->
    <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-50">
      <div class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
        <!-- Cabeçalho do modal -->
        <div class="flex justify-between items-center mb-4 border-b pb-2">
          <h2 class="text-lg font-bold text-red-600">Erros Capturados</h2>
          <button
            @click.prevent="$emit('fecharModal')"
            class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100"
          >
          <i class="fa-solid fa-x"></i>
          </button>
        </div>

        <!-- Lista de erros -->
        <div v-if="Object.keys(erros).length > 0">
          <ul class="list-disc pl-5 space-y-3">
            <li
              v-for="(mensagens, campo) in erros"
              :key="campo"
              class="text-sm text-gray-800"
            >
              <strong class="text-gray-900">{{ campo }}:</strong>
              <ul class="pl-4">
                <li v-for="(mensagem, index) in mensagens" :key="index" class="text-red-500">
                  {{ mensagem }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-else class="text-gray-500 text-sm">
          Nenhum erro capturado.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListagemErros",
  props: {
    erros: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  emits: ['fecharModal'],
};
</script>

<style scoped>
/* Estilos adicionais para animações */
</style>
