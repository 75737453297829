<template>
    <div>
        <h2 class="text-lg text-corBase font-bold">Dados</h2>
        <div class="grid grid-cols-2 gap-4">
            <div>
                <label>Nome</label>
                <input v-model="dadosComponente.nome" type="text" class="input-form">
            </div>
            <div>
                <label>Data de Criação</label>
                <input v-model="dadosComponente.criado_em_formatado" type="date" class="input-form" disabled>
            </div>
            <div>
                <label>Margem Lucro ML (%)</label>
                <input v-model="dadosComponente.margem_lucro_ml" type="number" min="0" class="input-form">
            </div>
            <div>
                <label>Margem Lucro Balcão (%)</label>
                <input v-model="dadosComponente.margem_lucro_balcao" type="number" min="0" class="input-form">
            </div>
            <div>
                <label>Fornecedor</label>
                <v-select v-model="dadosComponente.fornecedor" :options="listaFornecedores" label="nome" class="w-full z-10"
                    :reduce="fornecedor => fornecedor.id" />
            </div>
            <div>
                <label>Padrão</label>
                <input type="checkbox" v-model="dadosComponente.padrao">
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios'

export default {
    name: 'DadosComponente',

    props: {
        dadosComponente: Object
    },

    components: {
        vSelect
    },

    data() {
        return {
            listaFornecedores: [],
        }
    },

    created() {
        this.getFornecedores()
    },

    methods: {
        async getFornecedores() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('pessoas/fornecedor/?tipo_fornecedor_nome=Componentes&ativo=True&page_size=9999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                // console.log(response.data.results)
                this.listaFornecedores = response.data.results.map(fornecedor => ({
                    id: fornecedor.id,
                    nome: fornecedor.pessoa.nome_razao
                }));
            } catch (error) {
                console.error(error)
            }
        },
    }
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}
</style>