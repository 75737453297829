<template>


    <div class="container-formulario">

        <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
        <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase">Dados Local Vidro:</h1>

            <router-link to="/local-vidro" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Escopo do formulario de cadastro mobile-->
        <form @submit.prevent="cadastrarLocalVidro">
            <!--        PARTE DE DADOS DO PRODUTO-->
            <div class="div-produtos">
                <div class="campo-chapa">
                    <label class="font-bold text-corBase">Descrição</label>
                    <input type="text" v-model="descricao" class="input-form-chapa" required autofocus>
                </div>
            </div>


            <div class="flex w-full justify-end">
                <button class="btn-enviar" type="submit">Cadastrar</button>
            </div>
        </form>

    </div>
    <MensagemSucesso v-if="sucesso == true" rotas="/local-vidro" mensagem="Local do Vidro cadastrado com sucesso!" />
    <ErroRapido v-if="erro === true" mensagem="Este Local de Vidro já existe." />

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";

export default {
    name: "CadastroLocalVidro",


    data() {
        return {
            sucesso: false,
            erro: false,
            descricao: '',
        }
    },

    components: {
        MensagemSucesso,
        ErroRapido
    },

    created() {
    },

    computed: {
        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },
        isadmin() {
            return this.$store.state.isAdminUser
        },
    },

    methods: {


        async cadastrarLocalVidro() {
            const token = await this.$store.dispatch('getAuthTokenCookie')

            await this.$store.dispatch('fetchURLrequest')

            const data = new FormData();
            data.append('ativo', 'True')
            data.append('descricao', this.descricao)
            this.sucesso = false
            await axios.post('projeto/area-vidro/', data, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data);
                    this.sucesso = true
                    this.descricao = ''
                })
                .catch(error => {
                    console.error(error);
                    this.erro = true
                    setTimeout(() => {
                        this.erro = false;
                    }, 3000);

                })

        },


    }

}
</script>

<style lang="scss"></style>