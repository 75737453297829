<template>
    <div>
        <h3 class="text-corBase" v-if="dadosComponente.fornecedor">Fornecedor: {{ fornecedor.pessoa?.nome_razao }}</h3>
        <h2 class="text-lg text-corBase font-bold">Lista de Produtos:</h2>

        <!-- Adicionando uma div para rolagem lateral -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela h-full border-b">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200">Ações</th>
                        <th class="py-2 px-4 bg-gray-200">Nome do Produto</th>
                        <th class="py-2 px-4 bg-gray-200">Valor de Custo</th>
                        <th class="py-2 px-4 bg-gray-200">% Margem Adicional</th>
                        <th class="py-2 px-4 bg-gray-200">% Margem Adicional Balcão</th>
                        <th class="py-2 px-4 bg-gray-200">Valor Final de Venda</th>
                        <th class="py-2 px-4 bg-gray-200">Valor Final de Venda Balcão</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Exibir os produtos existentes -->
                    <tr v-for="produto in produtosTabela" :key="produto.id" class="border-b">
                        <td class="py-2 px-4 flex gap-2">
                            <button @click="deleteProduto(produto.id)" class="text-gray-500" title="Excluir Componente">
                                <i class="fas fa-times"></i>
                            </button>
                            <button @click="abrirEdicao(produto)" class="text-gray-500" title="Editar Componente">
                                <i class="fas fa-paperclip"></i>
                            </button>
                        </td>
                        <td class="py-2 px-4 whitespace-nowrap">{{
                            produto.componente.nome_especifico }}</td>
                        <td class="py-2 px-4">R$ {{
                            parseFloat(produto.componente.preco_custo).toFixed(2) }}</td>
                        <td class="py-2 px-4">
                            <input v-if="produtoEditando && produtoEditando.id === produto.id"
                                v-model="produtoEditando.margem_lucro_ml" type="number" min="0" class="input-form" />
                            <span v-else>{{ parseFloat(produto.margem_lucro_ml).toFixed(2) }}%</span>
                        </td>

                        <td class="py-2 px-4">
                            <input v-if="produtoEditando && produtoEditando.id === produto.id"
                                v-model="produtoEditando.margem_lucro_balcao" type="number" min="0"
                                class="input-form" />
                            <span v-else>{{ parseFloat(produto.margem_lucro_balcao).toFixed(2) }}%</span>
                        </td>
                        <td class="py-2 px-4">
                            <span v-if="produtoEditando && produtoEditando.id === produto.id">
                                R$ {{ calcularValorFinal(produtoEditando) }}
                            </span>
                            <span v-else>
                                R$ {{ calcularValorFinalProdutoExistente(produto) }}
                            </span>
                        </td>
                        <td class="py-2 px-4">
                            <span v-if="produtoEditando && produtoEditando.id === produto.id">
                                R$ {{ calcularValorFinalBalcao(produtoEditando) }}
                            </span>
                            <span v-else>
                                R$ {{ calcularValorFinalBalcaoProdutoExistente(produto) }}
                            </span>
                        </td>
                    </tr>

                    <!-- Linha de input para adicionar novo produto -->
                    <tr v-if="adicionarProdutoAtivo" class="border-b">
                        <td class="py-2 px-4"></td>
                        <td class="py-2 px-4 w-full sm:w-96">
                            <v-select v-model="novoProduto.componente" :options="listaProdutos" label="text"
                                class="w-full bg-white" :clearable="false" @search="onSearchProduto" />
                        </td>
                        <td class="py-2 px-4">
                            <input v-model="novoProduto.custo" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4">
                            <input v-model="novoProduto.margem_lucro_ml" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4">
                            <input v-model="novoProduto.margem_lucro_balcao" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4">{{ calcularValorFinal(novoProduto) }}</td>
                        <td class="py-2 px-4">{{ calcularValorFinalBalcao(novoProduto) }}</td>
                    </tr>

                    <!-- Modal inline de edição de produto -->
                    <tr v-if="editarProdutoAtivo">
                        <td class="py-2 px-4"></td>
                        <td class="py-2 px-4 w-full sm:w-96">
                            <Select2 v-model="produtoSelecionado.componente" :options="listaProdutos" class="w-full"
                                :settings="{ width: '100%', placeholder: 'Selecione um produto' }" />
                        </td>
                        <td class="py-2 px-4">
                            <input v-model="produtoSelecionado.custo" type="number" min="0" class="input-form"
                                readonly />
                        </td>
                        <td class="py-2 px-4">
                            <input v-model="produtoSelecionado.margem_lucro_ml" type="number" min="0"
                                class="input-form" />
                        </td>
                        <td class="py-2 px-4">
                            <input v-model="produtoSelecionado.margem_lucro_balcao" type="number" min="0"
                                class="input-form" />
                        </td>
                        <td class="py-2 px-4">{{ calcularValorFinal(produtoSelecionado) }}</td>
                        <td class="py-2 px-4">{{ calcularValorFinalBalcao(produtoSelecionado) }}</td>
                    </tr>
                </tbody>
            </table>
            <!-- Botões da paginação -->
            <Pagination v-if="produtosTabela.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
        </div>

        <!-- Botão de Adicionar Produto -->
        <div class="flex justify-end mt-4" v-if="adicionarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="adicionarProduto" :disabled="!produtoPreenchido">Adicionar
                Produto</button>
        </div>

        <!-- Botão de salvar as edições no modal de edição -->
        <div class="flex justify-end mt-4" v-if="produtoEditando">
            <button class="btn-salvar" @click.prevent="salvarProdutoEditado">Salvar Alterações</button>
        </div>

        <!-- Botão para adicionar novo produto -->
        <div class="flex justify-center mt-4">
            <button class="text-ativo" @click.prevent="abrirLinhaAdicionarProduto">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Alteração feita com sucesso." />
    <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
</template>

<script>
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import formatarData from "@/utils/FormatarData";
import axios from 'axios';

export default {
    name: 'ProdutosTabela',
    components: {
        Select2,
        vSelect,
        Pagination,
        SucessoRapido,
        ErroRapido
    },
    props: {
        dadosComponente: Object
    },
    data() {
        return {
            editarProdutoAtivo: false,
            produtoSelecionado: {},
            produtoEditando: null,
            sucesso: false,
            erro: false,
            erroMessage: '',

            produtosTabela: [],
            pesquisaProduto: '',

            novoProduto: {
                custo: 0,
                componente: null,
                margem_lucro_ml: 0,
                margem_lucro_balcao: 0
            },

            listaProdutos: [],
            adicionarProdutoAtivo: false,
            paginaAtual: 1,
            countItens: 0,
            fornecedor: '',
        };
    },

    computed: {
        produtoPreenchido() {
            return (
                this.novoProduto.componente !== null &&
                this.novoProduto.custo > 0 &&
                this.novoProduto.margem_lucro_ml > 0 &&
                this.novoProduto.margem_lucro_balcao > 0
            );
        }
    },

    created() {
        this.buscarProdutosTabelaComponente(1);
        this.buscarNomeFornecedor()
    },
    watch: {
        'novoProduto.componente': {
            handler(novoComponente) {
                if (novoComponente) {
                    console.log(novoComponente)
                    const produtoSelecionado = this.listaProdutos.find(produto => produto.id === novoComponente.id);
                    // console.log(produtoSelecionado)
                    if (produtoSelecionado) {
                        this.novoProduto.custo = parseFloat(produtoSelecionado.preco).toFixed(2);
                    } else {
                        this.novoProduto.custo = "0.00";
                    }
                } else {
                    this.novoProduto.custo = "0.00";
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {

        async buscarNomeFornecedor() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                if (this.dadosComponente.fornecedor !== null) {
                    const response = await axios.get(`pessoas/fornecedor/${this.dadosComponente.fornecedor}`, {
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    })

                    console.log(response.data)
                    this.fornecedor = response.data
                } else{
                    console.log('Sem fornecedor definido')
                }
            } catch (error) {
                console.error(error)
            }
        },

        async onSearchProduto(query) {
            if (query.length >= 3) {
                this.pesquisaProduto = query;
                await this.buscarListaProdutos();
            }
        },

        async buscarProdutosTabelaComponente(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(`precificacao/itens-tabelas-componente/?id_tabela_preco=${this.$route.params.id}&page=${pagina}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.produtosTabela = response.data.results;
                console.log(this.produtosTabela)
                this.countItens = response.data.count
            } catch (error) {
                console.error("Erro ao buscar produtos da tabela de componente:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async buscarListaProdutos() {
            if (this.pesquisaProduto.length < 3) return;
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`/produtos/produto-por-fornecedor/?tipo_produto=Componente&fornecedorID=${this.dadosComponente.fornecedor || ''}&nome_especifico=${this.pesquisaProduto || ''}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.listaProdutos = response.data.results.map(produto => ({
                    id: produto.id,
                    text: produto.nome_especifico,
                    preco: produto.preco_custo
                }));
            } catch (error) {
                console.error("Erro ao buscar lista de produtos:", error);
            }
        },

        abrirEdicao(produto) {
            this.produtoEditando = { ...produto };
        },

        abrirModalEditarProduto(produto) {
            this.produtoSelecionado = {
                ...produto,
                componente: produto.componente.id,
                custo: produto.componente.preco_custo,
            };
            this.editarProdutoAtivo = true;
        },

        fecharModal() {
            this.editarProdutoAtivo = false;
        },

        async salvarProdutoEditado() {
            // alert('chegou aq')
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                await axios.put(`precificacao/itens-tabelas-componente/${this.produtoEditando.id}/`, {
                    tabela_preco_componente: this.dadosComponente.id,
                    componente: this.produtoEditando.componente.id,
                    custo: this.produtoEditando.custo,
                    margem_lucro_ml: this.produtoEditando.margem_lucro_ml,
                    margem_lucro_balcao: this.produtoEditando.margem_lucro_balcao,
                    valor_final_venda_ml: this.calcularValorFinal(this.produtoEditando),
                    valor_final_venda_balcao: this.calcularValorFinalBalcao(this.produtoEditando)
                }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);

                this.produtoEditando = null;
                this.buscarProdutosTabelaComponente(this.paginaAtual)
            } catch (error) {
                console.error('Erro ao salvar o produto editado:', error);
            }
        },

        async deleteProduto(id) {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                await axios.patch(`precificacao/itens-tabelas-componente/${id}/`, { ativo: false }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
                this.buscarProdutosTabelaComponente(1);
            } catch (error) {
                console.error('Erro ao desativar o produto:', error);
            }
        },

        calcularValorFinal(produto) {
            console.log(produto)
            const custo = parseFloat(produto.componente?.preco_custo || produto.componente?.preco) || 0;
            const margemLucroML = parseFloat(produto.margem_lucro_ml) || 0;
            const valorFinal = custo + (custo * (margemLucroML / 100));
            return valorFinal.toFixed(2);
        },

        calcularValorFinalBalcao(produto) {
            const custo = parseFloat(produto.componente?.preco_custo || produto.componente?.preco) || 0;
            const margemLucroBalcao = parseFloat(produto.margem_lucro_balcao) || 0;
            const valorFinalBalcao = custo + (custo * (margemLucroBalcao / 100));
            return valorFinalBalcao.toFixed(2);
        },

        calcularValorFinalProdutoExistente(produto) {
            const custo = parseFloat(produto.componente.preco_custo) || 0;
            const margemLucroML = parseFloat(produto.margem_lucro_ml) || 0;
            const valor = custo + custo * (margemLucroML / 100);
            return valor.toFixed(2);
        },

        calcularValorFinalBalcaoProdutoExistente(produto) {
            const custo = parseFloat(produto.componente.preco_custo) || 0;
            const margemLucroBalcao = parseFloat(produto.margem_lucro_balcao) || 0;
            const valor = custo + custo * (margemLucroBalcao / 100);
            return valor.toFixed(2);
        },

        formatarDataProdutos(dataISO) {
            return formatarData(dataISO);
        },

        abrirLinhaAdicionarProduto() {
            this.adicionarProdutoAtivo = true;
        },

        async adicionarProduto() {
            if (this.produtoPreenchido) {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                try {
                    const response = await axios.post(`precificacao/itens-tabelas-componente/`, {
                        tabela_preco_componente: this.dadosComponente.id,
                        componente: this.novoProduto.componente.id,
                        margem_lucro_ml: this.novoProduto.margem_lucro_ml,
                        margem_lucro_balcao: this.novoProduto.margem_lucro_balcao,
                        custo: this.novoProduto.custo,
                        valor_final_venda_ml: this.calcularValorFinal(this.novoProduto),
                        valor_final_venda_balcao: this.calcularValorFinalBalcao(this.novoProduto)
                    }, {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    });

                    // console.log(response.data)

                    this.sucesso = true
                    setTimeout(() => {
                        this.sucesso = false;
                    }, 3000);

                    this.novoProduto = {
                        componente: '',
                        custo: 0,
                        margem_lucro_ml: 0,
                        margem_lucro_balcao: 0
                    }

                    this.buscarProdutosTabelaComponente(this.paginaAtual)

                    this.adicionarProdutoAtivo = false;
                } catch (error) {
                    if (error.response && error.response.data[0] === "Este item já existe para esta tabela de preço.") {
                        this.erro = true
                        this.erroMessage = 'Este item já existe para esta tabela de preço.'
                        setTimeout(() => {
                            this.erro = false;
                            this.erroMessage = ''
                        }, 3000);
                    } else if (error.response && error.response.data[0].includes("Este componente já está precificado na tabela")) {
                        this.erro = true
                        this.erroMessage = `${error.response.data[0]}`
                        setTimeout(() => {
                            this.erro = false;
                            this.erroMessage = ''
                        }, 3000);
                    } else {
                        console.error("Erro ao adicionar produto:", error);
                        this.erro = true
                        this.erroMessage = 'Ocorreu um erro ao salvar este Produto na tabela.'
                        setTimeout(() => {
                            this.erro = false;
                            this.erroMessage = ''
                        }, 3000);
                    }
                }
            }
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina
            this.buscarProdutosTabelaComponente(pagina)
        },
    }
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

thead th {
    background-color: #f8f9fa;
}

tr:hover {
    background-color: #f1f1f1;
}
</style>
