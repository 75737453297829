<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Linhas</h1>
      </div>

      <router-link to="/projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex gap-8 items-end mb-6 w-[80%]">
      <div class="w-full">
        <label class="text-corBase">Nome</label>
        <input type="text" class="input-form-chapa" v-model="linha">
      </div>
      <div class="w-full">
        <label class="text-corBase">Sistemista</label>
         <v-select v-model="projetista" :options="projetistas_sistemistas" :reduce="projetista => projetista.id" label="text" />
      </div>
      <div class="w-full">
        <label class="text-corBase">Bitola</label>
        <input type="text" class="input-form-chapa" v-model="bitola">
      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaLinhasSistemistas(paginaAtual)">Pesquisar</button>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/linha-sistema/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-1 rounded-sm px-4 mt-2 mr-2">Adicionar
        </button>
      </div>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">#</th>
            <th class="text-center px-10">Código</th>
            <th class="px-6 text-start w-full">Nome</th>
            <th class="px-20 text-start">Bitola</th>
            <th class="px-20 text-start">Sistemista</th>
            <th scope="col" class="text-center px-10">Status</th>
            <th v-if="isadmin === true" scope="col" class="px-10 text-start">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(linha_sistema, index) in lista_linha_sistema" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="linha_sistema.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs text-center">{{ linha_sistema.codigo }}</td>
            <td class="px-6 py-2 text-xs">{{ linha_sistema.linha }}</td>
            <td class="px-6 py-2 text-xs text-center">{{ linha_sistema.bitola || 'Bitola não definida.' }}</td>
            <td class="text-center py-2 text-xs w-full">{{ linha_sistema.projetistasistemistas.nome }} ({{ linha_sistema.projetistasistemistas.codigo }})</td>

            <!-- Status -->
            <td>
              <div class="px-1 sm:px-6 py-1 sm:py-4 text-center">
                <i v-if="linha_sistema.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
              </div>
            </td>

            <!-- Ações -->
            <td class="px-6 py-2 text-xs" v-if="isadmin === true">
              <a @click="selecionarLinhaEditar(linha_sistema)" href="#"
                class="font-medium text-corBase pl-2 hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="lista_linha_sistema.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
      <editar-linha-sistema v-if="mostrarModal === true" :linhaSistema="linhaSelecionada"
        @fecharModal="toggleModal()" @atualizarLista="getListaLinhasSistemistas(paginaAtual)"></editar-linha-sistema>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import EditarLinhaSistema from "@/components/midais/Editar/projeto/EditarLinhaSistema";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaLinhasSistemistas",

  components: {
    vSelect,
    EditarLinhaSistema,
    Pagination
  },

  data() {
    return {
      lista_linha_sistema: [],
      projetistas_sistemistas: [],

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: 0,

      // modal
      linhaSelecionada: null,
      mostrarModal: false,

      // filtros
      // nome_projetista: '',
      projetista: '',
      linha: '',
      bitola: '',

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  created(){
    this.getListaProjetistaSistemista()
  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaProjetistaSistemista() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/projetista-sistemista/?ativo=True&page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.projetistas_sistemistas = response.data.results.map(item => ({
              id: item.id,
              text: `${item.nome} (${item.codigo})`
            }));
            console.log(response.data.results)
          })
          .catch(error => {
            console.error(error)
          })
    },

    async getListaLinhasSistemistas(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`/projeto/linha-sistema/?projetista_sistemista_id=${this.projetista || ''}&linha=${this.linha}&bitola=${this.bitola}&page=${pagina}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_linha_sistema = response.data.results
          this.countItens = response.data.count
        })
        .catch(error => {
          console.error(error)
        })
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina
      this.getListaLinhasSistemistas(pagina)
    },

    selecionarLinhaEditar(linha) {
      this.linhaSelecionada = { ...linha }
      this.toggleModal()
    },

    toggleModal() {
      this.mostrarModal = !this.mostrarModal
    }

  }
}
</script>

<style lang="scss"></style>