<template>
  <div class="container-formulario">
    <!-- Cabeçalho -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Alterar Produtos da Tabela</h1>
      <router-link to="/componentes" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Navegação entre Dados e Produtos da Tabela -->
    <nav class="flex gap-4 mb-6 items-end">
      <button class="px-2 py-1 bg-corBase text-white" @click="mostrarDados = true">Dados</button>
      <button class="px-2 py-1 bg-corBase text-white" @click="mostrarDados = false">Produtos da Tabela</button>
    </nav>

    <!-- componente de Dados -->
    <DadosComponente v-if="mostrarDados" :dadosComponente="dadosComponente" />

    <!-- componente de Produtos da Tabela -->
    <ProdutosTabela v-if="!mostrarDados" :dadosComponente="dadosComponente" />

    <!-- Botões de Cancelar e Salvar -->
    <div class="flex w-full justify-end itens-center mt-6">
      <button class="btn-cancelar" @click.prevent="$router.push('/componentes')">Cancelar</button>
      <button class="btn-salvar" @click.prevent="salvarDados">Salvar</button>
    </div>
  </div>
  <SucessoRapido v-if="sucesso === true" mensagem="Alteração feita com sucesso." />
  <ErroRapido v-if="erro === true" mensagem="Este produto já existe na tabela." />
</template>

<script>
import axios from 'axios';
import Select2 from "vue3-select2-component";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import Pagination from '@/utils/Pagination.vue';
import DadosComponente from './DadosComponente.vue';
import ProdutosTabela from './ProdutosTabela.vue';
import formatarData from '@/utils/FormatarData'

export default {
  components: {
    Select2,
    SucessoRapido,
    ErroRapido,
    Pagination,
    DadosComponente,
    ProdutosTabela
  },
  data() {
    return {
      mostrarDados: true,
      sucesso: false,
      erro: false,
      dadosComponente: {},
      produtosTabela: [],
      novoProduto: {
        custo: 0,
        componente: null,
        margem_lucro_ml: 0,
        margem_lucro_balcao: 0
      },
      listaProdutos: [],
      adicionarProdutoAtivo: false,
      paginaAtual: 1,
      countItens: 0,
      next_page: null,
      itemsPorPagina: 5,
    };
  },
  computed: {
    produtoPreenchido() {
      return (
        this.novoProduto.componente !== null &&
        this.novoProduto.custo > 0 &&
        this.novoProduto.margem_lucro_ml > 0 &&
        this.novoProduto.margem_lucro_balcao > 0
      );
    }
  },
  methods: {
    formatarDataInput(dataISO) {
      const data = new Date(dataISO);
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();
      return `${ano}-${mes}-${dia}`;
    },

    async buscarDadosComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`precificacao/tabelas-componente/${this.$route.params.id}/`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.dadosComponente = response.data;
        console.log(this.dadosComponente)
        this.dadosComponente.criado_em_formatado = this.formatarDataInput(this.dadosComponente.criado_em);
      } catch (error) {
        console.error("Erro ao buscar dados da tabela de componente:", error);
      }
    },

    async salvarDados() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        await axios.put(`precificacao/tabelas-componente/${this.$route.params.id}/`, this.dadosComponente, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$router.push('/componentes');
        }, 3000);
      } catch (error) {
        console.error("Erro ao salvar dados da tabela de componente:", error);
      }
    },
  },
  created() {
    this.buscarDadosComponente();
    // this.buscarListaProdutos();
    // this.buscarProdutosTabelaComponente(1);
  },
  watch: {
    'novoProduto.componente': function (novoComponente) {
      this.novoProduto.custo = this.buscarPrecoComponente(novoComponente);
    }
  }
};
</script>

<style scoped>
.container-formulario {
  padding: 20px;
}

.cabecalho-formulario {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
