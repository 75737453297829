<template>
    <div class="container-formulario">
        <section>
            <!-- Cabeçalho com botão de retorno -->
            <div class="container-cabecalho flex justify-between mb-4">
                <span class="font-bold text-corBase text-xl mb-4">Criar Nova Obra</span>
                <router-link to="/obras" class="text-xl text-corBase font-bold">
                    <i class="fas fa-chevron-left"></i>
                </router-link>
            </div>

            <form @submit.prevent="salvarObra">
                <div class="space-y-4">
                    <!-- Seção 1: Dados da Obra -->
                    <div>
                        <button @click.prevent="toggleSection(1)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Obra
                        </button>
                        <div v-if="activeSection === 1"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Obra :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 2: Endereço -->
                    <div>
                        <button @click.prevent="toggleSection(2)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Endereço
                        </button>
                        <div v-if="activeSection === 2"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Endereco :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 3: Stakeholders -->
                    <div>
                        <button @click.prevent="toggleSection(3)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Stakeholders
                        </button>
                        <div v-if="activeSection === 3"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <ListarStakeholders :obra="obra" @atualizarStakeholders="atualizarStakeholders"
                                @salvarStakeholdersObrigatorios="salvarStakeholdersObrigatorios" />
                        </div>
                    </div>

                    <!-- Seção 4: Documentos -->
                    <div>
                        <button @click.prevent="toggleSection(4)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Documentos
                        </button>
                        <div v-if="activeSection === 4"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Documentos :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 5: Financeiro (somente para admins) -->
                    <div v-if="isadmin === true">
                        <button @click.prevent="toggleSection(5)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Financeiro
                        </button>
                        <div v-if="activeSection === 5"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Financeiro :obra="obra" />
                        </div>
                    </div>
                </div>

                <!-- Botões para salvar ou cancelar -->
                <div class="flex w-full justify-end itens-center mt-6">
                    <button class="btn-cancelar" @click.prevent="$router.push('/obras')">Cancelar</button>
                    <button class="btn-salvar" type="submit">Salvar</button>
                </div>
            </form>
        </section>

        <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
        <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
    </div>
</template>

<script>
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import Obra from './EtapasCriacaoObra/Obra.vue';
import Endereco from './EtapasCriacaoObra/Endereco.vue';
import Documentos from './EtapasCriacaoObra/Documentos.vue';
import ListarStakeholders from './EtapasCriacaoObra/ListarStakeholders.vue';
import Financeiro from './EtapasCriacaoObra/Financeiro.vue';
import axios from 'axios';

export default {
    name: 'CriarNovaObra',
    components: {
        SucessoRapido,
        ErroRapido,
        Obra,
        Endereco,
        ListarStakeholders,
        Documentos,
        Financeiro,
    },
    data() {
        return {
            sucesso: false,
            sucessoMessage: '',

            erro: false,
            erroMessage: '',

            activeSection: 1,
            obra: {
                descricao: '',
                imagem: null,
                imagemFile: null,
                endereco: '',
                numero: '',
                apartamento: '',
                bloco: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: '',
                cep: '',
                localizacao: '',
                status_obra: '',
                comissao: 0,
                valor_rt: 0,
                cliente: {},
                patrocinador: {},
                responsavel: {},
                stakeholders: [],
                arquivos: [],
            },
            id_obra_criada: ''
        };
    },
    computed: {
        isadmin() {
            return this.$store.state.isAdminUser;
        },
    },
    methods: {
        toggleSection(sectionNumber) {
            // Alterna a exibição da seção com base no número do acordeão clicado
            this.activeSection = this.activeSection === sectionNumber ? null : sectionNumber;
        },

        atualizarStakeholders(stakeholdersAtualizados) {
            this.obra.stakeholders = stakeholdersAtualizados;
        },

        salvarStakeholdersObrigatorios(stakeholdersObrigatorios) {
            this.obra.cliente = stakeholdersObrigatorios.cliente.pessoa.id;
            this.obra.patrocinador = stakeholdersObrigatorios.patrocinador.pessoa.id;
            this.obra.responsavel = stakeholdersObrigatorios.responsavel.pessoa.id;
        },

        async salvarObra() {
            const formDataObra = new FormData();
            formDataObra.append('descricao', this.obra.descricao);
            formDataObra.append('ativo', 'true');
            if (this.obra.imagemFile != null) {
                formDataObra.append('imagem', this.obra.imagemFile);
            }
            formDataObra.append('endereco', this.obra.endereco);
            formDataObra.append('numero', this.obra.numero);
            formDataObra.append('apartamento', this.obra.apartamento);
            formDataObra.append('bloco', this.obra.bloco);
            formDataObra.append('complemento', this.obra.complemento);
            formDataObra.append('bairro', this.obra.bairro);
            formDataObra.append('cidade', this.obra.cidade);
            formDataObra.append('estado', this.obra.estado);
            formDataObra.append('cep', this.obra.cep);
            formDataObra.append('localizacao', this.obra.localizacao);
            formDataObra.append('status_obra', this.obra.status_obra);
            formDataObra.append('comissao', this.obra.comissao);
            formDataObra.append('valor_rt', this.obra.valor_rt);
            formDataObra.append('cliente', this.obra.cliente);
            formDataObra.append('patrocinador', this.obra.patrocinador);
            formDataObra.append('responsavel', this.obra.responsavel);

            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.post('obra/', formDataObra, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });

                // Verifica se tem algum stakeholder além dos 3 obrigatórios
                this.id_obra_criada = response.data.id;
                if (this.obra.stakeholders.length >= 4) {
                    this.salvarPessoasAlocadas(this.id_obra_criada);
                }
                if (this.obra.arquivos.length >= 1) {
                    this.salvarArquivos(this.id_obra_criada);
                }
                this.sucesso = true;
                this.sucessoMessage = 'Obra criada com sucesso.';
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = '';
                    this.$router.push(`/obra/${this.id_obra_criada}`);
                }, 3000);
            } catch (error) {
                console.error('Erro ao criar a obra:', error);
                this.erro = true;
                this.erroMessage = 'Ocorreu um erro ao criar a Obra.';
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = '';
                }, 3000);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async salvarPessoasAlocadas(id_obra) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            const stakeholders = this.obra.stakeholders.filter(
                (stakeholder) => !['Cliente', 'Patrocinador', 'Responsável'].includes(stakeholder.funcao)
            );

            this.$store.dispatch("setLoading", true);
            try {
                for (const stakeholder of stakeholders) {
                    await axios.post(
                        `obra/pessoa-alocada/`,
                        {
                            obra: id_obra,
                            pessoa: stakeholder.pessoa.id,
                            responsabilidade: stakeholder.funcao.id,
                        },
                        {
                            headers: {
                                Authorization: `Token ${token}`,
                            },
                        }
                    );
                }
            } catch (error) {
                console.error('Erro ao criar pessoas alocadas:', error);
                this.erro = true;
                this.erroMessage = 'Ocorreu um erro ao alocar pessoas.';
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = '';
                }, 3000);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async salvarArquivos(id_obra) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                // console.log(id_obra);

                for (const file of this.obra.arquivos) {
                    console.log(file)
                    const formData = new FormData();
                    formData.append('obra', id_obra);
                    formData.append('ativo', 'True');
                    formData.append('descricao', `${file.name}`);
                    formData.append('url_documento', file);

                    const response = await axios.post(`obra/documentos-obra/`, formData, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });

                    console.log("Arquivo enviado:", response);
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
};
</script>

<style scoped>
input,
textarea,
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}
</style>
