<template>
    <div class="modal-overlay">
        <div class="modal-container">
            <!-- Botão para fechar o modal -->
            <div class="modal-header flex justify-between items-center">
                <h1 class="text-xl text-corBase font-bold">Pesquisar Produtos - {{ produto }}</h1>
                <button @click.prevent="$emit('fecharModal')" class="close-modal-button">
                    <i class="fas fa-times"></i>
                </button>
            </div>

            <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

            <!-- Conteúdo do modal -->
            <div class="modal-body">
                <div>
                    <div class="flex items-end mb-3 gap-8">
                        <div>
                            <label class="text-xl text-corBase">Descrição:</label>
                            <input type="text" class="input-form-chapa" v-model="codigo_base">
                        </div>
                        <div>
                            <label class="text-xl text-corBase">Código:</label>
                            <input type="text" class="input-form-chapa" v-model="codigo_base">
                        </div>
                        <div>
                            <label class="text-xl text-corBase">Linha Aplicação:</label>
                            <input type="text" class="input-form-chapa" v-model="codigo_base">
                        </div>

                        <div>
                            <button
                                class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
                                @click.prevent="getListaProdutos(paginaAtual)">
                                Pesquisar
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Lista de produtos -->
                <div class="container-modelo-tabela max-h-80">
                    <table class="container-tabela overflow-auto">
                        <thead class="cabecalho-tabela">
                            <tr>
                                <!-- <th scope="col" class="p-4">
                                    <div class="flex items-center">
                                        <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                        <label class="sr-only">checkbox</label>
                                    </div>
                                </th> -->
                                <th scope="col" class="px-6 py-3">Selecionar</th>
                                <!-- <th scope="col" class="px-6 py-3 text-center">Tipo Produto</th> -->
                                <th class="px-6 py-3">COD. Base</th>
                                <th class="px-6 py-3">Nome Base</th>
                                <th scope="col" class="px-6 py-3 text-center">Linha</th>
                                <!-- <th scope="col" class="px-6 py-3">Unidade Medida</th>
                                <th scope="col" class="px-6 py-3">Peso</th> -->
                                <th scope="col" class="px-6 py-3">Imagem</th>
                                <th scope="col" class="px-6 py-3">Status</th>
                                <!-- <th v-if="isadmin" scope="col" class="px-6 py-3">Ação</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(produto, index) in lista_produto_base" :key="index" class="escopo-tabela">
                                <!-- <td class="w-4 p-4">
                                    <div class="flex items-center">
                                        <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                            v-model="produto.selected">
                                        <label class="sr-only">checkbox</label>
                                    </div>
                                </td> -->
                                <th scope="row"
                                    class="px-6 py-2 font-medium text-xs text-center text-gray-900 whitespace-nowrap dark:text-white">
                                    <i class="fa-solid fa-check cursor-pointer text-xl"
                                        @click.prevent="selecionarProduto(produto)"></i>
                                </th>
                                <!-- <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.tipo_produto ? produto.tipo_produto.nome : 'Nome não disponível' }}
                                </td> -->
                                <td class="px-6 py-2 text-xs">{{ produto.codigo_base || '' }}</td>
                                <td class="px-6 py-2 text-xs">{{ produto.nome_base || '' }}</td>
                                <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.linha ? produto.linha.nome : 'Linha não disponível' }}
                                </td>
                                <!-- <td class="px-6 py-2 text-xs">
                                    {{ produto.unidade_medida ? produto.unidade_medida.nome : 
                                    'Unidade de medida não disponível' }}
                                </td> -->
                                <!-- <td class="px-6 py-2 text-xs">{{ produto.peso_base || 'Peso não disponível' }}</td> -->
                                <td class="px-6 py-2 text-xs">
                                    <button v-if="produto.imagem && produto.imagem.url_documento"
                                        @click.prevent="openPreviewImage(produto.imagem.url_documento)">
                                        <img class="w-12" :src="produto.imagem.url_documento"
                                            :alt="produto.imagem.descricao" />
                                    </button>
                                    <img v-else class="w-12"
                                        src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                        alt="Sem foto">
                                </td>
                                <template v-if="produto.ativo">
                                    <td class="px-6 py-2 text-xs">
                                        <i class="fa-solid fa-circle-check text-lg text-ativo ml-3"></i>
                                    </td>
                                </template>
                                <template v-else>
                                    <td class="px-6 py-2">
                                        <i class="fa-solid fa-circle-xmark text-lg text-inativo ml-3"></i>
                                    </td>
                                </template>
                                <!-- <td class="px-6 py-2 text-xs" v-if="isadmin">
                                    <a @click.prevent="selecionarProdutoBaseEditar(produto)" href="#"
                                        class="font-medium text-corBase hover:underline">
                                        <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
                                    </a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                    <Pagination v-if="lista_produto_base.length > 0" :offset="paginaAtual" :total="countItens"
                        :limit="20" @change-page="mudarPagina" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import vSelect from 'vue-select'
import EditarProdutoBase from "@/components/midais/Editar/produtos/EditarProdutoBase";
import PreviewImage from "@/components/midais/ged/PreviewImage";
import Pagination from "@/utils/Pagination.vue";

export default {
    name: "ListaProdutoBase",

    components: {
        EditarProdutoBase,
        vSelect,
        PreviewImage,
        Pagination
    },

    props: ['produto', 'itemListaSelecionado'],

    emits: ['fecharModal', 'atualizarLista', 'itemNovoPerfil'],

    data() {
        return {
            checkAll: false,
            lista_produto_base: [],

            tipo_produto: '',
            nome_produto: '',
            codigo_base: '',
            preview_imagem: '',

            produtoSelecionado: null,
            mostrarModal: false,
            countItens: 0,
            paginaAtual: 1,

            error: []
        }
    },

    created() {
        console.log(this.itemListaSelecionado)
    },

    methods: {

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getListaProdutos(pagina);
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },
        openPreviewImage(url) {
            this.preview_imagem = url
        },

        async getListaProdutos(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(
                    `produtos/produto/?tipo_produto=${this.produto}&nome_base=${this.nome_produto}&codigo_base=${this.codigo_base}&page=${pagina}`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                );

                this.lista_produto_base = response.data.results;
                // console.log(this.lista_produto_base)
                this.countItens = response.data.count;

            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            }
        },

        async selecionarProduto(produto) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                if (this.itemListaSelecionado) {
                    const response = await axios.patch(`projeto/projeto-perfil/${this.itemListaSelecionado}/`, {
                        perfil_id: produto.id
                    }, {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    })

                    console.log(response)
                    this.$emit('fecharModal')
                    this.$emit('atualizarLista')
                } else {
                    console.log(produto)
                    this.$emit('itemNovoPerfil', produto);
                    this.$emit('fecharModal')
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
}

.modal-container {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    padding: 20px;
    overflow-y: auto;
}

.modal-body {
    overflow-x: auto;
}

.close-modal-button {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}
</style>