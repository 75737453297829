<template>
  <div class="z-[20005]">
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <galeria v-if="showGaleria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
        <modal-add-imagem v-if="ged === true" @fechar_ged="addImageGed" @objetoClicado="atualizarFileInput" />

        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Produto</h2>

            <div class="flex flex-row gap-4">
              <div class="flex flex-col w-full md:w-[35%] mt-6">
                <label class="text-corBase text-xl font-bold">Adicione uma imagem:</label>
                <div class="imagePreviewWrapper"
                  :style="{ 'background-image': `url(${produto.imagem?.url_documento})` }"
                  @click.prevent="abrir_galeria">
                </div>
                <div class="flex gap-3 mt-4">
                  <button class="selecionar-arquivo" @click.prevent="addImageGed">Adicionar</button>
                  <button class="selecionar-arquivo" @click.prevent="abrir_galeria">Pesquisar</button>
                </div>

              </div>

              <div class="flex flex-col pt-5 w-full">
                <div>
                  <div class="container-campos">
                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Nome Base:</label>
                      <input type="text" required class="input-campos" v-model="produto.nome_base">
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Código Base:</label>
                      <input type="text" required class="input-campos" v-model="produto.codigo_base">
                    </div>
                  </div>

                  <div class="container-campos">
                    <div class="campos-mobile">
                      <label class="label-campos">Peso:</label>
                      <input type="text" required class="input-campos" v-model="produto.peso_base">
                    </div>
                  </div>

                  <div class="flex flex-col mt-2 w-full">
                    <label class="label-campos">Unidade de Medida:</label>
                    <v-select v-model="produto.unidade_medida" class="w-full bg-white z-[10]"
                      :options="lista_unidade_medida" label="nome" :reduce="option => option.id" />
                  </div>

                  <div class="flex flex-col mt-2 w-full">
                    <label class="label-campos">Produto:</label>
                    <v-select v-model="produto.tipo_produto" class="w-full bg-white z-[9]"
                      :options="lista_tipo_produtos" label="nome" :reduce="option => option.id" />
                  </div>

                  <div class="flex flex-col mt-2 w-full">
                    <label class="label-campos">Linha:</label>
                    <v-select v-model="produto.linha" class="w-full bg-white z-[8]" :options="lista_linha_produto_base"
                      label="nome" :reduce="option => option.id" />
                  </div>

                  <div class="flex flex-col mt-2 w-full">
                    <label class="label-campos">Cor Base:</label>
                    <v-select v-model="produto.cor_base" class="w-full bg-white z-[6]" :options="lista_cores"
                      label="nome" :reduce="option => option.id" />
                  </div>

                </div>
                <div>

                  <div class="container-campos w-full">

                    <div class="flex flex-col mt-2 w-full justify-start ">
                      <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                      <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                        <i v-if="produto.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                        <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                      </span>
                    </div>

                  </div>

                </div>


              </div>

            </div>


            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado()">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
      <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Galeria from "@/components/midais/ged/Galeria";
import ModalAddImagem from "@/components/ged/ModalAddImagem";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "EditarProdutoBase",

  components: {
    vSelect,
    Galeria,
    ModalAddImagem,
    ErroRapido,
    SucessoRapido,
  },
  data() {
    return {
      statusModal: false,
      showGaleria: false,
      ged: false,
      lista_unidade_medida: [],
      lista_cores: [],
      lista_tipo_produtos: [],
      lista_tipo_produtos_base: [],
      lista_linha_produto_base: [],

      erro: false,
      erroMessage: '',

      sucesso: false,
      sucessoMessage: ''

    }
  },
  props: ["produto"],
  emits: ["fecharModal", "atualizarLista"],

  created() {
    this.getListaUnidadeMedida()
    this.getListaCores()
    this.getListaTipoProduto()
    this.getListaTipoProdutoBase()
    this.getListaLinhaProdutoBase()
    console.log(this.produto)
  },

  methods: {
    toggleAtivo(event) {
      event.preventDefault()
      this.produto.ativo = !this.produto.ativo
    },

    atualizarFileInput(objeto) {
      // console.log('objeto que chegou:' + objeto)
      // console.log(objeto)
      this.produto.imagem = objeto;
      // console.log(this.produto.imagem)
    },

    addImageGed() {
      this.ged = !this.ged
    },

    abrir_galeria() {
      this.showGaleria = !this.showGaleria
    },


    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.produto.ativo)
      dadosAtualizados.append('nome_base', this.produto.nome_base)
      dadosAtualizados.append('codigo_base', this.produto.codigo_base)
      dadosAtualizados.append('codigo_mercado', this.produto.codigo_mercado)
      dadosAtualizados.append('codigo_auxiliar', this.produto.codigo_auxiliar)
      dadosAtualizados.append('peso_base', this.produto.peso_base)
      if (this.produto.tipo_produto) {
        if (this.produto.tipo_produto.id) {
          dadosAtualizados.append('tipo_produto', this.produto.tipo_produto.id);
        } else {
          dadosAtualizados.append('tipo_produto', this.produto.tipo_produto);
        }
      }

      if (this.produto.cor_base) {
        if (this.produto.cor_base.id) {
          dadosAtualizados.append('cor_base', this.produto.cor_base.id);
        } else {
          dadosAtualizados.append('cor_base', this.produto.cor_base);
        }
      }

      if (this.produto.linha) {
        if (this.produto.linha.id) {
          dadosAtualizados.append('linha', this.produto.linha.id);
        } else {
          dadosAtualizados.append('linha', this.produto.linha);
        }
      }

      if (this.produto.produto) {
        if (this.produto.produto.id) {
          dadosAtualizados.append('produto', this.produto.produto.id);
        } else {
          dadosAtualizados.append('produto', this.produto.produto);
        }
      }

      if (this.produto.unidade_medida) {
        if (this.produto.unidade_medida.id) {
          dadosAtualizados.append('unidade_medida', this.produto.unidade_medida.id);
        } else {
          dadosAtualizados.append('unidade_medida', this.produto.unidade_medida);
        }
      }

      if (this.produto.imagem) {
        if (this.produto.imagem.id) {
          dadosAtualizados.append('imagem', this.produto.imagem.id);
        } else {
          dadosAtualizados.append('imagem', this.produto.imagem);
        }
      }

      // for (let [key, value] of dadosAtualizados.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`produtos/produto/${this.produto.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        // this.$parent.irParaPagina(this.paginaAtual)
        // this.statusModal = false;
        this.sucesso = true
        this.sucessoMessage = `Produto editado com sucesso!`
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = ''
          this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);

      } catch (error) {
        console.error(error)
        this.erro = true
        this.erroMessage = `Ocorreu um erro ao salvar produto. Verifique todos os campos.`
        setTimeout(() => {
          this.erro = false;
          this.erroMessage = ''
        }, 3000);
      }
    },

    async getListaUnidadeMedida() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`produtos/unidade-medida`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        this.lista_unidade_medida = response.data.results.map(item => ({
          id: item.id,
          nome: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getListaLinhaProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`produtos/linha-produto-base`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        this.lista_linha_produto_base = response.data.results.map(item => ({
          id: item.id,
          nome: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getListaTipoProduto() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`produtos/produtos-sistema`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        this.lista_tipo_produtos = response.data.results.map(item => ({
          id: item.id,
          nome: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getListaTipoProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`produtos/tipo-produto-base`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        this.lista_tipo_produtos_base = response.data.results.map(item => ({
          id: item.id,
          nome: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getListaCores() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(
          `produtos/cor-produto-base/`,
          {
            headers: {
              'Authorization': `Token ${token}`,
            },
          }
        );
        // ?tipo_produto=${this.produto.tipo_produto.id}
        this.lista_cores = response.data.results.map(item => ({
          id: item.id,
          nome: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

  }
}

</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
  @apply text-corBase
}
</style>