<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Criar Vão para {{ obra.descricao }}</h1>
            </div>

            <router-link :to="`/obra/${$route.params.obra}`" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Formulário de criação de vão -->
        <div>
            <div class="form-group flex items-center">
                <label for="descricao" class="mr-2 text-corBase">Descrição</label>
                <input v-model="vao.descricao" type="text" id="descricao" placeholder="Digite aqui..." required />
            </div>

            <div class="form-group flex items-center">
                <label for="tipologia" class="mr-5 text-corBase">Modelo</label>
                <v-select v-model="modeloSelecionado" :options="lista_modelos" label="descricao"
                    placeholder="Selecione um modelo de tipologia" class="w-full pt-2" :clearable="false"
                    @update:modelValue="getTipologias(modeloSelecionado.id)" />

            </div>

            <div class="form-group flex items-center">
                <label for="tipologia" class="mr-2 text-corBase">Tipologia</label>
                <!-- <select class="cursor-pointer" v-model="tipologiaSelecionada" @change="onTipologiaChange">
                    <option value="" disabled>Selecione uma tipologia</option>
                    <option v-for="tipologia in tipologias" :key="tipologia.id" :value="tipologia.id">
                        {{ tipologia.nome }}
                    </option>
                </select> -->
                <v-select v-model="tipologiaSelecionada" :options="tipologias" label="nome"
                    placeholder="Selecione uma tipologia" class="w-full pt-2" :clearable="false"
                    @update:modelValue="onTipologiaChange" :reduce="tipologiaSelecionada => tipologiaSelecionada.id" />

            </div>

            <!-- Lista de linhas baseada na tipologia selecionada -->
            <div class="flex" v-if="linhas.length > 0">
                <div class="w-72">
                    <p>Linhas disponíveis:</p>
                    <div v-for="linha in linhas" :key="linha.id" class="flex">
                        <label class="mr-2">{{ linha.linha }}</label>
                        <input type="checkbox" :value="linha" v-model="linhasSelecionadas" />
                    </div>
                    <button type="button" class="btn-salvar" @click="adicionarTipologia">Adicionar</button>
                </div>
                <div class="">
                    <img v-if="tipologiaSelecionada && tipologias.find(t => t.id === tipologiaSelecionada)?.imagem?.url_documento"
                        :src="tipologias.find(t => t.id === tipologiaSelecionada)?.imagem.url_documento"
                        alt="Imagem da Tipologia" class="w-32 h-32 object-cover rounded-md border" />
                </div>
            </div>

            <!-- Seção de abas -->
            <div class="bg-gray-300 p-5 pt-0 rounded-lg">
                <div class="abas-container">
                    <template v-for="(aba, index) in abas" :key="index">
                        <div :class="['aba', { 'aba-selecionada': abaSelecionada === index }]">
                            {{ aba.nome }}
                        </div>
                    </template>
                </div>

                <!-- Conteúdo da aba selecionada -->
                <div v-if="abas.length > 0">
                    <div v-for="(aba, index) in abas" :key="index" v-show="abaSelecionada === index">

                        <!-- Aba Geral -->
                        <GeralComponent v-if="aba.nome === 'Geral'" :vao="vao" :orcamento="orcamento"
                            @update-file="onFileChange" />

                        <!-- Aba correspondente a tipologia -->
                        <div v-else class="flex items-start">
                            <!-- Coluna das imagens -->
                            <div class="w-1/2 flex flex-col items-center text-center">
                                <h4 class="text-corBase">Imagem da tipologia:</h4>
                                <img :src="aba.imagem.url_documento" class="foto-tipologia w-32 h-auto cursor-pointer"
                                    alt="Imagem tipologia" @click.prevent="openPreviewImage(aba.imagem.url_documento)">
                                <!-- @click="abrirModal(aba.imagem.url_documento)" -->

                                <div v-if="vao.imagem">
                                    <h4 class="text-corBase mt-10">Imagem do vão:</h4>
                                    <img :src="vao.imagem"
                                        class="foto-tipologia w-32 h-auto cursor-pointer object-cover"
                                        alt="Pré-visualização" @click.prevent="openPreviewImage(vao.imagem)" />
                                    <!-- @click="abrirModal(vao.imagem)" -->
                                </div>
                            </div>

                            <!-- Linha de separação vertical -->
                            <div class="w-px h-full bg-gray-600 mx-4"></div>

                            <!-- Coluna das informações da linha -->
                            <div class="w-1/2 flex flex-col space-y-4">
                                <!-- Abas internas para linhas -->
                                <div class="linhas-abas-container">
                                    <template v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex">
                                        <div :class="['linha-aba', { 'linha-aba-selecionada': linhaSelecionada === linhaIndex }]"
                                            @click="selecionarLinha(linhaIndex, aba)">
                                            {{ linha.linha }}
                                        </div>
                                    </template>
                                </div>

                                <!-- Conteúdo da linha selecionada -->
                                <div v-if="aba.linhas.length > 0">
                                    <div v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex"
                                        v-show="linhaSelecionada === linhaIndex">

                                        <!-- Verifica se um projeto foi selecionado -->
                                        <div v-if="projetosSelecionados[`${aba.id}_${linha.linha}`]">
                                            <p class="text-corBase font-semibold text-center pb-5">Projeto Selecionado
                                                para linha {{
                                                    linha.linha }}:</p>
                                            <div class="flex flex-wrap gap-6 justify-center">
                                                <div
                                                    class="flex flex-col items-center w-60 p-4 bg-white border border-corBase/40 shadow-md rounded-md">
                                                    <img src="@/assets/imagens/fotos/janela2folhas.png"
                                                        alt="Imagem do Projeto" class="w-40 h-40 object-cover mb-4">
                                                    <p class="text-sm font-medium text-center">
                                                        {{
                                                            projetosSelecionados[`${aba.id}_${linha.linha}`].projeto.descricao_fixa
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Exibição dos projetos -->
                                        <div v-else>
                                            <p class="text-corBase font-semibold text-center pb-5">Lista de Projetos</p>
                                            <!-- Lista de Projetos -->
                                            <!-- <p>{{ aba }}</p> -->
                                            <!-- <p>Chave: {{ `${linha.linha}_${aba.id_tipologia}` }}</p> -->

                                            <ListaProjetos
                                                v-if="listaProjetos[`${linha.linha}_${aba.id_tipologia}`]?.length > 0"
                                                :projetos="listaProjetos[`${linha.linha}_${aba.id_tipologia}`]"
                                                :abaId="aba.id" :nomeDaLinha="linha"
                                                @projeto-selecionado="salvarProjetos" />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Botões para navegar entre as abas -->
                <div class="flex w-full justify-end itens-center mt-6">
                    <button v-if="abaSelecionada > 0" @click="voltarAba" class="btn-cancelar">
                        Voltar
                    </button>
                    <button v-if="abaSelecionada < abas.length - 1" @click="onAvancarAba" class="btn-salvar">
                        Avançar
                    </button>
                    <button v-else type="button" @click="onAvancarAba" class="btn-salvar">
                        Salvar
                    </button>
                </div>

            </div>
        </div>

        <!-- Modal de Foto Ampliada -->
        <!-- <div v-if="fotoAmpliada" class="modal-mask z-9999" @click="fecharModal">
            <div class="modal-wrapper z-9999">
                <img :src="fotoAmpliada" class="modal-foto z-9999" />
            </div>
        </div> -->

    </div>
    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
    <SucessoRapido v-if="sucesso" mensagem="Vão criado com sucesso." />
    <ErroRapido v-if="erro" :mensagem="erroMessage" />
</template>

<script>
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import GeralComponent from './ComponentesCriacaoVao/GeralComponent.vue';
import ListaProjetos from './ComponentesCriacaoVao/ListaProjetos.vue'
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';
import axios from 'axios';

export default {
    name: 'CriarVao',

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
        GeralComponent,
        ListaProjetos,
        PreviewImage,
    },

    data() {
        return {
            vao: {
                descricao: '',
                largura: '',
                altura: '',
                cor_componente: '',
                cor_perfil: '',
                cor_vidro: '',
                local_vao: '',
                imagem: null,
                imagemFile: null,
                codigo_arquitetonico: '',
            },
            orcamento: {
                tipoMedidaContramarco: '',
                contramarco: false,
                arremate: false,
                arrematePiso: false
            },

            obra: [],


            cliente: '',

            mostrarVariaveis: false,
            mostrarVariaveisPorLinha: {},

            tipologiaSelecionada: null,
            tipologias: [],

            lista_modelos: [],
            modeloSelecionado: null,

            linhas: [],
            linhasSelecionadas: [],

            abas: [{ nome: 'Geral', ativo: true }],
            abaSelecionada: 0,
            linhaSelecionada: 0,

            sucesso: false,
            erro: false,
            erroMessage: '',

            preview_imagem: null,

            id_vao_criado: '',

            projetosPorLinha: {},
            projetosSelecionados: {},

            listaProjetosSelecionados: [],

            listaProjetos: {},
            listaProjetosPublic: {},
            projetoSelecionadoPorLinha: {}
        };
    },

    created() {
        // this.getTipologias();
        this.getModelosTipologia()
        this.getDadosObra()
    },

    methods: {

        async getDadosObra() {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`obra/obra/${this.$route.params.obra}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                this.obra = response.data
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getModelosTipologia() {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`projeto/modelo-variacao/?page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                this.lista_modelos = response.data.results
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getTipologias(modeloId) {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`/projeto/tipologia/?ativo=True&modelo=${modeloId}&page_size=9999`, {
                    headers: { Authorization: `Token ${token}` }
                });
                // console.log(response.data.results)
                this.tipologias = response.data.results
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getLinhas() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`projeto/linha-sistema/?page_size=9999`, {
                    headers: { Authorization: `Token ${token}` }
                });
                this.linhas = response.data.results;
            } catch (error) {
                console.error('Erro ao carregar linhas:', error);
            }
        },

        async onAvancarAba() {
            if (this.abas[this.abaSelecionada].nome === 'Geral') {
                // Verifica se todos os campos obrigatórios estão preenchidos
                // if (!this.vao.descricao || !this.vao.largura || !this.vao.altura || !this.vao.cor_componente || !this.vao.cor_perfil || !this.vao.cor_vidro) {
                //     this.erro = true
                //     this.erroMessage = "Preencha todos os campos obrigatórios."
                //     setTimeout(() => {
                //         this.erro = false;
                //         this.erroMessage = ''
                //     }, 3000);
                //     return;
                // }

                // Verifica se há tipologia adicionada
                const hasTipologiaAdicionada = this.abas.some(aba => aba.nome !== 'Geral');
                if (!hasTipologiaAdicionada) {
                    this.salvarVao()
                    return;
                }
            }

            if (this.abaSelecionada === this.abas.length - 1) {
                await this.salvarVao();
            } else {
                this.proximaAba();
            }
        },

        proximaAba() {
            if (this.abaSelecionada < this.abas.length - 1) {
                this.abaSelecionada++;
            }
        },

        voltarAba() {
            if (this.abaSelecionada > 0) {
                this.abaSelecionada--;
            }
        },

        onFileChange(file) {
            this.vao.imagem = file;
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        async onTipologiaChange() {
            await this.getLinhas();
            this.linhasSelecionadas = [];
        },

        // Adicionando a aba da tipologia
        async adicionarTipologia() {
            // console.log('click')
            const tipologia = this.tipologias.find(t => t.id === this.tipologiaSelecionada);
            if (tipologia && this.linhasSelecionadas.length > 0) {
                const uniqueAbaId = `${tipologia.linha}_${this.abas.length}`;

                this.abas.push({
                    nome: `${tipologia.nome}`,
                    ativo: true,
                    id: uniqueAbaId,
                    id_tipologia: tipologia.id,
                    linhas: [...this.linhasSelecionadas],
                    imagem: tipologia.imagem
                });

                for (const linha of this.linhasSelecionadas) {
                    const projetos = await this.buscarProjetos(tipologia.id, linha.linha);
                    this.projetosPorLinha[`${uniqueAbaId}_${linha.linha}`] = projetos;
                }

                this.tipologiaSelecionada = null;
                this.modeloSelecionado = ''
                this.linhasSelecionadas = [];
                this.linhas = [];
            }
        },

        // Busco projetos com aquela tipologia e aquela linha
        async buscarProjetos(tipologiaId, linhaNome) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(
                    `/projeto/projeto/?tipologia=${tipologiaId}&linha_nome=${linhaNome}`, {
                    headers: { Authorization: `Token ${token}` }
                });

                const linhaNome_tipologiaId = `${linhaNome}_${tipologiaId}`;

                this.listaProjetos[linhaNome_tipologiaId] = response.data.results;
                console.log('Chave composta:', linhaNome_tipologiaId);
                console.log('Lista de Projetos:', this.listaProjetos[linhaNome_tipologiaId]);

            } catch (error) {
                console.error('Erro ao buscar projetos:', error);
                return [];
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Criar o Vão
        async salvarVao() {
            console.log(this.vao)
            const formDataVao = new FormData();
            formDataVao.append('obra', this.$route.params.obra);
            formDataVao.append('ativo', 'true');
            formDataVao.append('descricao', this.vao.descricao);
            formDataVao.append('largura', this.vao.largura);
            formDataVao.append('altura', this.vao.altura);
            formDataVao.append('local_vao', this.vao.local_vao.id);
            if (this.vao.imagemFile != null) {
                formDataVao.append('imagem', this.vao.imagemFile);
            }
            formDataVao.append('cor_componente', this.vao.cor_componente);
            formDataVao.append('cor_perfil', this.vao.cor_perfil);
            formDataVao.append('cor_vidro', this.vao.cor_vidro);
            formDataVao.append('codigo_arquitetonico', this.vao.codigo_arquitetonico);

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.post('obra/vao-obra/', formDataVao, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.id_vao_criado = response.data.id
                this.salvarListaProjetos(this.id_vao_criado)

                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$router.push(`/obra/${this.$route.params.obra}`);
                }, 3000);
            } catch (error) {
                console.error('Erro ao criar vão:', error);
                this.erro = true;
                this.erroMessage = "Ocorreu um erro ao criar vão."
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = ''
                }, 3000);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        salvarProjetos(projeto) {
            // Salva o projeto selecionado no objeto `projetosSelecionados`
            this.projetosSelecionados[`${projeto.abaId}_${projeto.nomeDaLinha.linha}`] = projeto;
            console.log(this.projetosSelecionados);
        },

        // Função para salvar os projetos associados ao vão

        async salvarListaProjetos(id_vao) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            const promises = [];

            for (const aba of this.abas) {
                // Garante que a aba possui linhas e uma tipologia válida
                if (aba.nome !== 'Geral' && Array.isArray(aba.linhas) && aba.linhas.length > 0 && aba.id_tipologia) {
                    for (const linha of aba.linhas) {
                        if (linha.id) {
                            const formData = new FormData();

                            formData.append('vao_obra', id_vao);
                            formData.append('ativo', 'true');
                            formData.append('tipologias', aba.id_tipologia);
                            formData.append('linhas', linha.id);

                            // Verifica se há projeto associado à linha atual
                            const projetoAssociado = Object.values(this.projetosSelecionados).find(
                                projeto => projeto.nomeDaLinha && projeto.nomeDaLinha.id === linha.id
                            );

                            if (projetoAssociado) {
                                formData.append('projeto', projetoAssociado.projeto.id);
                            }

                            // Adiciona a promise para salvar o registro
                            promises.push(
                                axios.post(`obra/linhas-projeto-vao-obra/`, formData, {
                                    headers: { Authorization: `Token ${token}` }
                                })
                                    .then(() => {
                                        console.log(
                                            `Linha ${linha.id} com tipologia ${aba.id_tipologia}` +
                                            (projetoAssociado ? ` e projeto ${projetoAssociado.projeto.id}` : '') +
                                            ' salva com sucesso.'
                                        );
                                    })
                                    .catch(error => {
                                        console.error(
                                            `Erro ao salvar linha ${linha.id} com tipologia ${aba.id_tipologia}` +
                                            (projetoAssociado ? ` e projeto ${projetoAssociado.projeto.id}` : '') +
                                            ':', error
                                        );
                                    })
                            );
                        }
                    }
                }
            }

            // Aguarda todas as promises serem concluídas
            this.$store.dispatch("setLoading", true);
            try {
                await Promise.all(promises);
                console.log('Todas as operações foram concluídas com sucesso.');
            } catch (error) {
                console.error('Erro em uma ou mais operações:', error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },


        // async salvarListaProjetos(id_vao) {
        //     const token = await this.$store.dispatch('getAuthTokenCookie');
        //     await this.$store.dispatch('fetchURLrequest');

        //     // Verifica se há projetos selecionados
        //     const hasProjetos = Object.keys(this.projetosSelecionados).length > 0;

        //     if (hasProjetos) {
        //         // Envia os projetos selecionados com suas linhas e tipologias
        //         for (const chave in this.projetosSelecionados) {
        //             const projetoIndex = this.projetosSelecionados[chave];

        //             if (projetoIndex && projetoIndex.projeto && projetoIndex.nomeDaLinha && projetoIndex.projeto.id) {
        //                 const { projeto, nomeDaLinha } = projetoIndex;

        //                 // Encontra a tipologia associada à chave do projeto e linha específicos
        //                 const [tipologiaNome, linha] = chave.split('_');
        //                 const tipologiaAssociada = this.abas.find(aba => aba.nome === tipologiaNome);

        //                 if (tipologiaAssociada && tipologiaAssociada.id_tipologia) {
        //                     const formDataProjeto = new FormData();

        //                     formDataProjeto.append('vao_obra', id_vao);
        //                     formDataProjeto.append('ativo', 'true');
        //                     formDataProjeto.append('projeto', projeto.id);
        //                     formDataProjeto.append('linhas', nomeDaLinha.id);
        //                     formDataProjeto.append('tipologias', tipologiaAssociada.id_tipologia);

        //                     this.$store.dispatch("setLoading", true);
        //                     try {
        //                         await axios.post(`obra/linhas-projeto-vao-obra/`, formDataProjeto, {
        //                             headers: {
        //                                 Authorization: `Token ${token}`
        //                             }
        //                         });
        //                         console.log(`Projeto ${projeto.id} com tipologia ${tipologiaAssociada.nome} e linha ${nomeDaLinha.linha} salvo com sucesso.`);
        //                     } catch (error) {
        //                         console.error(`Erro ao enviar o projeto ${projeto.id} com linha ${nomeDaLinha.linha}:`, error);
        //                     } finally {
        //                         this.$store.dispatch("setLoading", false);
        //                     }
        //                 } else {
        //                     console.warn(`Tipologia associada não encontrada ou inválida para o projeto ${projeto.id} e linha ${nomeDaLinha.linha}`);
        //                 }
        //             } else {
        //                 console.warn(`Projeto ou linha inválido na chave ${chave}.`);
        //             }
        //         }
        //     }

        //     // Salva as tipologias que não possuem projetos associados
        //     for (const aba of this.abas) {
        //         if (aba.nome !== 'Geral' && Array.isArray(aba.linhas) && aba.linhas.length > 0 && aba.id_tipologia) {
        //             const formDataSemProjetos = new FormData();

        //             formDataSemProjetos.append('vao_obra', id_vao);
        //             formDataSemProjetos.append('ativo', 'true');
        //             formDataSemProjetos.append('tipologias', aba.id_tipologia);

        //             // Verifica se a tipologia já foi associada a um projeto selecionado, caso contrário, salva separadamente
        //             const isAssociatedWithProject = Object.values(this.projetosSelecionados).some(projeto =>
        //                 projeto.nomeDaLinha && aba.linhas.some(linha => linha.id === projeto.nomeDaLinha.id)
        //             );

        //             if (!isAssociatedWithProject) {
        //                 // Adiciona as linhas específicas da tipologia atual
        //                 for (const linha of aba.linhas) {
        //                     if (linha.id) {
        //                         formDataSemProjetos.append('linhas', linha.id);
        //                     }
        //                 }

        //                 this.$store.dispatch("setLoading", true);
        //                 try {
        //                     await axios.post(`obra/linhas-projeto-vao-obra/`, formDataSemProjetos, {
        //                         headers: {
        //                             Authorization: `Token ${token}`
        //                         }
        //                     });
        //                     console.log(`Tipologia ${aba.id_tipologia} com suas linhas salvas com sucesso (sem projetos).`);
        //                 } catch (error) {
        //                     console.error(`Erro ao salvar tipologia ${aba.id_tipologia} com suas linhas:`, error);
        //                 } finally {
        //                     this.$store.dispatch("setLoading", false);
        //                 }
        //             }
        //         }
        //     }
        // },

        async buscarCliente() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`/obra/?obra=${this.$route.params.obra}`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                )
                this.cliente = response.data.results[0].cliente.id
            } catch (error) {
                console.error(error)
            }
        },

        selecionarLinha(linhaIndex, aba) {
            this.linhaSelecionada = linhaIndex;
            const chave = `${aba.id}_${aba.linhas[linhaIndex].linha}`;
            this.mostrarVariaveis = !!this.mostrarVariaveisPorLinha[chave];
        },

    }
};
</script>

<style scoped>
label {
    display: block;
    font-weight: bold;
}

input[type="file"],
input[type="number"],
input[type="text"],
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.abas-container {
    display: flex;
    margin-top: 15px;
    @apply mt-4 p-2 border-b rounded-lg
}

.aba {
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    @apply rounded-lg bg-white text-corBase border border-white
}

.aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.foto-tipologia {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    z-index: 10;
}

.linhas-abas-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 5px;
}

.linha-aba {
    padding: 10px;
    cursor: pointer;
    margin-top: 2px;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    @apply rounded-lg bg-white text-corBase border border-white
}

.linha-aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-foto {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
</style>
