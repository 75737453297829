<template>


  <!-- TEMPLATE DASHBOARD TIPOS PRA CIMA DE 768 PIXELS -->
  <div class="container-formulario">
    <div class="cabecalho-dashboard">
      <h1>Cadastro de Tipos</h1>

      <button @click="$router.push(`/cadastros`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap h-[66vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-corBase/40 pb-8">

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-chapa')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Chapa</h2>
            <i class="fa-solid fa-layer-group"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Chapa presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-componente')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Componente</h2>
            <i class="fa-solid fa-gears"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Componente presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-email')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Email</h2>
            <i class="fa-solid fa-envelope"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Email presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-endereco')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Endereço</h2>
            <i class="fa-solid fa-location-dot"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Endereço presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-financeiro`)">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Financeiro</h2>
            <i class="fa-solid fa-money-bill"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre o Financeiro presente em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/lista-tipo-fornecedor')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Fornecedor</h2>
            <i class="fa-solid fa-truck-field"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Fornecedor presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-perfil')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Perfil</h2>
            <i class="fa-solid fa-ruler-combined"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Perfis presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-pessoa')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Pessoa</h2>
            <i class="fas fa-user"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Pessoa presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-produto-base')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Produto Base - Mudar</h2>
            <i class="fas fa-user"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Produto Base presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-servico')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Serviço</h2>
            <i class="fa-solid fa-hammer"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Serviço presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-telefone')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Telefone</h2>
            <i class="fa-solid fa-phone"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Telefone presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-uso-consumo')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Uso Consumo</h2>
            <i class="fa-solid fa-box-open"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Uso de Consumo presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-vidro')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Vidro - Mudar</h2>
            <i class="fa-solid fa-window-maximize"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Tipos de Vidro presentes em seu sistema!</p>
          </div>
        </div>
      </button>

    </div>
  </div>


</template>

<script>

export default {
  name: "Tipos",

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
  }


}

</script>

<style lang="scss"></style>