<template>
  <div class="container-formulario">
    <!-- Cabeçalho -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Alterar Produtos da Tabela</h1>
      <router-link to="/perfil" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Botões para selecionar qual seção exibir -->
    <div class="flex gap-4 mb-6">
      <button @click="mostrarComponente('dados')" class="px-2 py-1 bg-corBase text-white">Dados</button>
      <button @click="mostrarComponente('precoKG')" class="px-2 py-1 bg-corBase text-white">Preço por KG</button>
      <button @click="mostrarComponente('precoLinha')" class="px-2 py-1 bg-corBase text-white">Preço por Linha</button>
      <button @click="mostrarComponente('tratamentoLinha')" class="px-2 py-1 bg-corBase text-white">Pintura</button>
    </div>

    <!-- Exibir o componente selecionado -->
    <div v-if="componenteAtual === 'dados'">
      <DadosPerfil :dadosPerfil="dadosPerfil" />
    </div>
    <div v-if="componenteAtual === 'precoKG'">
      <PrecoKG :dadosPerfil="dadosPerfil" />
    </div>
    <div v-if="componenteAtual === 'precoLinha'">
      <PrecoLinha :dadosPerfil="dadosPerfil" />
    </div>
    <div v-if="componenteAtual === 'tratamentoLinha'">
      <TratamentoLinha :dadosPerfil="dadosPerfil" />
    </div>

    <!-- Botões de Cancelar e Salvar -->
    <div class="flex w-full justify-end itens-center mt-6">
      <button class="btn-cancelar" @click.prevent="$router.push('/perfil')">Cancelar</button>
      <button class="btn-salvar" @click.prevent="salvarDados">Salvar</button>
    </div>
  </div>
  <SucessoRapido v-if="sucesso === true" mensagem="Tabela alterada com sucesso!" />
</template>

<script>
import axios from 'axios';
import Select2 from "vue3-select2-component";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import Pagination from '@/utils/Pagination.vue';
import DadosPerfil from './DadosPerfil.vue'
import PrecoKG from './PrecoKG.vue'
import PrecoLinha from './PrecoLinha.vue'
import TratamentoLinha from './TratamentoLinha.vue'
import formatarData from '@/utils/FormatarData'

export default {
  name: 'AlterarPerfil',
  components: {
    Select2,
    SucessoRapido,
    Pagination,
    DadosPerfil,
    PrecoKG,
    PrecoLinha,
    TratamentoLinha
  },
  data() {
    return {
      componenteAtual: 'dados',
      sucesso: false,
      dadosPerfil: {},
      produtosTabela: [],
      novoProduto: {
        custo: 0,
        perfil: null,
        margem_lucro_ml: 0,
        margem_lucro_balcao: 0
      },
      listaProdutos: [],
      adicionarProdutoAtivo: false,
      paginaAtual: 1,
      countItens: 0,
      next_page: null,
      itemsPorPagina: 5,
    };
  },
  methods: {
    mostrarComponente(nomeComponente) {
      this.componenteAtual = nomeComponente;
    },
    
    formatarDataInput(dataISO) {
      const data = new Date(dataISO);
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();
      return `${ano}-${mes}-${dia}`;
    },

    async buscarDadosPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`precificacao/tabelas-perfil/${this.$route.params.id}/`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.dadosPerfil = response.data;
        this.dadosPerfil.criado_em_formatado = this.formatarDataInput(this.dadosPerfil.criado_em);
      } catch (error) {
        console.error("Erro ao buscar dados da tabela de perfil:", error);
      }
    },

    // async buscarListaProdutos() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie');
    //   await this.$store.dispatch('fetchURLrequest');
    //   try {
    //     const response = await axios.get('/produtos/perfil/', {
    //       headers: {
    //         Authorization: `Token ${token}`
    //       }
    //     });
    //     this.listaProdutos = response.data.results.map(produto => ({
    //       id: produto.id,
    //       text: produto.perfil_base.nome_especifico,
    //       preco: produto.perfil_base.preco_custo
    //     }));
    //   } catch (error) {
    //     console.error("Erro ao buscar lista de produtos:", error);
    //   }
    // },

    // async adicionarProduto() {
    //   if (this.produtoPreenchido) {
    //     const token = await this.$store.dispatch('getAuthTokenCookie');
    //     await this.$store.dispatch('fetchURLrequest');
    //     try {
    //       const response = await axios.post(`precificacao/itens-tabelas-perfil/`, {
    //         tabela_preco_perfil: this.dadosPerfil.id,
    //         perfil: this.novoProduto.perfil,
    //         margem_lucro_ml: this.novoProduto.margem_lucro_ml,
    //         margem_lucro_balcao: this.novoProduto.margem_lucro_balcao,
    //         custo: this.novoProduto.custo
    //       }, {
    //         headers: {
    //           Authorization: `Token ${token}`
    //         }
    //       });

    //       this.buscarProdutosTabelaPerfil(1)

    //       this.novoProduto = {
    //         nome: '',
    //         custo: 0,
    //         margem_lucro_ml: 0,
    //         margem_lucro_balcao: 0
    //       }

    //       this.adicionarProdutoAtivo = false;
    //     } catch (error) {
    //       console.error("Erro ao adicionar produto:", error);
    //     }
    //   }
    // },

    async salvarDados() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        await axios.put(`precificacao/tabelas-perfil/${this.$route.params.id}/`, this.dadosPerfil, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$router.push('/perfil');
        }, 3000);
      } catch (error) {
        console.error("Erro ao salvar dados da tabela de perfil:", error);
      }
    },
  },
  created() {
    this.buscarDadosPerfil();
    // this.buscarListaProdutos();
    // this.buscarProdutosTabelaPerfil(1);
  },
  watch: {
    'novoProduto.perfil': function (novoPerfil) {
      this.novoProduto.custo = this.buscarPrecoPerfil(novoPerfil);
    }
  }
};
</script>

<style scoped>
.container-formulario {
  padding: 20px;
}

.cabecalho-formulario {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
