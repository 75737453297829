<template>
    <div>
        <div class="container-modal-editar">
            <div class="sombra-modal">
                <div class="conteudo-modal-editar">
                    <form class="form-editar text-white">
                        <div class="flex justify-end">
                            <button @click.prevent="$emit('fecharModal')">
                                <i class="fas fa-close text-2xl text-corBase"></i>
                            </button>
                        </div>
                        <h2 class="text-lg text-corBase font-bold mb-2">Editar Método Pagamento</h2>
                        
                        <div>
                            <div class="container-campos">
                                <div class="campos campos-mobile label-campos-popup">
                                    <label class="label-campos label-campos-popup">Método:</label>
                                    <input type="text" required class="input-campos" v-model="dadosMetodo.metodo">
                                </div>

                                <div class="container-campos w-full">

                                    <div class="flex flex-col mt-2 w-full justify-start ">
                                        <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                                        <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                                            <i v-if="dadosMetodo.ativo === true"
                                                class="fa-solid fa-circle-check text-ativo"></i>
                                            <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <!--Botão-->
                            <div class="btn-formulario-modal">
                                <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <SucessoRapido v-if="sucesso" mensagem="Método atualizado com sucesso." />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
    name: "EditarMetodoPagamento",
    components: {
        SucessoRapido
    },
    data() {
        return {
            sucesso: false,
        }
    },
    props: ["dadosMetodo"],
    emits: ["fecharModal"],
    created() {
        console.log(this.dadosMetodo)
    },
    methods: {

        toggleAtivo() {
            this.dadosMetodo.ativo = !this.dadosMetodo.ativo
        },

        async putDadosEditado() {
            const dadosAtualizados = new FormData();
            dadosAtualizados.append('ativo', this.dadosMetodo.ativo)
            dadosAtualizados.append('metodo', this.dadosMetodo.metodo)


            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest')
                const response = await axios.patch(`financeiro/metodos-pagamento/${this.dadosMetodo.id}/`, dadosAtualizados, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                });
                console.log(response);
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal')
                }, 3000);

            } catch (error) {
                console.log(error)
            }
        },

    }
}
</script>

<style></style>