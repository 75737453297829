<template>

  <div class=" flex justify-center md:block md:justify-end md:mr-10">

    <div class="container-formulario overflow-y-auto h-screen">
      <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Projeto:</h1>

        <!-- <router-link 
          class="btn-salvar"
          :to="'/incluir-item-orcamento/' + projeto_id"
        >
          Validar
        </router-link> -->

        <router-link to="/lista-projetos" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <navgecacao-projeto @navegar-opcoes="navegarOpcoes" @toggleFormulasVariaveis="toggleFormulasVariaveis"
      @fecharTodasAbas="fecharTodasAbas" />

      <template v-if="opcoes.dados === true">
        <!-- <projeto-dados-compornent :dadosProjeto="dadosProjeto" /> -->
        <dados-projeto :dadosProjeto="dadosProjeto" />
      </template>

      <template v-if="opcoes.nota_fiscal === true">
        <!-- <projeto-dados-compornent :dadosProjeto="dadosProjeto" /> -->
        <nota-fiscal-projeto :dadosProjeto="dadosProjeto" />
      </template>

      <template v-if="opcoes.vendas === true">
        <!-- <projeto-dados-compornent :dadosProjeto="dadosProjeto" /> -->
        <vendas-projeto :dadosProjeto="dadosProjeto" />
      </template>

      <template v-if="opcoes.producao === true">
        <!-- <projeto-dados-compornent :dadosProjeto="dadosProjeto" /> -->
        <producao-projeto :dadosProjeto="dadosProjeto" />
      </template>

      <template v-if="opcoes.instalacao === true">
        <!-- <projeto-dados-compornent :dadosProjeto="dadosProjeto" /> -->
        <instalacao-projeto :dadosProjeto="dadosProjeto" />
      </template>

      <template v-if="opcoes.formulas_variaveis === true">
        <!-- <div class="flex w-full items-center justify-start mt-8 p-4 gap-3">
          <button class="px-2 py-1 bg-corBase text-white" @click.prevent="navegarOpcoesFormulasVariaveis('formulas')">
            Fórmulas
          </button>
          <button class="px-2 py-1 bg-corBase text-white"
            @click.prevent="navegarOpcoesFormulasVariaveis('variaveis')">Variáveis
          </button>
        </div> -->


        <!-- <template v-if="formulas === true">
          <formula-projeto :lista_formulas="lista_formulas" :projeto_id="projeto_id"
            @atualizarFormulas="putListaFormulasProjeto" @getListaFormulasProjeto="getListaFormulasProjeto" />
        </template>

        <template v-if="variaveis === true">
          <variaveis-projeto :lista_variaveis_locais="lista_variaveis_locais" :projeto_id="projeto_id"
            @atualizarVariaveis="putlistaVariaveis" @getListaVariaveisLocais="getListaVariaveisLocais" />
        </template> -->
      </template>

      <template v-if="opcoes.validacoes === true">
          <validacao-projeto :lista_formulas="lista_formulas" :projeto_id="projeto_id"
            @atualizarFormulas="putListaFormulasProjeto" @getListaFormulasProjeto="getListaFormulasProjeto" />
        </template>

      <template v-if="opcoes.formulas === true">
          <formula-projeto :lista_formulas="lista_formulas" :projeto_id="projeto_id"
            @atualizarFormulas="putListaFormulasProjeto" @getListaFormulasProjeto="getListaFormulasProjeto" />
        </template>

        <template v-if="opcoes.variaveis === true">
          <variaveis-projeto :lista_variaveis_locais="lista_variaveis_locais" :projeto_id="projeto_id"
            @atualizarVariaveis="putlistaVariaveis" @getListaVariaveisLocais="getListaVariaveisLocais" />
        </template>

      <template v-if="opcoes.perfis === true">
        <perfil-projeto :perfis="perfis" :projeto_id="projeto_id" @atualizarPerfis="putListaPerfis"
          @getListaPerfisProjeto="getListaPerfisProjeto" @atualizarUnicoPerfil="putPerfil" />
      </template>

      <template v-if="opcoes.vidros === true">
        <vidro-projeto :vidros="vidros" :projeto_id="projeto_id" @atualizarVidros="putListaVidros"
          @getListaVidrosProjeto="getListaVidrosProjeto" @atualizarUnicoVidro="putVidro" />
      </template>

      <template v-if="opcoes.componentes === true">
        <componente-projeto :componentes="componentes" :projeto_id="projeto_id"
          @atualizarComponentes="putListaComponentes" @getListaComponentesProjeto="getListaComponentesProjeto"
          @atualizarUnicoComponente="putComponente" />
      </template>

      <template v-if="opcoes.chapas_telas === true">
        <chapas-telas-projeto :vidros="vidros" :projeto_id="projeto_id" @atualizarVidros="putListaVidros"
          @getListaVidrosProjeto="getListaVidrosProjeto" @atualizarUnicoVidro="putVidro" />
      </template>

      <template v-if="opcoes.servicos === true">
        <div>
          <servico-projeto :servicos="servicos" :projeto_id="projeto_id" @atualizarServicos="putListaServicos" />
        </div>
      </template>

      <template v-if="opcoes.imagem_capa === true">
        <div>
          <imagem-capa-projeto :projeto_id="projeto_id" @atualizarImagens="putListaServicos" />
        </div>
      </template>

      <template v-if="opcoes.imagens_variaveis === true">
        <div>
          <imagens-variaveis-projeto :projeto_id="projeto_id" @atualizarImagens="putListaServicos" />
        </div>
      </template>

    </div>

  </div>

  <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso" />

</template>

<script>

import Select2 from "vue3-select2-component";
import axios from "axios";
import ModalFormulaProjeto from "@/components/midais/alterar_projeto/ModalFormulaProjeto";
import ProjetoDadosCompornent from "@/components/cadastros_projetos_componentes/alterar_projeto/ProjetoDadosCompornent";
import DadosProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/DadosProjeto.vue";
import NotaFiscalProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/NotaFiscalProjeto.vue";
import VendasProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/VendasProjeto.vue";
import ProducaoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/ProducaoProjeto.vue";
import InstalacaoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/InstalacaoProjeto.vue";
import NavgecacaoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/NavgecacaoProjeto";
import FormulaProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/FormulaProjeto";
import VariaveisProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/VariaveisProjeto";
import ValidacaoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/ValidacaoProjeto";
import PerfilProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/PerfilProjeto";
import VidroProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/VidroProjeto";
import ComponenteProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/ComponenteProjeto";
import ChapasTelasProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/ChapasTelasProjeto.vue";
import ImagemCapaProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/ImagemCapaProjeto.vue";
import ImagensVariaveisProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/ImagensVariaveisProjeto.vue";
import ServicoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/ServicoProjeto";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "AlterarProjeto",

  components: {
    Select2,
    ModalFormulaProjeto,
    // componente navegacao
    NavgecacaoProjeto,
    // componentes de cada aba
    ProjetoDadosCompornent,
    DadosProjeto,
    NotaFiscalProjeto,
    VendasProjeto,
    ProducaoProjeto,
    InstalacaoProjeto,
    FormulaProjeto,
    VariaveisProjeto,
    ValidacaoProjeto,
    PerfilProjeto,
    VidroProjeto,
    ComponenteProjeto,
    ChapasTelasProjeto,
    ServicoProjeto,
    ImagemCapaProjeto,
    ImagensVariaveisProjeto,
    SucessoRapido
  },

  data() {
    return {
      // variaveis dados dos produtos
      dadosProjeto: '',
      tipologia: '',
      cod_produto: '',
      descricao_fixa: '',
      descricao_variavel: '',
      unidade: '',
      ncm: '',
      lib_venda: '',
      kit: '',
      kit_cor_vidro: '',
      kit_cor_perfil: '',
      kit_cor_acessorio: '',
      cfop_saida: '',
      cfop_fora_uf: '',
      temp_producao: '',
      temp_instalacao: '',
      status_projeto: '',
      perfis: [],
      vidros: [],
      servicos: [],
      componentes: [],
      altura_minima: '',
      altura_maxima: '',
      largura_minima: '',
      largura_maxima: '',
      espessuras: [],

      opcoes: [],

      formulas: false,
      variaveis: false,

      // variaveis para salvar as listas
      lista_formulas: [],
      lista_variaveis_locais: [],
      lista_tipologias: [],
      lista_componentes: [],
      lista_perfis: [],
      lista_vidros: [],
      lista_servicos: [],
      lista_espessuras: [],

      // variaveis para dados do produto
      activeSection: 1,
      checkAll: false,
      sucesso: false

    }
  },


  created() {

    // faz as requisições das listas e projeto para poder usar nas alterações
    this.getDadosProjeto()
    this.getListaPerfisProjeto()
    this.getListaServicoProjeto()
    this.getListaVidrosProjeto()
    this.getListaComponentesProjeto()
    this.getListaStatus()
    this.getListaTipologia()
  },

  computed: {

    // pega o id passado como parametro na rota
    projeto_id() {
      return this.$route.params.id
    },

    // pega a empresa que esta logada de dentro da store
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  watch: {
    sucesso() {
      if (this.sucesso) {
        setTimeout(() => {
          this.sucesso = false
        }, 2000)
      }
    }
  },


  methods: {

    // evento que é ativado pelo filho
    navegarOpcoes(nomeVariavel) {
      for (const key in this.opcoes) {
        if (key !== nomeVariavel) {
          this.opcoes[key] = false; // Define todas as opções como false
        }
      }
      this.opcoes[nomeVariavel] = !this.opcoes[nomeVariavel]; // Alterna a opção selecionada
    },

    fecharTodasAbas(){
      this.opcoes = []
    },

    toggleFormulasVariaveis(opcao) {
      if (opcao == 'Fórmula') {
        // alert('formula')
        this.formulas = true
        this.variaveis = false
        console.log('formulas: ' + this.formulas + 'variaveis: ' + this.variaveis)
      }
      if (opcao == 'Variável') {
        // alert('variavel')
        this.variaveis = true
        this.formulas = false
        console.log('formulas: ' + this.formulas + 'variaveis: ' + this.variaveis)
      }
    },

    // funcao para navegar nos botos dentro de formulas/variaveis
    navegarOpcoesFormulasVariaveis(variavel) {
      // console.log('testado')
      this.formulas = false
      this.variaveis = false
      if (variavel == 'formulas') {
        this[variavel] = !this[variavel];
        this.getListaFormulasProjeto()
      }
      if (variavel == 'variaveis') {
        this[variavel] = !this[variavel];
        this.getListaVariaveisLocais()
      }

    },

    // seleciona todos os items das listas
    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getDadosProjeto() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/projeto/${this.projeto_id}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.dadosProjeto = response.data
      } catch (error) {
        console.error(error)
      }
    },

    // pesquisas no banco
    // busca a lista de formulas relacionadas ao projeto
    async getListaFormulasProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/formula-projeto/?projeto=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_formulas = response.data.results
        })
        .catch(error => {
          console.error(error)
        })

    },

    // altera formulas relacionadas ao projeto
    async putListaFormulasProjeto(formulas) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = formulas.map((formula) => {
        formula.projeto ? formula.projeto = this.projeto_id : ''

        return formula
      })

      await axios.put(`projeto/formula-projeto-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaFormulasProjeto()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },

    // busca a lista de variaveis relacionadas ao projeto
    async getListaVariaveisLocais() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/variavel-local/?projeto=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_variaveis_locais = response.data.results
        })
        .catch(error => {
          console.error(error)
        })

    },

    async putlistaVariaveis(lista_variaveis_locais) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = lista_variaveis_locais.map(variavel => {
        variavel.projeto ? variavel.projeto = this.projeto_id : ''

        return variavel
      })

      await axios.put(`projeto/variavel-local-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaVariaveisLocais()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },

    // busca as tipologias
    async getListaTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/tipologia', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_tipologias = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }));
          console.log('TIPOLOGIAS')
          console.log(this.lista_tipologias)
          console.log('------------------')
        })
        .catch(error => {
          console.error(error)
        })

    },

    async putPerfil(perfilId, perfilProjetoId) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = { perfil_id: perfilId }

      await axios.patch(`projeto/projeto-perfil/${perfilProjetoId}/`, data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      }).then(response => {
        this.getListaPerfisProjeto()
        this.sucesso = true
      })
        .catch(error => {
          console.error(error)
        })
    },

    // atualiza os perfeis do projeto
    async putListaPerfis(perfis) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = perfis.map((perfil) => {
        perfil.perfil_id ? perfil.perfil_id = perfil.perfil_id.id : ''
        perfil.projeto_id ? perfil.projeto_id = this.projeto_id : ''

        return perfil
      })

      await axios.put(`projeto/projeto-perfil-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaPerfisProjeto()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },

    async putVidro(vidroId, vidroProjetoId) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      console.log(vidroId)
      console.log(vidroProjetoId)

      const data = { vidro_id: vidroId }

      await axios.patch(`projeto/projeto-vidro/${vidroProjetoId}/`, data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      }).then(response => {
        this.getListaVidrosProjeto()
        this.sucesso = true
      })
        .catch(error => {
          console.error(error)
        })
    },

    // atualiza os vidros do projeto
    async putListaVidros(vidros) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = vidros.map((vidro) => {
        vidro.vidro_id ? vidro.vidro_id = vidro.vidro_id.id : ''
        vidro.area_vidro ? vidro.area_vidro = vidro.area_vidro.id : ''
        vidro.projeto_id ? vidro.projeto_id = this.projeto_id : ''

        return vidro
      })

      await axios.put(`projeto/projeto-vidro-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaVidrosProjeto()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },

    async putComponente(componenteId, componenteProjetoId) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      console.log(componenteId)
      console.log(componenteProjetoId)

      const data = { componente_id: componenteId }

      await axios.patch(`projeto/projeto-componente/${componenteProjetoId}/`, data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      }).then(response => {
        this.getListaComponentesProjeto()
        this.sucesso = true
      })
        .catch(error => {
          console.error(error)
        })
    },

    // atualiza os componentes do projeto
    async putListaComponentes(componentes) {

      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = componentes.map(componente => {
        componente.componente_id ? componente.componente_id = componente.componente_id.id : ''
        componente.projeto_id ? componente.projeto_id = this.projeto_id : ''

        return componente
      })

      await axios.put(`projeto/projeto-componente-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaComponentesProjeto()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },

    // atualiza os servico do projeto
    async putListaServicos(servicos) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = servicos
      console.log(data)

      await axios.put(`projeto/projeto-servico-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaServicosProjeto()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },

    // atualiza as imagens do projeto
    async putListaImagens(imagem) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = imagem
      console.log(data)

      await axios.put(`projeto/projeto-imagem-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.getListaImagensProjeto()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        })

    },


    // busca as listas
    async getListaStatus() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/status-projeto', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_status = response.data.results.map(item => ({
            id: item.id,
            text: item.descricao
          }));
          console.log(this.lista_status)
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaPerfisCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/perfil', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_perfis = response.data.results.map(item => ({
            id: item.id,
            text: item.nome_especifico
          }));
          console.log(this.lista_perfis)
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaPerfisProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-perfil/?projeto_id=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.perfis = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaServicoProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-servico/?projeto_id=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.servico = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaVidrosCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/vidro', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_vidros = response.data.results.map(item => ({
            id: item.id,
            text: item.vidro_base.nome_especifico
          }));
          console.log(this.lista_vidros)
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaVidrosProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-vidro/?projeto_id=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.vidros = response.data.results
          console.log('VIDROS')
          console.log(this.vidros)
          console.log('---------------')
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaComponentesCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/componente-acessorio', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_componentes = response.data.results.map(item => ({
            id: item.id,
            text: item.nome_especifico
          }));
          console.log(this.lista_componentes)
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaComponentesProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-componente/?projeto_id=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.componentes = response.data.results
          console.log(this.componentes)
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaServicosCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/servico', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_servicos = response.data.results.map(item => ({
            id: item.id,
            text: item.nome_especifico
          }));
          console.log(this.lista_servicos)
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaServicosProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/servico/?projeto=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.servicos = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaImagensProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/imagem/?projeto=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.servicos = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaEspessurasCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/espessura', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_espessuras = response.data.results.map(item => ({
            id: item.id,
            text: item.espessura
          }));
          console.log(this.lista_espessuras)
        })
        .catch(error => {
          console.error(error)
        })
    },

  }

}


</script>


<style lang="scss">

// style mobile
.nav-principal-projetos-mobile {
  @apply flex gap-4 w-full flex-wrap mt-8
}

.btn-nav-projeto-mobile {
  @apply flex items-center bg-corBase/10 text-corBase px-1 py-0.5
}
</style>