<template>
  <div className="fixed inset-0 flex flex-col items-center justify-center bg-black z-[20005] bg-opacity-70">

    <button class="w-full flex justify-end px-24" @click.prevent="closedPreviewImage">
      <i class="fas fa-xmark text-5xl text-white"></i>
    </button>

    <div className="w-full">
      <img :src="imagem" class="w-1/3 mx-auto"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewImage.vue",
  props: ["imagem"],
  methods: {
    closedPreviewImage() {
      this.$emit('closed')
    }
  },
};
</script>

<style scoped>
/* Adicione estilos adicionais conforme necessário */
</style>
