<!-- src/components/ContasAPagar.vue -->
<template>
    <div class="container-formulario">

        <div class="cabecalho-dashboard">
            <h1>Contas a Pagar</h1>

            <button @click="$router.push(`/financeiro`)" class="text-2xl">
                <i class="fas fa-chevron-left mr-1 "></i>
            </button>
        </div>

        <!-- Ações -->
        <div class="flex gap-4 mb-6 items-end w-[100%]">
            <div class="w-full">
                <!-- <label class="text-corBase">Cliente</label>
                <input v-model="clienteFilter" v-on:input="clienteFilter = clienteFilter.toUpperCase()"
                    class="w-full z-10" type="text"> -->
            </div>
            <div>
                <button class="btn-add-fisica" @click="getContas(1)">Pesquisar</button>
            </div>
            <div>
                <button @click="$router.push(`/criar-conta-pagar`)" class="btn-salvar whitespace-nowrap">
                    Nova Conta
                </button>
            </div>
        </div>

        <div class="flex text-center items-center justify-center gap-6 p-4">
            <!-- Pendente -->
            <div
                class="flex flex-col items-center justify-center bg-yellow-500 text-white w-40 h-40 rounded-lg shadow-md border-2 border-yellow-700">
                <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                <h2 class="text-xl font-bold">Pendente</h2>
                <h3 class="text-lg font-semibold mt-2">R$ 1.354,44</h3>
            </div>

            <!-- Próximos 7 dias -->
            <div
                class="flex flex-col items-center justify-center bg-orange-500 text-white w-40 h-40 rounded-lg shadow-md border-2 border-orange-700">
                <i class="fa-solid fa-calendar fa-bounce"></i>
                <h2 class="text-xl font-bold">Próximos 7 dias</h2>
                <h3 class="text-lg font-semibold mt-2">R$ 597,44</h3>
            </div>

            <!-- Pago -->
            <div
                class="flex flex-col items-center justify-center bg-green-500 text-white w-40 h-40 rounded-lg shadow-md border-2 border-green-700">
                <i class="fa-solid fa-money-bill-transfer fa-flip"></i>
                <h2 class="text-xl font-bold">Pago</h2>
                <h3 class="text-lg font-semibold mt-2">R$ 5.315,00</h3>
            </div>

            <!-- Em atraso -->
            <div
                class="flex flex-col items-center justify-center bg-red-500 text-white w-40 h-40 rounded-lg shadow-md border-2 border-red-700">
                <i class="fa-solid fa-triangle-exclamation fa-beat"></i>
                <h2 class="text-xl font-bold">Em atraso</h2>
                <h3 class="text-lg font-semibold mt-2">R$ 0,00</h3>
            </div>
        </div>

        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr class="bg-gray-100 text-left">
                        <th class="p-2 border-b">Fornecedor</th>
                        <th class="p-2 border-b">Valor</th>
                        <th class="p-2 border-b">Data de Vencimento</th>
                        <th class="p-2 border-b">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="conta in contasPagar" :key="conta.id" class="hover:bg-gray-50">
                        <td class="p-2 border-b">{{ conta.fornecedor }}</td>
                        <td class="p-2 border-b">R$ {{ conta.valor.toFixed(2) }}</td>
                        <td class="p-2 border-b">{{ conta.data_vencimento }}</td>
                        <td class="p-2 border-b">
                            <span :class="statusClass(conta.status)">
                                {{ conta.status }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination v-if="contasPagar.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Pagination from '@/utils/Pagination.vue';

export default {
    name: "ContasAPagar",

    components: {
        Pagination
    },

    data() {
        return {
            contasPagar: [
                { id: 1, fornecedor: "Fornecedor A", valor: 300.00, data_vencimento: "2024-11-20", status: "Em atraso" },
                { id: 2, fornecedor: "Fornecedor B", valor: 300.00, data_vencimento: "2024-11-20", status: "Pendente" },
                { id: 3, fornecedor: "Fornecedor C", valor: 1200.00, data_vencimento: "2024-11-30", status: "Pago" },
            ],

            paginaAtual: 1,
            countItens: '4',
        };
    },
    methods: {
        statusClass(status) {
            return {
                'text-green-600': status === "Pago",
                'text-yellow-600': status === "Pendente",
                'text-red-600 font-bold': status === "Em atraso",
            };
        }, 

        async getContas(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`contas-a-pagar/?page=${pagina}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response.data.results)
                // this.contasPagar = response.data.results
                // this.countItens = response.data.count
            } catch (error) {
                console.error(error)
            }
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getContas(pagina);
        },
    }
};
</script>

<style scoped></style>
