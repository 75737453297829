<template>
  <div class="fundo-midal" v-if="midal==true">
    <div class="midal">

      <div class="secao-mensagem-sucesso-midal">
        <p class="mensagem-sucesso-midal">{{ mensagem }}</p>
        <div class="fundo-icone-deu-errado"><i class="fa-solid fa-x icone-deu-errado"></i></div>
      </div>

      <div class="secao-botao">
        <router-link class="botao-midal botao-midal-voltar" :to="rotas">Cancelar</router-link>
        <button type="button" class="botao-midal botao-midal-continuar" @click.prevent="fecharMidal()">Tentar Novamente</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MensagemSucesso",

  data(){
    return{
      midal:true,
    }
  },

  props: {
    mensagem: String,
    rotas: String,
  },

  created() {
    this.midal = true
  },

  methods:{
    fecharMidal(){
      this.midal = !this.midal
    }
  }
}
</script>

<style lang="scss" scoped>
.fundo-midal{
  @apply absolute flex justify-center items-center bg-black bg-opacity-10 left-0 w-screen h-screen z-[1000]
}

.midal{
  @apply absolute z-[1000] flex flex-col justify-between items-center bg-white w-72 h-96 py-6 px-4 rounded-xl shadow-xl
}

.secao-icone-fechar{
  @apply w-full flex justify-end
}

.icone-fechar{
  @apply text-corBase text-2xl cursor-pointer
}

.secao-mensagem-sucesso-midal{
  @apply flex flex-col items-center
}

.mensagem-sucesso-midal{
  @apply flex flex-col items-center text-corBase font-bold text-center text-lg
}

.fundo-icone-deu-errado{
  @apply flex justify-center items-center mt-8 bg-inativo w-20 h-20 rounded-full
}

.icone-deu-errado{
  @apply text-white text-5xl
}

.secao-botao{
  @apply w-full flex justify-between
}

.botao-midal{
  @apply flex  justify-center items-center w-24 h-8 bg-white text-sm font-medium rounded-md
}

.botao-midal-voltar{
  @apply bg-corBase text-white
}

.botao-midal-continuar{
  @apply bg-ativo text-white
}
</style>