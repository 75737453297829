<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-6 py-2 text-center">#</th>
          <th scope="col" class="px-6 py-2 text-center">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-2 text-start">Pesquisar</th>
          <th scope="col" class="px-6 py-2 text-start">Perfil</th>
          <th scope="col" class="px-6 py-2 text-start">Imagem</th>
          <th scope="col" class="px-6 py-2 text-start">Descrição</th>
          <th scope="col" class="px-6 py-2 text-center">Peso</th>
          <th scope="col" class="px-6 py-2 text-start">Corte</th>
          <th scope="col" class="px-6 py-2 text-start">QTDE/Fórmula</th>
          <th scope="col" class="px-6 py-2 text-start">Medida/Fórmula</th>
          <th scope="col" class="px-6 py-2 text-start">Posição</th>
          <th scope="col" class="px-6 py-2 text-start">Condição</th>
          <th scope="col" class="px-6 py-2 text-start">Observação</th>
          <th scope="col" class="px-28 py-2 text-start">Cor</th>
          <th scope="col" class="px-8 py-2 text-start whitespace-nowrap">Fase Montagem</th>
          <th scope="col" class="px-10 py-2 text-start">Aplicação</th>
        </tr>
      </thead>

      <!-- Linhas de Perfis -->
      <draggable :list="perfis" tag="tbody" handle=".handle" itemKey="id">
        <template v-slot:item="{ element, index }">
          <tr :key="index" class="escopo-tabela">
            <th scope="col" class="text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              <div class="handle cursor-pointer">
                <span>☰</span> <!-- Handle para arrastar -->
                {{ element.index }}
              </div>
            </th>
            <td scope="col" class="text-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="element.selected">
            </td>
            <td class="w-full text-center">
              <i class="fa-solid fa-magnifying-glass cursor-pointer"
                @click.prevent="toggleMenuProdutos(element.id)"></i>
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.perfil_id" :options="perfisPesquisados" label="codigo_base"
                class="bg-white w-32" :clearable="false" @input="(s) => buscarPerfis(s.target.value)"
                @keydown.enter="atualizarUnicoPerfil(element.perfil_id, element.index)">
                <template v-slot:option="option">
                  <div @click.prevent="atualizarUnicoPerfil(option, element.index)">
                    <span class="font-bold">
                      {{ option.codigo_base }}
                    </span> -
                    {{ option.nome_base }}
                  </div>
                </template>
              </v-select>
            </td>
            <td scope="col" class="text-center text-xs flex justify-center items-center">
              <div>
                <img v-if="element.perfil_id.imagem" :src="element.perfil_id.imagem.url_documento"
                  class="cursor-pointer w-10 h-10" @click="openPreviewImage(element.perfil_id.imagem.url_documento)">
                <img v-else
                  src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                  alt="Sem foto" class="w-10 h-10">
              </div>
            </td>

            <td scope="col" class="text-center text-xs">
              {{ element.perfil_id.nome_base }}
            </td>
            <td scope="col" class="text-center text-xs">
              {{ element.perfil_id.peso_base }}
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.corte" class="input-campos">
                <option v-for="corte in ['45/45', '45/90', '90/45', '90/90']" :key="corte" :value="corte">
                  {{ corte }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.qtd_formula">
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.medida_formula">
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.posicao" class="input-campos">
                <option v-for="posicao in ['H', 'L', 'V']" :key="posicao" :value="posicao">
                  {{ posicao }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.condicao">
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.observacao">
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.cor" :options="coresPerfil" label="nome" class="bg-white w-full custom-width"
                placeholder="Escolha uma opção" />
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.fase_esquadria" :options="fasesEsquadria" label="descricao"
                class="bg-white w-full custom-width" placeholder="Escolha uma opção" />
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.aplicacao" class="input-campos">
                <option v-for="aplicacao in ['A DEFINIR', 'OBRA', 'PRODUÇÃO']" :key="aplicacao" :value="aplicacao">
                  {{ aplicacao }}
                </option>
              </select>
            </td>
          </tr>
        </template>
      </draggable>

      <tr v-if="adicionarNovoPerfil" class="escopo-tabela">
        <td class="w-full"></td>
        <td class="w-full"></td>
        <td class="w-full text-center">
          <i class="fa-solid fa-magnifying-glass cursor-pointer" @click.prevent="toggleMenuProdutos()"></i>
        </td>
        <td class="w-full">
          <!-- <Search v-model="perfilSelecionado" :options="perfisPesquisados" :labelKey="getLabelKey" valueKey="id"
            :nameKey="getNameKey" @select="onSelect" @shouldSearch="buscarPerfis" /> -->
          <v-select v-model="perfilSelecionado">
          </v-select>
          <!-- <input type="text" :value="perfilSelecionado"> -->
        </td>
        <td scope="col" class="text-center text-xs flex justify-center items-center">
          <div>
            <img v-if="novoPerfil.imagem" :src="novoPerfil.imagem" class="cursor-pointer w-10 h-10"
              @click.prevent="openPreviewImage(novoPerfil.imagem)">
            <img v-else
              src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
              alt="Sem foto" class="w-10 h-10">
          </div>
        </td>
        <td class="w-full text-center">
          {{ novoPerfil.descricao }}
        </td>
        <td class="w-full text-center">
          {{ novoPerfil.peso }}
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.corte" class="input-campos">
            <option v-for="corte in ['45/45', '45/90', '90/45', '90/90']" :key="corte" :value="corte">
              {{ corte }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.qtd_formula">
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.medida_formula">
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.posicao" class="input-campos">
            <option v-for="posicao in ['H', 'L', 'V']" :key="posicao" :value="posicao">
              {{ posicao }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.condicao">
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.observacao">
        </td>
        <td class="w-full">
          <v-select v-model="novoPerfil.cor" :options="coresPerfil" label="nome" class="bg-white w-full custom-width"
            :reduce="cor => cor.id" placeholder="Escolha uma opção" />
        </td>
        <td class="w-full">
          <v-select v-model="novoPerfil.fase_esquadria" :options="fasesEsquadria" label="descricao"
            class="bg-white w-full custom-width" :reduce="fase => fase.id" placeholder="Escolha uma opção" />
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.aplicacao" class="input-campos">
            <option v-for="aplicacao in ['A DEFINIR', 'OBRA', 'PRODUÇÃO']" :key="aplicacao" :value="aplicacao">
              {{ aplicacao }}
            </option>
          </select>
        </td>
      </tr>

    </table>
    <div v-if="adicionarNovoPerfil" class="flex w-full justify-end itens-center mt-6 px-4 mb-5">
      <button class="btn-cancelar" @click.prevent="togglePerfil">
        Cancelar
      </button>
      <button class="btn-salvar" @click.prevent="postNovoPerfil">
        Adicionar
      </button>
    </div>
    <div v-else class="flex justify-center">
      <button class="text-ativo" @click.prevent="togglePerfil">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>

  <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
  <!-- Grupo de botões -->
  <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
    <button class="botoes bg-red-500 w-full sm:w-auto" @click.prevent="verificarExclusao()">
      Excluir Selecionados
    </button>
    <button class="botoes bg-blue-500 w-full sm:w-auto" @click.prevent="alterarSelecionados()">
      Alterar Selecionados
    </button>
    <button class="botoes bg-orange-500 w-full sm:w-auto" @click.prevent="exportarPerfis()">
      Exportar Perfis
    </button>
    <button class="botoes bg-yellow-500 w-full sm:w-auto" @click="triggerFileInput">
      Importar Perfis
    </button>
  </div>

  <!-- Input de arquivo oculto -->
  <input type="file" ref="fileInput" class="hidden" @change="importarPerfis" accept=".csv" />

  <!-- Botão Salvar -->
  <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end">
    <button class="btn-salvar w-full sm:w-auto" @click.prevent="atualizarPerfis(perfis)">
      Salvar
    </button>
  </div>
</div>



  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="fecharModalConfirmacao" @confirmarExclusao="excluirSelecionados" />

  <alterar-perfis v-if="modalAlterarSelecionados === true" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="atualizarLista()" :perfis="perfisSelecionados" :coresPerfil="coresPerfil"
    :fasesEsquadria="fasesEsquadria" />

  <modal-produtos v-if="modalProdutos === true" produto="Perfil" :itemListaSelecionado="itemListaSelecionado"
    @itemNovoPerfil="onSelect" @atualizarLista="atualizarLista()" @fecharModal="toggleMenuProdutos()" />

  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

  <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

  <erro-rapido v-if="erro === true" :mensagem="erroMessage" />

  <sucesso-rapido v-if="sucesso === true" :mensagem="sucessoMessage" />

</template>

<script>
import axios from 'axios'
import Search from '@/utils/Search.vue'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import AlterarPerfis from '@/components/midais/Editar/projeto/alterar_selecionados/AlterarPerfis.vue'
import ModalProdutos from '@/components/cadastros_projetos_componentes/alterar_projeto/ModalProdutos.vue'
import draggable from 'vuedraggable'
import Search2 from '@/utils/Search2.vue'
import vSelect from "vue-select";
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue'
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue'

export default {
  name: "PerfilProjeto",

  props: ['perfis', 'projeto_id'],

  emits: ['atualizarPerfis', 'getListaPerfisProjeto', 'atualizarUnicoPerfil'],

  data() {
    return {
      perfilSelecionado: [],
      perfisPesquisados: [],
      fasesEsquadria: [],
      coresPerfil: [],
      preview_imagem: '',

      novoPerfil: {
        projeto_id: this.projeto_id,
        perfil_id: null,
        descricao: '',
        peso: '',
        corte: '',
        qtd_formula: '',
        medida_formula: '',
        posicao: 'H',
        condicao: '',
        cor: '',
        observacao: '',
        fase_esquadria: '',
        aplicacao: 'A DEFINIR',
        index: this.perfis.length + 1
      },

      checkAll: false,
      modal_confirmacao: false,

      modalProdutos: false,
      itemListaSelecionado: '',

      adicionarNovoPerfil: false,

      modalAlterarSelecionados: false,
      perfisSelecionados: [],

      exibirErros: false,
      erros: {},
      erro: false,
      erroMessage: '',

      sucesso: false,
      sucessoMessage: '',
    }
  },

  components: {
    Search,
    MensagemConfirmacaoExclusao,
    AlterarPerfis,
    ModalProdutos,
    draggable,
    Search2,
    vSelect,
    PreviewImage,
    ListagemErros,
    ErroRapido,
    SucessoRapido,
  },

  created() {
    this.buscaFases()
    this.buscaCoresPerfil()
  },

  computed: {
    checkSome() {
      return this.perfis.some(perfil => perfil.selected)
    }
  },

  methods: {

    async buscaCoresPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`produtos/cor-perfil/?page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.coresPerfil = response.data.results
        // console.log(this.coresPerfil)
      } catch (error) {
        console.error(error)
      }
    },

    async buscaFases() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`projeto/fase-esquadria/?page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.fasesEsquadria = response.data.results;
      } catch (error) {
        console.error(error)
      }
    },

    async buscarPerfis(searchQuery) {
      if (searchQuery.length > 2) {
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest')

          const response = await axios.get(`produtos/produto/?tipo_produto=Perfil&filtro_base=${searchQuery}&page_size=9999`, {
            headers: {
              Authorization: `Token ${token}`
            }
          });
          this.perfisPesquisados = response.data.results;
        } catch (error) {
          console.error(error);
        }
      } else {
        this.perfisPesquisados = [];
      }
    },

    async postNovoPerfil() {
      try {
        console.log(this.novoPerfil);
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        // Fazendo a requisição diretamente
        const response = await axios.post('projeto/projeto-perfil/', this.novoPerfil, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        console.log("Perfil criado com sucesso:", response.data);

        this.adicionarNovoPerfil = false;

        this.perfisPesquisados = [];
        this.limparCampos();
        this.atualizarLista()
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          this.erros = error.response.data;
        } else {
          this.erros = { geral: ["Ocorreu um erro desconhecido."] };
        }
        this.toggleErros();
        console.log(this.exibirErros);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

    toggleMenuProdutos(itemId) {
      // alert('Menu ' + index)
      this.itemListaSelecionado = itemId
      this.modalProdutos = !this.modalProdutos
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.toggleAlterarSelecionados()
        this.perfisSelecionados = this.perfis.filter(perfil => perfil.selected)
        console.log(this.perfisSelecionados)
      } else {
        this.erro = true
        this.erroMessage = 'Selecione pelo menos um Perfil para ser alterado.'
        setTimeout(() => {
          this.erro = false;
          this.erroMessage = ''
        }, 3000);
      }
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados
    },


    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    atualizarUnicoPerfil(perfil, index) {
      const perfilProjetoAAtualizarId = this.perfis[index - 1].id

      this.perfisPesquisados = []
      this.$emit('atualizarUnicoPerfil', perfil.id, perfilProjetoAAtualizarId)

    },

    atualizarPerfis(perfisAAtualizar) {
      perfisAAtualizar.forEach((perfil, index) => {
        perfil.index = index + 1
        perfil.fase_esquadria = perfil.fase_esquadria ? perfil.fase_esquadria.id : ''
        perfil.cor = perfil.cor ? perfil.cor.id : ''
      })

      this.$emit('atualizarPerfis', perfisAAtualizar)
    },

    getLabelKey(option) {
      return option.codigo_base ? option.codigo_base : ''
    },

    getNameKey(option) {
      return option.nome_base ? option.nome_base : ''
    },

    onSelect(perfil) {
      console.log(perfil)
      this.perfilSelecionado = `${perfil.codigo_base}`
      console.log(this.perfilSelecionado)
      this.novoPerfil.perfil_id = perfil.id
      this.novoPerfil.descricao = perfil.nome_base
      this.novoPerfil.peso = perfil.peso_base
      this.novoPerfil.imagem = perfil.imagem?.url_documento
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
      this.perfis.forEach((perfil) => {
        perfil.selected = this.checkAll;
      });
    },

    fecharModalConfirmacao() {
      this.modal_confirmacao = false
    },

    excluirSelecionados() {
      if (this.checkAll) {
        this.deletarPerfis(this.perfis)
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }

      const perfisADeletar = this.perfis.filter((perfil) => {
        if (perfil.selected) {
          return perfil
        }
      })

      const perfisAAtualizar = this.perfis.filter(perfil =>
        !perfisADeletar.some(deletar => deletar.id === perfil.id)
      )

      this.deletarPerfis(perfisADeletar)
      this.modal_confirmacao = false
      this.atualizarPerfis(perfisAAtualizar)
    },

    verificarExclusao() {
      if (this.checkSome || this.checkAll) {
        this.modal_confirmacao = true
      } else {
        this.erro = true
        this.erroMessage = 'Selecione pelo menos um Perfil para ser excluído.'
        setTimeout(() => {
          this.erro = false;
          this.erroMessage = ''
        }, 3000);
      }
    },


    async deletarPerfis(perfisADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const perfisADeletarPromisses = []

      for (const perfil of perfisADeletar) {

        perfisADeletarPromisses.push(
          axios.delete(`projeto/projeto-perfil/${perfil.id}/`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(perfisADeletarPromisses)

      this.$emit('getListaPerfisProjeto')
    },

    togglePerfil() {
      this.adicionarNovoPerfil = !this.adicionarNovoPerfil
      this.limparCampos()
    },


    limparCampos() {
      this.perfilSelecionado = []
      this.novoPerfil.perfil_id = ''
      this.novoPerfil.descricao = ''
      this.novoPerfil.peso = ''
      this.novoPerfil.imagem = ''
      this.novoPerfil.corte = ''
      this.novoPerfil.qtd_formula = ''
      this.novoPerfil.medida_formula = ''
      this.novoPerfil.posicao = ''
      this.novoPerfil.condicao = ''
      this.novoPerfil.cor = ''
      this.novoPerfil.observacao = ''
      this.novoPerfil.fase_esquadria = ''
    },

    atualizarLista() {
      // alert('atualizar')
      this.$emit('getListaPerfisProjeto')
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    exportarPerfis() {
      const headers = [
        "Código",
        "Corte",
        "QTDE/Fórmula",
        "Medida/Fórmula",
        "Posição",
        "Condição",
        "Observação",
        "Cor",
        "Fase Montagem",
        "Aplicação",
      ];

      const rows = this.perfis.map((perfil) => [
        perfil.perfil_id?.codigo_base || "",
        perfil.corte || "",
        perfil.qtd_formula || "",
        perfil.medida_formula || "",
        perfil.posicao || "",
        perfil.condicao || "",
        perfil.observacao || "",
        perfil.cor?.nome || "",
        perfil.fase_esquadria?.descricao || "",
        perfil.aplicacao || "",
      ]);

      const csvContent = [headers, ...rows]
        .map((row) => row.join(","))
        .join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "perfis.csv";
      link.click();
    },

    // Método para importar os dados
    importarPerfis(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        const rows = content
          .split("\n")
          .filter((row) => row.trim() !== "")
          .map((row) => row.split(","));

        // Remova o cabeçalho (primeira linha)
        rows.shift();

        this.erros = { erros: [] };

        // Converte os dados em objetos e realiza as buscas
        for (const [index, row] of rows.entries()) {
          const perfil = {
            codigo: row[0]?.trim(),
            corte: row[1]?.trim(),
            qtd_formula: row[2]?.trim(),
            medida_formula: row[3]?.trim(),
            posicao: row[4]?.trim(),
            condicao: row[5]?.trim(),
            observacao: row[6]?.trim(),
            cor: row[7]?.trim(),
            fase_esquadria: row[8]?.trim(),
            aplicacao: row[9]?.trim(),
          };

          const camposFaltando = [];
          if (!perfil.codigo) camposFaltando.push("Código");
          if (!perfil.corte) camposFaltando.push("Corte");
          if (!perfil.qtd_formula) camposFaltando.push("QTDE/Fórmula");
          if (!perfil.medida_formula) camposFaltando.push("Medida/Fórmula");
          if (!perfil.posicao) camposFaltando.push("Posição");

          if (camposFaltando.length > 0) {
            this.erros.erros.push(
              `Linha ${index + 2}: Campos obrigatórios ausentes: ${camposFaltando.join(", ")}`
            );
            continue;
          }

          this.$store.dispatch("setLoading", true);
          try {
            // Buscar ID do Código
            const codigoResponse = await this.getIDPerfil(perfil.codigo);
            perfil.perfil_id = codigoResponse?.data?.results[0]?.id || null;

            // Buscar ID da Cor somente se ela for fornecida
            if (perfil.cor) {
              const corResponse = await this.getIDCor(perfil.cor);
              perfil.cor_id = corResponse?.data?.results[0]?.id || null;
            } else {
              perfil.cor_id = null; // Cor ausente no CSV
            }

            // Buscar ID da Fase de Montagem somente se ela for fornecida
            if (perfil.fase_esquadria) {
              const faseResponse = await this.getIDFaseMontagem(perfil.fase_esquadria);
              perfil.fase = faseResponse?.data?.results[0]?.id || null;
            } else {
              perfil.fase = null; // Fase ausente no CSV
            }

            console.log("Perfil Processado:", perfil);

            // Configurar `this.novoPerfil`
            this.novoPerfil = {
              projeto_id: this.projeto_id,
              perfil_id: perfil.perfil_id,
              descricao: perfil.descricao,
              peso: perfil.peso,
              corte: perfil.corte,
              qtd_formula: perfil.qtd_formula,
              medida_formula: perfil.medida_formula,
              posicao: perfil.posicao,
              cor: perfil.cor_id,
              condicao: perfil.condicao,
              observacao: perfil.observacao,
              fase_esquadria: perfil.fase,
              aplicacao: perfil.aplicacao,
            };

            // Executar `postNovoPerfil`
            await this.postNovoPerfil();
            this.$store.dispatch("setLoading", false);
          } catch (error) {
            console.error(`Erro ao processar o perfil na linha ${index + 2}:`, error);
            this.erros.erros.push(`Linha ${index + 2}: Erro ao buscar dados para o perfil.`);
          }
        }

        if (this.erros.erros.length > 0) {
          console.error("Erro(s) encontrado(s):", this.erros);
          this.exibirErros = true;
        } else {
          this.sucesso = true;
          this.sucessoMessage = `Perfis importados e cadastrados com sucesso.`;

          setTimeout(() => {
            this.sucesso = false;
            this.sucessoMessage = false;
          }, 3000);
        }
      };

      this.$refs.fileInput.value = "";
      reader.readAsText(file);
    },


    async getIDPerfil(codigo) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/produto/?codigo_base=${codigo}`, {
        headers: { Authorization: `Token ${token}` },
      });
    },

    async getIDCor(cor) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/cor-perfil/?cor=${cor}`, {
        headers: { Authorization: `Token ${token}` },
      });
    },

    async getIDFaseMontagem(fase) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`projeto/fase-esquadria/?fase=${fase}`, {
        headers: { Authorization: `Token ${token}` },
      });
    },



  }
}
</script>

<style scoped>
.botoes {
  @apply px-4 py-1 text-white
}
</style>