<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase font-bold">Criar Nova Tabela de Componente</h1>
            <router-link to="/componentes" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Formulário de dados da tabela -->
        <div>
            <label class="font-bold">Nome da Tabela</label>
            <input v-model="novaTabela.nome" type="text" class="input-form" placeholder="Digite aqui..."/>

            <label class="font-bold">Margem Lucro ML (%)</label>
            <input v-model="novaTabela.margem_lucro_ml" type="number" min="0" class="input-form" />

            <label class="font-bold">Margem Lucro Balcão (%)</label>
            <input v-model="novaTabela.margem_lucro_balcao" type="number" min="0" class="input-form" />

            <label class="font-bold">Desconto (%)</label>
            <input v-model="novaTabela.desconto" type="number" min="0" class="input-form" />

            <label>Fornecedor</label>
            <v-select v-model="novaTabela.fornecedor" :options="listaFornecedores" label="nome" class="w-full z-10" :reduce="fornecedor => fornecedor.id" />
        </div>

        <!-- Botões de Cancelar e Salvar -->
        <div class="flex justify-end mt-4">
            <button class="btn-cancelar" @click.prevent="$router.push('/componentes')">Cancelar</button>
            <button class="btn-salvar" @click.prevent="criarTabela">Criar Tabela</button>
        </div>
    </div>
    <SucessoRapido v-if="sucesso === true" mensagem="Tabela criada com sucesso!" />
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
    name: 'CriarTabelaComponente',
    components: {
        vSelect,
        SucessoRapido
    },
    data() {
        return {
            sucesso: false,
            novaTabela: {
                nome: "",
                margem_lucro_ml: 0,
                margem_lucro_balcao: 0,
                desconto: 0,
                fornecedor: null,
            },

            listaFornecedores: []
        };
    },

    created(){
        this.getFornecedores()
    },

    methods: {

        async getFornecedores(){
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('pessoas/fornecedor/?tipo_fornecedor_nome=Componentes&ativo=True&page_size=9999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                // console.log(response.data.results)
                this.listaFornecedores = response.data.results.map(fornecedor => ({
                id: fornecedor.id,
                nome: fornecedor.pessoa.nome_razao
            }));
            } catch(error){
                console.error(error)
            }
        },

        async criarTabela() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.post("precificacao/tabelas-componente/",
                    { ...this.novaTabela, },
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$router.push('/componentes');
                }, 3000);
            } catch (error) {
                console.error("Erro ao criar tabela de Componente:", error);
            }
        },

    },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
}

.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    margin-bottom: 10px;
    width: 100%;
}
</style>