<template>
    <div class="flex justify-center items-center">
        <div>
            <div class="text-center">

                <h2 class="text-2xl font-bold mb-4">Imagens Variáveis</h2>

                <div class="flex justify-center gap-2">

                    <img :src="imagem" alt="Imagem de Capa" class="object-cover w-64 border rounded-lg cursor-pointer"
                        @click.prevent="openPreviewImage(`${imagem}`)" />

                    <img :src="imagem" alt="Imagem de Capa" class="object-cover w-64 border rounded-lg cursor-pointer"
                        @click.prevent="openPreviewImage(`${imagem}`)" />

                </div>

                <div class="flex gap-3 mt-4">
                    <button class="selecionar-arquivo" @click.prevent="toggleGaleria()">
                        Pesquisar Imagem
                    </button>
                </div>

            </div>

            <div class="flex itens-center mt-6 justify-center">
                <button class="btn-salvar" @click.prevent="atualizarImagem()">Salvar</button>
            </div>

        </div>
    </div>

    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
    <Galeria v-if="mostrarGaleria" @fechar_galeria="toggleGaleria" @objetoClicado="atualizarFileInput" />
</template>

<script>
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';
import Galeria from '@/components/midais/ged/Galeria.vue';

export default {
    name: "ImagensProjeto",

    data() {
        return {
            preview_imagem: false,
            mostrarGaleria: false,
            imagem: 'https://www.astra-sa.com/pics//varejo/portas-e-janelas/janelas-de-correr/janela-de-correr-2-folhas-JC.jpg'
        }
    },

    props: ['projeto_id'],

    emits: ['atualizarImagens'],

    components: {
        PreviewImage,
        Galeria,
    },

    methods: {
        closed_preview_image() {
            this.preview_imagem = ''
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        atualizarFileInput(objeto) {
            console.log('objeto que chegou:' + objeto)
            console.log(objeto)
            this.imagem = objeto.url_documento;
        },

        toggleGaleria() {
            this.mostrarGaleria = !this.mostrarGaleria
        },

        async atualizarImagem() {
            alert('Salvar imagem')
        },
    }
};
</script>

<style scoped></style>