<template>
  <div class="w-full">
    <!-- MODAL PARA ADICIONAR NOVA FORMULA AO PROJETO -->
    <div class="flex flex-col gap-4 mt-4 mb-6">
      <h2 class="text-xl font-bold text-corBase">Fórmulas do Projeto</h2>
    </div>

    <!-- ESCOPO DA LISTA DE FORMULAS DO PROJETO -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="px-3 py-3">
            #
          </th>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th class="px-6 py-3 flex justify-center">
            Opções
          </th>
          <th class="px-6 py-3">
            Codigo
          </th>
          <th class="px-6 py-3">
            Descrição
          </th>
          <th scope="col" class="px-6 py-3">
            Fórmula
          </th>
          <th scope="col" class="px-6 py-3">
            Condição
          </th>
        </tr>
        </thead>
        <draggable 
          :list="lista_formulas"
          tag="tbody" 
          handle=".handle"
          itemKey="id"
        >
          <template v-slot:item="{ element, index }">
            <tr :key="index" class="escopo-tabela">
              <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                <div class="handle cursor-pointer">
                  <span>☰</span> <!-- Handle para arrastar -->
                  {{ element.index }}
                </div>
              </th>
              <td class="w-4 p-4">
                <div class="selec">
                  <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                          v-model="element.selected">
                  <label class="sr-only">checkbox</label>
                </div>
              </td>
              <td class="flex py-2 gap-6 justify-center">
                <button @click.prevent="element.edit = true">
                  <i class="fa-solid fa-pen-to-square"></i>
                </button>
              </td>
              <td class="px-6 py-2 text-xs" v-if="!element.edit">
                {{ element.codigo }}
              </td>
              <td class="py-2 text-xs" v-else>
                <input 
                  type="text" 
                  class="w-full p-1 text-corBase border border-corBase/40" 
                  v-model="element.codigo"
                  @input="forcarPrefixo(element)"
                  :placeholder="valorPadrao"
                >
              </td>
              <td class="px-6 py-2 text-xs">
                <input 
                  type="text" class="w-full p-1 text-corBase border border-corBase/40"
                  v-model="element.descricao"
                >
              </td>
              <td class="px-6 py-2 text-xs">
                <input
                  type="text" 
                  class="w-full p-1 text-corBase border border-corBase/40" 
                  v-model="element.formula"
                >
              </td>
              <td class="px-6 py-2 text-xs">
                <input
                  type="text" 
                  class="w-full p-1 text-corBase border border-corBase/40" 
                  v-model="element.condicao"
                >
              </td>

            </tr>
          </template>
        </draggable>
      </table>

      <table class="container-tabela overflow-auto" v-if="novasFormulas.length">
        <thead class="cabecalho-tabela">
        <tr>
          <th class="px-6 py-3">
            Codigo
          </th>
          <th class="px-6 py-3">
            Descrição
          </th>
          <th scope="col" class="px-6 py-3">
            Formula
          </th>
          <th scope="col" class="px-6 py-3">
            Condição
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(formula, index) in novasFormulas" :key="index" class="escopo-tabela">
          <td class="px-6 py-2 text-xs">
            <input 
                type="text" 
                class="w-full p-1 text-corBase border border-corBase/40" 
                v-model="formula.codigo"
                @input="forcarPrefixo(formula)"
                :placeholder="valorPadrao"
              >
          </td>
          <td class="px-6 py-2 text-xs">
            <input 
              type="text" 
              class="w-full p-1 text-corBase border border-corBase/40"
              v-model="formula.descricao"
              @input="formula.descricao = formula.descricao.toUpperCase()"
            >
          </td>
          <td class="px-6 py-2 text-xs">
            <input 
              type="text" 
              class="w-full p-1 text-corBase border border-corBase/40" 
              v-model="formula.formula"
            >
          </td>
          <td class="px-6 py-2 text-xs">
            <input 
              type="text" 
              class="w-full p-1 text-corBase border border-corBase/40" 
              v-model="formula.condicao"
            >
          </td>

        </tr>
        </tbody>
      </table>

      <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="novasFormulas.length">
        <button 
          class="btn-cancelar" 
          @click.prevent="() => novasFormulas = []"
        >
          Cancelar
        </button>
        <button 
          class="btn-salvar" 
          @click.prevent="postNovaFormula"
          :disabled="!botaoAdicionarHabilitado"
        >
            Adicionar
        </button>
      </div>

      <div class="flex justify-center" v-if="!novasFormulas.length">
        <button class="text-ativo" @click.prevent="adicionarFormula">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
    <div class="flex w-full justify-end itens-center mt-6">

      <button
        class="btn-cancelar" 
        @click.prevent="modal_confirmacao = true"
        v-if="checkSome || checkAll"
      >
        Excluir Selecionados
      </button>
      <!-- <button class="btn-cancelar" @click.prevent="() => $emit('getListaFormulasProjeto')">Cancelar</button> -->
      <button class="btn-salvar" @click.prevent="atualizarFormulas(lista_formulas)">Salvar</button>
    </div>
  </div>

  <MensagemConfirmacaoExclusao
    mensagem="Deseja mesmo excluir os itens selecionados?"
    :abrir="modal_confirmacao"
    @feharModal="feharModalConfirmacao"
    @confirmarExclusao="excluirSelecionados"
  />

</template>

<script>
import axios from 'axios'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import draggable from 'vuedraggable'

export default {
  name: "FormulaProjeto",

  props: ['lista_formulas', 'projeto_id'],

  emits: ['atualizarFormulas', 'getListaFormulasProjeto'],

  data() {
    return {
      novasFormulas: [],
      valorPadrao: 'FP',

      checkAll: false,
      modal_confirmacao: false,
    }
  },

  components: {
    MensagemConfirmacaoExclusao,
    draggable
  },

  computed: {
    checkSome() {
      return this.lista_formulas.some(formula => formula.selected)
    },

    botaoAdicionarHabilitado() {
      return this.novasFormulas.every(formula =>
        formula.codigo !== this.valorPadrao &&
        formula.descricao.trim() !== ''
      );
    },
  },

  methods: {
    atualizarFormulas(formulasAAtualizar) {
      formulasAAtualizar.forEach((formula, index) => {
        formula.index = index + 1
      })

      this.$emit('atualizarFormulas', formulasAAtualizar)
    },

    feharModalConfirmacao() {
      this.modal_confirmacao = false
    },

    forcarPrefixo(formula) {
      const prefixo = this.valorPadrao;
      if (!formula.codigo.startsWith(prefixo)) {
        formula.codigo = prefixo + formula.codigo.slice(prefixo.length);
      }
      // Converte o restante para maiúsculas
      formula.codigo = formula.codigo.toUpperCase();
    },

    selectAll() {
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    excluirSelecionados() {
      if(this.checkAll) {
        this.deletarFormula(this.lista_formulas)
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }
      const formulasADeletar = this.lista_formulas.filter((formula) => {
        if(formula.selected){
          return formula
        }
      })

      const formulasAAtualizar = this.lista_formulas.filter(formula => 
        !formulasADeletar.some(deletar => deletar.id === formula.id)
      )


      this.deletarFormula(formulasADeletar)
      this.modal_confirmacao = false
      this.atualizarFormulas(formulasAAtualizar)
    },

    async deletarFormula(formulasADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const formulasADeletarPromisses = []

      for(const formula of formulasADeletar){

        formulasADeletarPromisses.push(
          axios.delete(`projeto/formula-projeto/${formula.id}/`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(formulasADeletarPromisses)

      this.$emit('getListaFormulasProjeto')
    },

    adicionarFormula() {
      this.novasFormulas.push({
        projeto: this.projeto_id,
        codigo: this.valorPadrao,
        descricao: '',
        formula: '',
        index: this.lista_formulas.length + 1
      })
    },

    async postNovaFormula() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const novasFormulasPromisses = []

      for(const novaFormula of this.novasFormulas){

        novasFormulasPromisses.push(
          axios.post('projeto/formula-projeto/', novaFormula, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(novasFormulasPromisses)

      this.novasFormulas = []
      this.$emit('getListaFormulasProjeto')

    }
  }

}
</script>

<style lang="scss">
.btn-salvar {
    position: relative;
    transition: opacity 0.3s ease, cursor 0.3s ease;

    &:disabled {
      opacity: 0.6;  // Deixa o botão opaco
      cursor: not-allowed;  // Muda o cursor para bloqueado

      // Estilização para a mensagem flutuante
      &::after {
        content: 'Preencha todos os campos obrigatórios'; // Mensagem flutuante
        position: absolute;
        top: -35px;  // Posição acima do botão
        left: -10%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.75);  // Fundo da mensagem
        color: white;  // Cor da mensagem
        padding: 5px 10px;  // Espaçamento interno
        border-radius: 4px;  // Bordas arredondadas
        font-size: 12px;  // Tamanho da fonte
        white-space: nowrap;
        opacity: 0;  // Inicialmente invisível
        transition: opacity 0.3s ease;
        pointer-events: none;  // Previne que a mensagem receba cliques
      }

      &:hover::after {
        opacity: 1;  // Torna a mensagem visível ao passar o mouse
      }
    }
  }

</style>