<template>

  <!-- NAVBAR MENOS DE 640 PIXELS -->
  <nav class="block sm:hidden container-navbar-sm">
    <div class="primeira-nav">
      <button @click="$router.push(`/dashboard`)">
        <img src="../assets/imagens/logo.png" class="logo-sm">
      </button>

      <div class="menu-hamburger">
        <button
            @click.prevent="abrirMenu"
            class="icone-menu-hamburguer"
        >
          <span class="fas fa-bars-staggered" v-if="!expandido"></span>
          <span class="fas fa-times" v-if="expandido"></span>
        </button>

        <div v-if="expandido">
          <div class="container-menu">
            <div class="container-opcao-menu" v-for="(opcao, index) in opcoes" :key="index">
              <button class="opcao" @click.prevent="abrirDropdown(index)">
                <div class="opcao-esquerda">
                  <span :class="opcao.icone"></span>
                  <h2>{{ opcao.titulo }}</h2>
                </div>
                <i class="fas fa-chevron-down" v-if="!opcao.dropdown"></i>
                <i class="fas fa-chevron-up" v-if="opcao.dropdown"></i>
              </button>

              <div v-show="opcao.dropdown">
                <div class="container-drop-menu">
                  <button class="sub-opcao" v-for="(subOpcao, subIndex) in opcao.subOpcoes"
                          @click.prevent="abrirPagina(index, subIndex)" :key="subIndex">
                    {{ subOpcao }}
                  </button>
                </div>
              </div>

            </div>
            <button class="btn-drop-menu" @click.prevent="logout">Sair</button>
          </div>
        </div>
      </div>
    </div>

    <div class="segunda-nav">
      <h3 class="navegacao-navbar">Dashboard ></h3>

      <div class="segunda-nav-esquerda">
        <button class="fas fa-magnifying-glass"></button>

        <button class="fas fa-bell"></button>

        <button @click.prevent="abrirDropdownPerfil" class="btn-meu-perfil"><img src="../assets/imagens/logo.png"></button>
        <div v-if="this.dropdownPerfil">
            <div class="container-dropdown-perfil2">
              <a href="#" class="opcao-drop-perfil2">Meu Perfil</a>
              <a href="#" class="opcao-drop-perfil2">Meu Perfil</a>
              <a href="#" class="opcao-drop-perfil2">Meu Perfil</a>
              <button @click.prevent="logout" class="btn-drop-perfil2">Sair</button>
            </div>
          </div>
      </div>
    </div>
  </nav>

  <!-- NAVBAR DE 641 A 768 PIXELS-->
  <nav class="sm:block md:hidden hidden">
    <div class="primeira-nav">
      <button @click="$router.push(`/dashboard`)">
        <img src="../assets/imagens/logo.png" class="logo-sm">
      </button>

      <div class="menu-hamburger">
        <button
            @click.prevent="abrirMenu"
            class="icone-menu-hamburguer"
        >
          <span class="fas fa-bars-staggered" v-if="!expandido"></span>
          <span class="fas fa-times" v-if="expandido"></span>
        </button>

        <div v-if="expandido">
          <div class="container-menu2">
            <div class="container-opcao-menu2" v-for="(opcao, index) in opcoes" :key="index">
              <button class="opcao2" @click.prevent="abrirDropdown(index)">
                <div class="opcao-esquerda2">
                  <span :class="opcao.icone"></span>
                  <h2>{{ opcao.titulo }}</h2>
                </div>
                <i class="fas fa-chevron-down" v-if="!opcao.dropdown"></i>
                <i class="fas fa-chevron-up" v-if="opcao.dropdown"></i>
              </button>

              <div v-show="opcao.dropdown">
                <div class="container-drop-menu2">
                  <button class="sub-opcao2" v-for="(subOpcao, subIndex) in opcao.subOpcoes" :key="subIndex"
                          @click.prevent="abrirPagina(index, subIndex)">
                    {{ subOpcao }}
                  </button>
                </div>
              </div>

            </div>
            <button class="btn-drop-menu640" @click.prevent="logout">Sair</button>
          </div>
        </div>
      </div>
    </div>

    <div class="segunda-nav2">
      <h3 class="navegacao-navbar2">Dashboard ></h3>

      <div class="segunda-nav-esquerda2">
        <input class="barra-navegação1 mr-3"
               type="text"
               placeholder="Pesquisar..."
               v-model="pesquisar"
        ><span class="fas fa-search lupa"></span>

        <button class="fas fa-bell mr-3"></button>

        <button @click.prevent="abrirDropdownPerfil" class="btn-meu-perfil"><img src="../assets/imagens/logo.png"></button>
        <div v-if="this.dropdownPerfil">
            <div class="container-dropdown-perfil2">
              <a href="#" class="opcao-drop-perfil2">Meu Perfil</a>
              <a href="#" class="opcao-drop-perfil2">Meu Perfil</a>
              <a href="#" class="opcao-drop-perfil2">Meu Perfil</a>
              <button @click.prevent="logout" class="btn-drop-perfil2">Sair</button>
            </div>
          </div>
      </div>
    </div>
  </nav>

  <!-- NAVBAR A CIMA DE 768 PIXELS -->
  <nav class="sm:hidden md:block hidden">
    <div class="sombra-nav-bar"></div>
    <div class="nav-flutuante">
      <div class="primeira-nav3">
        <div class="esquerda-nav">
          <button @click="$router.push(`/dashboard`)">
            <img src="../assets/imagens/logo.png" class="w-1- h-10 mr-1">
          </button>
          <button @click="$router.push(`/dashboard`)">
            <img src="../assets/imagens/tipografia.png" class="w-40">
          </button>
          <span class="divisao"></span>
          <h3 class="navegacao-navbar3">Dashboard ></h3>
        </div>

        <div class="segunda-nav-esquerda3">
          <input class="barra-navegação2 mr-4"
                 type="text"
                 placeholder="Pesquisar..."
                 v-model="pesquisar"
          ><span class="fas fa-search lupa2"></span>

          <button class="fas fa-bell mr-4"></button>

          <button class="btn-meu-perfil" @click.prevent="abrirDropdownPerfil">
            <img src="../assets/imagens/logo.png">
          </button>

          <div v-if="this.dropdownPerfil">
            <div class="container-dropdown-perfil">
              <a href="#" class="opcao-drop-perfil">Meu Perfil</a>
              <a href="#" class="opcao-drop-perfil">Meu Perfil</a>
              <a href="#" class="opcao-drop-perfil">Meu Perfil</a>
              <button @click.prevent="logout" class="btn-drop-perfil">Sair</button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-if="pesquisar !== ''" class="fixed w-[25%] z-[1000] right-0 mt-20">
      <div class="w-full bg-screenCor">
        <div v-for="(item, index) in this.rotas.slice(0,7)" :key=index class=" py-3 px-2 border-b z-[20000] border-corBase/20">
          <router-link :to="item[1]" @click.prevent="limpaPesquisa">{{ item[0] }}</router-link>
        </div>
      </div>

    </div>

  </nav>

</template>

<script>

import axios from 'axios';
import routes from '@/router/index.js'
import { keyBy } from 'lodash';

export default {
  name: 'Navbar',
  data() {
    return {
      API_URL: process.env.VUE_APP_API_URL,
      exibirOpcoes: false,
      expandido: false,
      dropdownPerfil: false,
      //v-model do campo de pesquisa
      pesquisar: '',
      // opções de dropdown
      opcoes: [
        {
          titulo: "Relatorios",
          icone: "fas fa-chart-line mr-2",
          dropdown: false,
          subOpcoes: ["Relatório Geral", "Relatório Vendas", "Relatório Orçamento"]
        },
        {
          titulo: "Cadastros",
          icone: "fas fa-user-edit mr-2",
          dropdown: false,
          subOpcoes: ["Pessoas", "Fornecedores", "Clientes"]
        },
        {
          titulo: "Obras",
          icone: "fas fa-person-digging mr-2",
          dropdown: false,
          subOpcoes: ["Lista de Obras"]
        },
        {
          titulo: "Orçamentos",
          icone: "fas fa-file-invoice-dollar mr-2",
          dropdown: false,
          subOpcoes: ["Relatório Geral", "Relatório Vendas", "Relatório Orçamento"]
        },
        {
          titulo: "Projetos",
          icone: "fas fa-project-diagram mr-2",
          dropdown: false,
          subOpcoes: ["Relatório Geral", "Relatório Vendas", "Relatório Orçamento"]
        },
        {
          titulo: "GED",
          icone: "fas fa-camera mr-2",
          dropdown: false,
          subOpcoes: ["Imagens", "Categorias", "Subcategorias"]
        },
        {
          titulo: "Vendas",
          icone: "fas fa-shopping-cart mr-2",
          dropdown: false,
          subOpcoes: ["Relatório Geral", "Relatório Vendas", "Relatório Orçamento"]
        },
        {
          titulo: "Estoque",
          icone: "fas fa-boxes mr-2",
          dropdown: false,
          subOpcoes: ["Relatório Geral", "Relatório Vendas", "Relatório Orçamento"]
        },
        {
          titulo: "Configurações",
          icone: "fas fa-gear mr-2",
          dropdown: false,
          subOpcoes: ["Empresa", "Usuários", "Precificação"]
        },
        {
          titulo: "Ferramentas",
          icone: "fas fa-wrench mr-2",
          dropdown: false,
          subOpcoes: ["Relatório Geral", "Relatório Vendas", "Relatório Orçamento"]
        },
      ],

      rotas: [],
    }
  },

  created() {
    // fecha o menu quando a rota é mudada
    this.$watch(
        () => this.$route.path,
        (novaRota, rotaAntiga) => {
          if (novaRota !== rotaAntiga) {
            this.fecharMenu()
          }
        }
    )

  },
  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  watch: {
    // Executa uma quando houver uma alteração no campo de pesquisa
    pesquisar() {
      this.rotas = routes.options.routes.map(route => route.meta?.description?.includes(this.pesquisar.toUpperCase())
          ? [route.meta?.description, route.path] : null).filter(r => r !== null);


    },
  },

  methods: {
    limpaPesquisa() {
      this.pesquisar = ''
    },
    // abre o dropdown no botao do perfil
    abrirDropdownPerfil() {
      this.dropdownPerfil = !this.dropdownPerfil
    },

    // fecha o menu mobile
    fecharMenu() {
      this.expandido = false
    },

    // abre as opções do menu mobile
    abrirDropdown(index) {
      this.opcoes[index].dropdown = !this.opcoes[index].dropdown
    },

    // abre o menu mobile
    abrirMenu() {
      this.expandido = !this.expandido
    },

    //
    abrirOpcoes() {
      this.exibirOpcoes = !this.exibirOpcoes;
    },

    // faz o logout do usuario do sistema
    async logout() {
      // pega o token de autenticação
      const token = await this.$store.dispatch('getAuthTokenCookie')
      // pega o subdominio da pagina que estiver
      // monta a url pra requição de acordo com o subdomino que estiver
      await this.$store.dispatch('fetchURLrequest')

      // requisição no banco para deslogar o usuario
      await axios
          .post(`/token/logout`, {}, {
            headers: {
              'Authorization': `Token ${token}`
            }
          })

          .then(response => {
            console.log(response)
          })
          .catch(error => {
            console.log(error)
          })

      // remove o token do localStore
      axios.defaults.headers.common['Authorization'] = ''
      localStorage.removeItem('token')
      this.$store.commit('removeToken')
      this.$store.dispatch('removeAuthTokenCookie')

      localStorage.removeItem('minhaEmpresa')
      this.$store.commit('removeMinhaEmpresa')
      localStorage.removeItem('isAdminUser')

      this.$router.push('/login')
    },

    // metodo que redireciona para a pagina de acordo com a subopcao clicada
    abrirPagina(index, subIndex) {
      const subOpcao = this.opcoes[index].subOpcoes[subIndex]
      let rota = ''

      // Aqui, você pode adicionar um switch/case ou if/else para mapear a subopção para uma rota
      switch (subOpcao) {
        case 'Relatório Geral':
          rota = `/relatorios/geral`
          break;
        case 'Relatório Vendas':
          rota = `/relatorios/vendas`
          break;
        case 'Relatório Orçamento':
          rota = `/relatorios/orcamentos`
          break;
        case 'Pessoas':
          rota = `/pessoa`
          break;
        case 'Fornecedores':
          rota = `/cadastros/fornecedores`
          break;
        case 'Clientes':
          rota = `/cadastros/clientes`
          break;
        case 'Precificação':
          rota = `/precificacao`
          break;
        case 'Lista de Obras':
          rota = `/obras`
          break;
        case 'Imagens':
          rota = `/ged/lista-imagens`
          break;
        case 'Categorias':
          rota = `/ged/categoria`
          break;
        case 'Subcategorias':
          rota = `/ged/subcategoria`
          break;
          // TODO: IMPLEMENTAR O RESTANTE DOS LINKS FUTURAMENTE
        default:
          rota = '/'
          break;
      }

      this.$router.push(rota)
    }


  },


}
</script>

<style lang="scss">

.container-navbar-sm {
  @apply bg-corBase
}

.primeira-nav {
  @apply flex flex-row justify-between  border-b-[1px] border-corSombraNavBar/50 items-center
  py-2 px-4
}

.logo-sm {
  @apply w-14
}

.icone-menu-hamburguer {
  @apply text-3xl text-corSombraNavBar
}

.segunda-nav {
  @apply flex flex-row justify-between p-2 items-center text-corSombraNavBar border-b-[1px]
  border-corSombraNavBar/50
}

.segunda-nav-esquerda {
  @apply flex items-center justify-around w-24 py-3
}

.btn-meu-perfil {
  @apply w-8 rounded-full
}

.container-menu {
  @apply absolute top-0 left-0 w-48 h-full bg-corBase divide-y divide-screenCor/30 z-[999]
}

.container-opcao-menu {
  @apply py-3 px-6 text-xl
}

.opcao {
  @apply flex text-white items-center justify-between w-full text-base

}

.opcao-esquerda {
  @apply flex items-center
}

.container-drop-menu {
  @apply w-full flex flex-col h-full text-base bg-black/20 rounded-lg mt-2 divide-y divide-screenCor/40
}

.sub-opcao {
  @apply text-white text-xs py-2 px-4 text-left
}

.btn-drop-menu {
  @apply w-40 text-center bg-screenCor rounded-lg text-corBase p-2 ml-4
}

.btn-drop-menu640 {
  @apply w-52 text-center bg-screenCor rounded-lg text-corBase p-2 mt-60 ml-8
}

/* Estilos da navbar de 640 px a 768 px */
.segunda-nav2 {
  @apply flex flex-row justify-between p-2 items-center text-corSombraNavBar border-b-[1px]
  border-corSombraNavBar/50
}

.segunda-nav-esquerda2 {
  @apply flex items-center justify-around py-3
}

.barra-navegação1 {
  @apply p-2 rounded-full px-4 bg-screenCor
}

.lupa {
  @apply absolute right-[89px] mx-auto text-corBase
}

.container-menu2 {
  @apply absolute top-0 left-0 w-72 h-full bg-corBarraLateral pt-4 divide-y divide-screenCor/30 z-[23000]
}

.container-opcao-menu2 {
  @apply py-3 px-6 text-xl
}

.opcao2 {
  @apply flex text-white items-center justify-between w-full text-base

}

.opcao-esquerda2 {
  @apply flex items-center
}

.container-drop-menu2 {
  @apply w-full flex flex-col block h-full text-base bg-black/20 rounded-lg mt-2
}

.sub-opcao2 {
  @apply text-white text-xs py-2 px-4 text-left
}


/* estilo nav bar flutuante acima de 768 px */
.sombra-nav-bar {
  @apply w-[94%] xl:w-[96%] fixed top-2.5 left-8 bg-corSombraNavBar rounded-lg h-10 z-0
}

.nav-flutuante {
  @apply w-[98%] fixed top-4 left-4  bg-corBase rounded-lg z-0

}

.segunda-nav-esquerda3 {
  @apply flex flex-row justify-between items-center text-corSombraNavBar
}

.primeira-nav3 {
  @apply flex flex-row justify-between items-center
  py-2 px-4
}

.barra-navegação2 {
  @apply p-1 rounded-full px-4 bg-screenCor
}

.lupa2 {
  @apply absolute right-[100px] mx-auto text-corBase
}

.esquerda-nav {
  @apply flex items-center
}

.divisao {
  @apply border-r h-[40px] w-[1px] border-screenCor/30 ml-2
}

.navegacao-navbar3 {
  @apply text-screenCor px-4
}


/* estilo dropdown perfil */
.container-dropdown-perfil {
  @apply fixed right-0 top-16 flex flex-col bg-corBase z-50
}

.opcao-drop-perfil {
  transition: .3s;
  @apply w-24 bg-black/20 hover:bg-corBase/30 text-center
}

.btn-drop-perfil {
  transition: .3s;
  @apply w-full bg-black/20 hover:bg-corBase/30
}

/* estilo dropdown celular */
.container-dropdown-perfil2 {
  @apply fixed right-0 top-36 flex flex-col bg-corBase z-50
}

.opcao-drop-perfil2 {
  transition: .3s;
  @apply w-24 bg-black/20 hover:bg-corBase/30 text-center
}

.btn-drop-perfil2 {
  transition: .3s;
  @apply w-full bg-black/20 hover:bg-corBase/30
}
</style>