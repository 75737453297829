<template>
  <div v-if="modal_exibir" class="fundo-modal">
    <div class="modal">
      <div class="w-full">
        <h1 class="text-2xl font-bold text-corBase mb-5">Itens à editar para exclusão da variável {{ modal_variavel.variavel }}</h1>
        <div>
          <h2 class="text-xl font-bold text-corBase">Perfis</h2>
          <ListaPerfisParaEdicao
            :perfis="modal_dependencias ? modal_dependencias.perfisProjeto : ''"
          />
        </div>

        <div>
          <h2 class="text-xl font-bold text-corBase">Componentes</h2>
          <ListaComponentesParaEdicao
            :componentes="modal_dependencias ? modal_dependencias.componentesProjeto : ''"
          />
        </div>
        <div class="flex w-full justify-end mt-5">
          <button class="btn-salvar" @click.prevent="atualizarItens">Salvar</button>
        </div>
      </div>
    </div>
  </div>

  <SucessoRapido v-if="sucesso" mensagem="Perfis e componentes atualizados com sucesso"/>
</template>

<script>
import axios from 'axios'
import ListaPerfisParaEdicao from './ListaPerfisParaEdicao.vue'
import ListaComponentesParaEdicao from './ListaComponentesParaEdicao.vue';
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  data() {
    return {
      sucesso: false
    };
  },

  components: {
    ListaPerfisParaEdicao,
    ListaComponentesParaEdicao,
    SucessoRapido
  },

  props: ['modal_exibir', 'modal_variavel', 'modal_dependencias', 'modalConfirmacaoResolve'],

  emits: ['resolvePromise'],

  methods: {
    confirmarExclusao() {
      this.$emit('resolvePromise')
    },

    async atualizarItens(){
      try{
        await this.atualizarPerfis(this.modal_dependencias.perfisProjeto)
        await this.atualizarComponentes(this.modal_dependencias.componentesProjeto)

        this.$emit('resolvePromise')
      }catch(e){
        console.error(e)
      }      
    },

    async atualizarPerfis(perfis){

      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = perfis.map((perfil) => {
        perfil.perfil_id ? perfil.perfil_id = perfil.perfil_id.id : ''
        perfil.projeto_id ? perfil.projeto_id = perfil.projeto_id.id : ''
        perfil.fase_esquadria ? perfil.fase_esquadria = perfil.fase_esquadria.id : perfil.fase_esquadria = null
        perfil.cor ? perfil.cor = perfil.cor.id : perfil.cor = null

        return perfil
      })

      await axios.put(`projeto/projeto-perfil-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            
          })
          .catch(error => {
            console.log(error)
          })
    },

    async atualizarComponentes(componentes){

      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = componentes.map(componente => {
        componente.componente_id ? componente.componente_id = componente.componente_id.id : ''
        componente.projeto_id ? componente.projeto_id = componente.projeto_id.id : ''
        componente.fase_esquadria ? componente.fase_esquadria = componente.fase_esquadria.id : componente.fase_esquadria = null

        return componente
      })

      await axios.put(`projeto/projeto-componente-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {

          })
          .catch(error => {
            console.log(error)
          })
    }
  }
};
</script>

<style lang="scss" scoped>
.fundo-modal{
  @apply absolute flex justify-center items-center bg-black bg-opacity-10 left-0 top-0 w-screen h-screen z-[10000]
}

.modal{
  @apply absolute z-[10000] flex flex-col justify-center gap-20 items-center bg-white py-6 px-4 rounded-xl shadow-xl
}
</style>