<template>


  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Unidades de Medida</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center gap-3 mb-10">

      <div class="flex gap-8">
        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaUnidadeMedida(1)">Pesquisar
          </button>
        </div>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/unidade-medida-cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Codigo
            </th>
            <th class="px-6 py-3">
              Descrição
            </th>
            <th class="px-6 py-3">
              Produtos Permitidos
            </th>
            <th class="px-6 py-3 text-center">
              Medição
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Editar
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(unidade, index) in lista_unidade_medida" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="unidade.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ unidade.codigo }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ unidade.nome }}
            </td>
            <td class="px-6 py-2 text-xs">
              <span v-for="(produtos_permitidos, index) in unidade.produtos_do_sistema" :key="index">
                {{ produtos_permitidos.nome + ', ' }}
              </span>
            </td>

            <!-- <template v-if="unidade.medicao === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="unidade.medicao === false">
              <td class="px-1 sm:px-6  py-1 sm:py-4">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template> -->

            <td class="text-center">
              <input type="checkbox" v-model="unidade.medicao" class="no-click">
            </td>


            <template v-if="unidade.ativo === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="unidade.ativo === false">
              <td class="px-1 sm:px-6  py-1 sm:py-4">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>

            <td  class="px-6 py-2 text-xs">
              <a @click.prevent="selecionarUnidadeEditar(unidade)" href="#" class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>

          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_unidade_medida.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
      <editar-unidade-medida v-if="mostrarModal" :unidade="unidadeSelecionada"
        :lista-produtos-permitidos="lista_produtos_permitidos" @fecharModal="toggleModal" />
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarUnidadeMedida from "@/components/midais/Editar/produtos/EditarUnidadeMedida";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaUnidadeMedida",
  components: {
    EditarUnidadeMedida,
    Pagination
  },

  data() {
    return {
      lista_unidade_medida: [],
      lista_produtos_permitidos: [],
      lista_itens_produtos_permitidos: [],
      produtos_permitidos: [],
      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      mostrarModal: false,
      unidadeSelecionada: null,

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  created() {
    this.getProdutosDoSistema()
  },

  methods: {
    async getProdutosPermitidos(idUnidade) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`/produtos/unidade-medida/${idUnidade}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        console.log(response.data)
        // Mapear os produtos permitidos a partir da resposta
        this.produtos_permitidos = response.data

        this.selecionarUnidadeEditar(idUnidade)

      } catch (error) {
        console.error("Erro ao buscar produtos permitidos:", error);
      }
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    selecionarUnidadeEditar(unidade) {
      this.unidadeSelecionada = unidade
      this.toggleModal()
    },

    toggleModal() {
      this.mostrarModal = !this.mostrarModal
    },


    async getListaUnidadeMedida(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.get(`/produtos/unidade-medida/?page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_unidade_medida = response.data.results;
        this.countItens = response.data.count
        console.log(this.lista_unidade_medida);

      } catch (error) {
        console.error("Erro ao buscar unidades de medida:", error);

      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaUnidadeMedida(pagina);
    },


    async getProdutosDoSistema() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.get(`produtos/produtos-sistema/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        this.lista_produtos_permitidos = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }))
      } catch (error) {
        console.log(error)
      }
    }


  }
}
</script>

<style lang="scss" scoped>
.no-click {
    pointer-events: none;
  }
</style>