<template>


    <!-- TEMPLATE DASHBOARD TIPOS PRA CIMA DE 768 PIXELS -->
    <div class="container-formulario">
      <div class="cabecalho-dashboard">
        <h1>Cadastro de Tipos</h1>
  
        <button @click="$router.push(`/tipos`)" class="text-2xl">
          <i class="fas fa-chevron-left mr-1 "></i>
        </button>
      </div>
  
      <div class="flex flex-wrap h-[66vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-corBase/40 pb-8">
  
        <button class="escopo-dashboard-desktop" @click="$router.push('/metodos-pagamento')">
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Métodos de Pagamento</h2>
              <i class="fa-solid fa-money-bill-wave"></i>
            </div>
            <div class="descricao-opcao">
              <p class="mt-6">Cadastre os Métodos de Pagamento presentes em seu sistema!</p>
            </div>
          </div>
        </button>
  
        <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-componente')">
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Componente</h2>
              <i class="fa-solid fa-gears"></i>
            </div>
            <div class="descricao-opcao">
              <p class="mt-6">Cadastre os Tipos de Componente presentes em seu sistema!</p>
            </div>
          </div>
        </button>
  
        <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-email')">
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Email</h2>
              <i class="fa-solid fa-envelope"></i>
            </div>
            <div class="descricao-opcao">
              <p class="mt-6">Cadastre os Tipos de Email presentes em seu sistema!</p>
            </div>
          </div>
        </button>
  
        <button class="escopo-dashboard-desktop" @click="$router.push('/tipo-endereco')">
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Endereço</h2>
              <i class="fa-solid fa-location-dot"></i>
            </div>
            <div class="descricao-opcao">
              <p class="mt-6">Cadastre os Tipos de Endereço presentes em seu sistema!</p>
            </div>
          </div>
        </button>
  
        <button class="escopo-dashboard-desktop" @click="$router.push(`/financeiros`)">
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Financeiro</h2>
              <i class="fa-solid fa-money-bill"></i>
            </div>
            <div class="descricao-opcao">
              <p class="mt-6">Cadastre o Financeiro presente em seu sistema!</p>
            </div>
          </div>
        </button>

  
      </div>
    </div>
  
  
  </template>
  
  <script>
  
  export default {
    name: "Tipos",
  
    computed: {
      isadmin() {
        return this.$store.state.isAdminUser
      },
    }
  
  
  }
  
  </script>
  
  <style lang="scss"></style>