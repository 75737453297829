<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white" @submit.prevent="putDadosEditado">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Projetista</h2>
            <div>
              <div class="md:flex md:gap-12">
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Código:</label>
                  <input type="text" required class="input-campos text-base" v-model="projetistaSistemista.codigo">
                </div>
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Nome:</label>
                  <input type="text" required class="input-campos text-base" v-model="projetistaSistemista.nome">
                </div>
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Empresa:</label>
                  <v-select v-model="projetistaSistemista.empresa" :options="lista_empresa" label="nome_razao" class="bg-white" />
                </div>

                <div class="flex flex-col mt-2 w-full justify-start ">
                  <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                  <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                    <i v-if="projetistaSistemista.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                    <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                  </span>
                </div>


              </div>

            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" type="submit">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso === true" mensagem="Projetista editado com sucesso!" />
      <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar." />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
// import ToastError from "@/components/toasts/toast_erro/ToastError";
// import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";

export default {
  name: "EditarProjetistaSistemista",
  components: {
    Select2,
    vSelect,
    SucessoRapido,
    ErroRapido,
  },
  data() {
    return {
      statusModal: false,
      lista_empresa: [],
      sucesso: false,
      erro: false,

    }
  },
  props: ["projetistaSistemista"],
  emits: ["fecharModal", "atualizarLista"],

  created() {
    this.getListaEmpresa()
    console.log(this.projetistaSistemista)
  },

  methods: {
    toggleAtivo() {
      this.projetistaSistemista.ativo = !this.projetistaSistemista.ativo
    },

    // onEscKeyPressed(event) {
    //   if (event.key === 'Escape') {
    //     this.statusModal = false
    //     this.$parent.getListaProjetistaSistemista()
    //   }
    // },

    // onEnterKeyPressed(event) {
    //   if (event.key === 'Enter') {
    //     this.putDadosEditado()
    //   }
    // },

    async putDadosEditado() {
      console.log(this.projetistaSistemista)
      const dataUpdated = {
        codigo: this.projetistaSistemista.codigo,
        nome: this.projetistaSistemista.nome,
        ativo: this.projetistaSistemista.ativo,
        empresa: this.projetistaSistemista.empresa?.id ? [this.projetistaSistemista.empresa[0].id] : []
      }

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`projeto/projetista-sistemista/${this.projetistaSistemista.id}/`, dataUpdated, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response)

        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);

      } catch (error) {
        console.error(error)
        this.erro = true
        setTimeout(() => {
          this.erro = false;
        }, 3000);
      }
    },

    async getListaEmpresa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/juridicas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_empresa = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    }

  }
}
</script>

<style lang="scss"></style>