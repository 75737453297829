<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Editar Vão - {{ vao.descricao }} </h1>
            </div>

            <router-link :to="`/obra/${$route.params.id_obra}`" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Formulário de edição de vão -->
        <div>
            <div class="form-group flex items-center">
                <label for="descricao" class="mr-2 text-corBase">Descrição</label>
                <input v-model="vao.descricao" type="text" id="descricao" placeholder="Digite a descrição..."
                    required />
            </div>

            <div class="form-group flex items-center">
                <label for="tipologia" class="mr-5 text-corBase">Modelo</label>
                <v-select v-model="modeloSelecionado" :options="lista_modelos" label="descricao"
                    placeholder="Selecione um modelo de tipologia" class="w-full pt-2" :clearable="false"
                    @update:modelValue="getTipologias(modeloSelecionado.id)" />

            </div>

            <div class="form-group flex items-center">
                <label for="tipologia" class="mr-2 text-corBase">Tipologia</label>
                <!-- <select class="cursor-pointer" v-model="tipologiaSelecionada" @change="onTipologiaChange">
                    <option value="" disabled>Selecione uma tipologia</option>
                    <option v-for="tipologia in tipologias" :key="tipologia.id" :value="tipologia.id">
                        {{ tipologia.nome }}
                    </option>
                </select> -->
                <v-select v-model="tipologiaSelecionada" :options="tipologias" label="nome"
                    placeholder="Selecione uma tipologia" class="w-full pt-2" :clearable="false"
                    @update:modelValue="onTipologiaChange" :reduce="tipologiaSelecionada => tipologiaSelecionada.id" />

            </div>

            <!-- Lista de linhas baseada na tipologia selecionada -->
            <div class="form-group" v-if="linhas.length > 0">
                <p>Linhas disponíveis:</p>
                <div v-for="linha in linhas" :key="linha.id" class="flex">
                    <label class="mr-2">{{ linha.linha }}</label>
                    <input type="checkbox" :value="linha" v-model="linhasSelecionadas" />
                </div>
                <button type="button" class="btn-salvar" @click="adicionarTipologia">Adicionar Tipologia</button>
            </div>

            <!-- Seção de abas -->
            <div class="bg-gray-300 p-5 pt-0 rounded-lg">
                <div class="abas-container">
                    <template v-for="(aba, index) in abas" :key="index">
                        <div :class="['aba', { 'aba-selecionada': abaSelecionada === index }]">
                            {{ aba.nome }}
                        </div>
                    </template>
                </div>

                <div v-if="abas.length > 0">
                    <div v-for="(aba, index) in abas" :key="index" v-show="abaSelecionada === index">

                        <!-- Aba Geral -->
                        <GeralComponent v-if="aba.nome === 'Geral'" :vao="vao" :orcamento="orcamento"
                            @update-file="onFileChange" />

                        <!-- Aba Projetos -->
                        <div v-else-if="aba.nome === 'Projetos'">
                            <h2 class="text-lg font-bold text-corBase pb-2">Projetos ({{ calcularTotalProjetos() }}):
                            </h2>
                            <!-- TAMANHO: {{ projetosComConteudo.length }} -->
                            <!-- PROJETOS: {{ projetosComConteudo }} -->
                            <div v-if="projetosComConteudo.length > 0" class="flex flex-wrap justify-center gap-4">
                                <div v-for="projeto in projetosComConteudo" :key="projeto.id"
                                    class="">

                                <!-- V-FOR: {{ projeto.linhas.linha }} -->

                                    <ul class="w-full sm:w-[80%]">
                                        <!-- v-for="(projetoLista, index) in projeto.projeto" :key="index" -->
                                        <li
                                            class="flex flex-col items-center p-4 bg-white border border-corBase/40 shadow-md rounded-md w-full">
                                            <img src="@/assets/imagens/fotos/janela2folhas.png" alt="Imagem do Projeto"
                                                class="w-40 h-40 object-cover mb-4">

                                                <!-- projetolista: {{ projetoLista }} -->

                                            <strong class="block text-base font-semibold">Projeto: </strong>
                                            <span class="text-gray-700 whitespace-nowrap text-sm pb-3">
                                                <p>{{ projeto.projeto.descricao_fixa || '' }}</p>
                                            </span>
                                            <strong class="block text-base font-semibold">Linha: </strong>
                                            <span class="text-gray-700 whitespace-nowrap text-sm pb-3">
                                                <p>{{ projeto.linhas.linha }}</p>
                                            </span>
                                            <hr>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div v-else>
                                <p>Nenhum projeto encontrado para este vão.</p>
                            </div>
                        </div>

                        <div v-else-if="aba.nome === 'Tipologias'">
                            <h2 class="text-lg font-bold text-corBase pb-2">Tipologias Selecionadas:</h2>

                            <!-- Verificar se há tipologias nos projetos -->
                             <!-- tipologias: {{ projetos }} -->

                             <div v-for="projeto in projetos" :key="projeto.id">
                                <!-- {{ projeto.tipologias }} -->
                             </div>
                            <div v-if="projetos.length > 0 && projetos.some(projeto => projeto.tipologias.length > 0)"
                                class="flex flex-wrap justify-center gap-4">
                                <div v-for="projeto in projetos" :key="projeto.id"
                                    class="flex flex-col items-center w-full sm:w-1/2 md:w-1/4 lg:w-1/4 text-center">
                                    <!-- {{ projeto }} -->
                                    <ul class="w-full sm:w-[80%]">
                                        <li v-for="(listaTipologias, index) in projeto.tipologias"
                                            :key="listaTipologias.id"
                                            class="flex flex-col items-center p-4 bg-white border border-corBase/40 shadow-md rounded-md w-full">

                                            {{ listaTipologias }}
                                            <!-- COM PROJETO ASSOCIADO -->
                                            <div v-if="projeto.projeto[index]?.tipologia === listaTipologias.id">
                                                <i class="fa-solid fa-triangle-exclamation fa-beat fa-xl text-red-500 mb-4"
                                                    :title="`Tipologia associada ao projeto '${projeto.projeto[index]?.descricao_fixa || ''}'`">
                                                </i>
                                                <!-- Texto adicional em dispositivos móveis -->
                                                <p class="text-red-500 text-xs sm:hidden">
                                                    Tipologia associada ao projeto '{{
                                                        projeto.projeto[index]?.descricao_fixa || '' }}'
                                                </p>
                                                <div class="flex w-full items-center justify-center">
                                                    <img :src="listaTipologias.imagem.url_documento"
                                                        alt="Imagem da Tipologia"
                                                        class="w-24 h-24 md:w-32 md:h-32 object-cover rounded mb-2">
                                                </div>
                                                <strong class="block text-base font-semibold">Tipologia:</strong>
                                                <span class="text-gray-700 whitespace-nowrap text-sm pb-3">
                                                    <p>{{ listaTipologias.nome }}</p>
                                                </span>
                                                <strong class="block text-base font-semibold">Linha:</strong>
                                                <span v-for="linha in projeto.linhas" :key="linha.id"
                                                    class="text-gray-700 whitespace-nowrap text-sm pb-3">
                                                    <p>{{ linha.linha }}</p>
                                                </span>
                                            </div>

                                            <!-- SEM PROJETO ASSOCIADO -->
                                            <div v-else class="text-center">
                                                <p class="text-gray-500">Tipologia sem projeto associado:</p>
                                                <div class="flex w-full items-center justify-center">
                                                    <img :src="listaTipologias.imagem.url_documento"
                                                        alt="Imagem da Tipologia"
                                                        class="w-24 h-24 md:w-32 md:h-32 object-cover rounded mb-2">
                                                </div>
                                                <strong class="block text-base font-semibold">Tipologia:</strong>
                                                <span class="text-gray-700 whitespace-nowrap text-sm pb-3">
                                                    <p>{{ listaTipologias.nome }}</p>
                                                </span>
                                                <strong class="block text-base font-semibold">Linha:</strong>
                                                <span v-for="linha in projeto.linhas" :key="linha.id"
                                                    class="text-gray-700 whitespace-nowrap text-sm pb-3">
                                                    <p>{{ linha.linha }}</p>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Exibe mensagem caso não haja tipologias -->
                            <div v-else>
                                <p>Nenhuma tipologia adicionada para este vão.</p>
                            </div>
                        </div>

                        <!-- Aba correspondente a tipologia -->
                        <div v-else class="flex items-start">
                            <!-- Coluna das imagens -->
                            <div class="w-1/2 flex flex-col items-center text-center">
                                <h4 class="text-corBase">Imagem da tipologia:</h4>
                                <img :src="aba.imagem.url_documento" class="foto-tipologia w-32 h-auto cursor-pointer"
                                    alt="Imagem tipologia" @click="abrirModal(aba.imagem.url_documento)">

                                <div v-if="vao.imagem">
                                    <h4 class="text-corBase mt-10">Imagem do vão:</h4>
                                    <img :src="vao.imagem.url_documento"
                                        class="foto-tipologia w-32 h-auto cursor-pointer object-cover"
                                        alt="Pré-visualização" @click="abrirModal(vao.imagem.url_documento)" />
                                </div>
                            </div>

                            <!-- Linha de separação vertical -->
                            <div class="w-px h-full bg-gray-600 mx-4"></div>

                            <!-- Coluna das informações da linha -->
                            <div class="w-1/2 flex flex-col space-y-4">
                                <!-- Abas internas para linhas -->
                                <div class="linhas-abas-container">
                                    <template v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex">
                                        <div :class="['linha-aba', { 'linha-aba-selecionada': linhaSelecionada === linhaIndex }]"
                                            @click="selecionarLinha(linhaIndex, aba)">
                                            {{ linha.linha }}
                                        </div>
                                    </template>
                                </div>

                                <!-- Exibe o componente CriarVariaveisOrcamento quando o projeto for selecionado -->
                                <div v-if="mostrarVariaveisPorLinha[`${aba.id}_${aba.linhas[linhaSelecionada]?.nome}`]">
                                    <!-- <CriarVariaveisOrcamento
                                        :id="projetoSelecionadoPorLinha[`${aba.id}_${aba.linhas[linhaSelecionada]?.nome}`]" /> -->
                                </div>

                                <!-- Conteúdo da linha selecionada -->
                                <div v-else-if="aba.linhas.length > 0">
                                    <div v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex"
                                        v-show="linhaSelecionada === linhaIndex">

                                        <!-- Verifica se um projeto foi selecionado -->
                                        <div v-if="projetosSelecionados[`${aba.nome}_${linha.linha}`]">
                                            <p class="text-corBase">Projeto Selecionado:</p>
                                            <p><strong>Descrição:</strong> {{
                                                projetosSelecionados[`${aba.nome}_${linha.linha}`]?.descricao_fixa }}
                                            </p>
                                            <p>Ocorreu um erro. Reinicie a página</p>
                                        </div>

                                        <!-- Exibição dos projetos  -->
                                        <div v-else>
                                            <p class="text-corBase font-semibold text-center pb-5">Lista de Projetos
                                            </p>
                                            <!-- Lista de Projetos -->
                                            <ListaProjetos
                                                v-if="listaProjetos[`${linha.linha}_${aba.id_tipologia}`]?.length > 0"
                                                :projetos="listaProjetos[`${linha.linha}_${aba.id_tipologia}`]"
                                                :abaId="aba.id" :nomeDaLinha="linha"
                                                @projeto-selecionado="salvarProjetos" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Botões para navegação entre abas -->
                <div class="flex w-full justify-end itens-center mt-6">
                    <button v-if="abaSelecionada > 0" @click="voltarAba" class="btn-cancelar">Voltar</button>
                    <button v-if="abaSelecionada < abas.length - 1" @click="onAvancarAba"
                        class="btn-salvar">Avançar</button>
                    <button v-else @click="onAvancarAba" class="btn-salvar">Salvar</button>
                </div>
            </div>
        </div>
    </div>

    <SucessoRapido v-if="sucesso" mensagem="Vão atualizado com sucesso." />
    <ErroRapido v-if="erro" :mensagem="erroMessage" />
</template>


<script>
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import GeralComponent from './ComponentesCriacaoVao/GeralComponent.vue';
import vSelect from 'vue-select';
import ListaProjetos from './ComponentesCriacaoVao/ListaProjetos.vue';
import axios from 'axios';

export default {
    name: 'EditarVao',

    components: {
        SucessoRapido,
        ErroRapido,
        GeralComponent,
        vSelect,
        ListaProjetos,
    },

    data() {
        return {
            vao: {
                descricao: '',
                largura: null,
                altura: null,
                local_vao: '',
                cor_componente: '',
                cor_perfil: '',
                cor_vidro: '',
                imagem: null,
                codigo_arquitetonico: '',
                imagePreview: null
            },

            orcamento: {
                tipoMedidaContramarco: '',
                contramarco: false,
                arremate: false,
                arrematePiso: false
            },


            tipologiaSelecionada: null,

            tipologias: [],
            linhas: [],
            linhasSelecionadas: [],

            abas: [
                { nome: 'Geral', ativo: true },
                { nome: 'Projetos', ativo: true },
                { nome: 'Tipologias', ativo: true },
            ],

            abaSelecionada: 2,
            linhaSelecionada: 0,

            sucesso: false,
            erro: false,
            erroMessage: '',

            fotoAmpliada: null,

            modeloSelecionado: null,
            lista_modelos: [],

            projetosPorLinha: {},
            projetosSelecionados: {},

            listaProjetosSelecionados: [],
            listaProjetos: {},
            listaProjetosPublic: {},

            projetoSelecionadoPorLinha: {},

            mostrarVariavel: false,
            mostrarVariaveisPorLinha: {},
            projetos: [],

            vaoId: '',
            projeto_id: '',

        };
    },

    created() {
        this.vaoId = this.$route.params.vao;
        this.buscarDadosVao();
        this.getModelosTipologia()
    },

    computed: {
        projetosComConteudo() {
            return this.projetos.filter(projeto => projeto.projeto != null);
        }

    },

    methods: {

        async getModelosTipologia() {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`projeto/modelo-variacao/?page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                this.lista_modelos = response.data.results
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getTipologias(modeloId) {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`/projeto/tipologia/?ativo=True&modelo=${modeloId}&page_size=9999`, {
                    headers: { Authorization: `Token ${token}` }
                });
                // console.log(response.data.results)
                this.tipologias = response.data.results
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        calcularTotalProjetos() {
            console.log(this.projetos);
            return this.projetos.reduce((total, projeto) => {
                // Incrementa o total apenas se 'projeto.projeto' não for null ou undefined
                return total + (projeto.projeto ? 1 : 0);
            }, 0);
        },

        async getLinhas() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`projeto/linha-sistema/?page_size=9999`, {
                    headers: { Authorization: `Token ${token}` }
                });
                this.linhas = response.data.results;
            } catch (error) {
                console.error('Erro ao carregar linhas:', error);
            }
        },

        async buscarCliente() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`/obra/?obra=${this.$route.params.id_obra}`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                )
                this.cliente = response.data.results[0].cliente.id
            } catch (error) {
                console.error(error)
            }
        },

        // Modal de Variáveis do Projeto
        toggleVariaveis(projetoId) {
            this.mostrarVariavel = !this.mostrarVariavel
            this.projeto_id = projetoId
        },

        // Buscar os dados do vão
        async buscarDadosVao() {

            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                const response = await axios.get(`/obra/vao-obra/${this.vaoId}`, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.vao = response.data;

                // console.log(this.vao)

                if (this.vao.orcamento) {
                    this.orcamento.tipoMedidaContramarco = this.vao.orcamento.tipo_medida_contramarco === 'E' ? 'Externa' : 'Interna';
                    this.orcamento.contramarco = this.vao.orcamento.contramarco;
                    this.orcamento.arremate = this.vao.orcamento.arremate;
                    this.orcamento.arrematePiso = this.vao.orcamento.arremate_piso;
                }

                await this.buscarProjetosVao();
            } catch (error) {
                console.error('Erro ao buscar dados do vão:', error);
            }
        },

        // Buscar projetos do vão
        async buscarProjetosVao() {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                const response = await axios.get(`obra/linhas-projeto-vao-obra/?vao=${this.vaoId}`, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.projetos = response.data.results;
                console.log(this.projetos)
            } catch (error) {
                console.error('Erro ao buscar dados do orçamento:', error);
            }
        },

        async buscarProjetos(tipologiaId, linhaNome) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(
                    `/projeto/projeto/?tipologia=${tipologiaId}&linha_nome=${linhaNome}`, {
                    headers: { Authorization: `Token ${token}` }
                });

                const linhaNome_tipologiaId = `${linhaNome}_${tipologiaId}`;

                this.listaProjetos[linhaNome_tipologiaId] = response.data.results;
                console.log('Chave composta:', linhaNome_tipologiaId);
                console.log('Lista de Projetos:', this.listaProjetos[linhaNome_tipologiaId]);
            } catch (error) {
                console.error('Erro ao buscar projetos:', error);
                return [];
            }
        },

        // Teste Projeto Perfil, Teste Janela 2 folhas, teste jaela, teste janela 2 folhas
        async salvarVao() {
            // console.log(this.vao)
            const formDataVao = new FormData();
            formDataVao.append('obra', this.vao.obra);
            formDataVao.append('ativo', 'true');
            formDataVao.append('descricao', this.vao.descricao);
            formDataVao.append('largura', this.vao.largura);
            formDataVao.append('altura', this.vao.altura);
            formDataVao.append('local_vao', this.vao.local_vao.id);
            if (this.vao.imagemFile != null) {
                formDataVao.append('imagem', this.vao.imagemFile);
            }
            formDataVao.append('cor_componente', this.vao.cor_componente);
            formDataVao.append('cor_perfil', this.vao.cor_perfil);
            formDataVao.append('cor_vidro', this.vao.cor_vidro);
            formDataVao.append('codigo_arquitetonico', this.vao.codigo_arquitetonico);

            // Adicionar as tipologias e suas linhas
            // console.log(this.abas.slice(3))
            this.abas.slice(3).forEach((aba, index) => {
                formDataVao.append(`tipologias[${index}][id]`, aba.id_tipologia);
                aba.linhas.forEach((linha, linhaIndex) => {
                    formDataVao.append(`tipologias[${index}][linhas][${linhaIndex}]`, linha.id);
                });
            });

            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                const response = await axios.patch(`/obra/vao-obra/${this.vaoId}/`, formDataVao, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$router.push(`/obra/${this.$route.params.id_obra}`);
                }, 3000);
                this.salvarProjetos(this.vaoId)
            } catch (error) {
                console.error(error);
            }
        },

        onFileChange(file) {
            const imageUrl = URL.createObjectURL(file);
            this.vao.imagem = file;
            // this.vao.imagePreview = imageUrl;
        },


        selecionarLinha(linhaIndex, aba) {
            this.linhaSelecionada = linhaIndex;
            const chave = `${aba.id}_${aba.linhas[linhaIndex].nome}`;
            this.mostrarVariaveis = !!this.mostrarVariaveisPorLinha[chave];
        },

        async incluirItemOrcamento(projeto) {
            // Está vindo tudo como um único objeto (projeto) do componente ListaProjetos

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const data = {
                    orcamento: this.vao.orcamento.id,
                    projeto: projeto.projeto.id,
                    descricao: this.vao.descricao,
                    quantidade: this.quantidade,
                    largura: this.vao.largura,
                    altura: this.vao.altura,
                    contramarco: this.orcamento.contramarco,
                    arremate: this.orcamento.arremate,
                    arremate_piso: this.orcamento.arrematePiso,
                    cor_componentes: this.vao.cor_componente,
                    cor_perfis: this.vao.cor_perfil,
                    cor_vidros: this.vao.cor_vidro,
                    status_medidas: "2"
                };

                const response = await axios.post('orcamentos/item-orcamento/', data, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.listaProjetosSelecionados.push({
                    projetoId: projeto.projeto.id,
                    linhaId: projeto.nomeDaLinha.id
                });

                this.idItemOrcamento = response.data.id;
                this.projetoSelecionadoPorLinha[`${projeto.abaId}_${projeto.nomeDaLinha.nome}`] = response.data.id;
                this.mostrarVariaveisPorLinha[`${projeto.abaId}_${projeto.nomeDaLinha.nome}`] = true;
            } catch (error) {
                console.error(error);
            }
        },

        // Carrega as linhas com base na tipologia selecionada
        async onTipologiaChange() {
            await this.getLinhas();
            this.linhasSelecionadas = [];
        },

        // Função para salvar os projetos associados ao vão
        async salvarProjetos(id_vao) {
            // alert('tá aqui')
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            // Verifica se há projetos selecionados
            const hasProjetos = Object.keys(this.projetosSelecionados).length > 0;

            if (hasProjetos) {
                // alert("Iniciando o salvamento dos projetos selecionados.");

                // Envia os projetos selecionados com suas linhas e tipologias
                for (const chave in this.projetosSelecionados) {
                    const projetoIndex = this.projetosSelecionados[chave];
                    // alert(`Processando projeto com chave: ${chave}`);

                    if (projetoIndex && projetoIndex.projeto && projetoIndex.nomeDaLinha && projetoIndex.projeto.id) {
                        const { projeto, nomeDaLinha } = projetoIndex;

                        // Encontra a tipologia associada à chave do projeto e linha específicos
                        const [tipologiaNome, linhaNome] = chave.split('_');
                        const tipologiaAssociada = this.abas.find(aba => aba.nome === tipologiaNome);
                        // alert(`Tipologia associada encontrada: ${tipologiaAssociada ? tipologiaAssociada.nome : 'Nenhuma'}`);

                        if (tipologiaAssociada && tipologiaAssociada.id_tipologia) {
                            const formDataProjeto = new FormData();

                            formDataProjeto.append('vao_obra', id_vao);
                            formDataProjeto.append('ativo', 'true');
                            formDataProjeto.append('projeto', projeto.id);
                            formDataProjeto.append('linhas', nomeDaLinha.id);
                            formDataProjeto.append('tipologias', tipologiaAssociada.id_tipologia);

                            this.$store.dispatch("setLoading", true);
                            try {
                                await axios.post(`obra/linhas-projeto-vao-obra/`, formDataProjeto, {
                                    headers: {
                                        Authorization: `Token ${token}`
                                    }
                                });
                                // alert(`Projeto ${projeto.id} com tipologia ${tipologiaAssociada.nome} e linha ${nomeDaLinha.nome} salvo com sucesso.`);
                            } catch (error) {
                                // alert(`Erro ao enviar o projeto ${projeto.id} com linha ${nomeDaLinha.nome}: ${error}`);
                                console.error(`Erro ao enviar o projeto ${projeto.id} com linha ${nomeDaLinha.nome}:`, error);
                            } finally {
                                this.$store.dispatch("setLoading", false);
                            }
                        } else {
                            // alert(`Tipologia associada não encontrada ou inválida para o projeto ${projeto.id} e linha ${nomeDaLinha.nome}`);
                            console.warn(`Tipologia associada não encontrada ou inválida para o projeto ${projeto.id} e linha ${nomeDaLinha.nome}`);
                        }
                    } else {
                        // alert(`Projeto ou linha inválido na chave ${chave}.`);
                        console.warn(`Projeto ou linha inválido na chave ${chave}.`);
                    }
                }
            } else {
                // alert("Nenhum projeto foi selecionado para salvar.");
            }

            // === INÍCIO DAS ALTERAÇÕES PARA TIPOS SEM PROJETOS ===

            // alert("Iniciando salvamento de tipologias sem projetos.");

            // Salva as tipologias que não possuem projetos associados
            for (const aba of this.abas) {
                if (aba.nome !== 'Geral' && Array.isArray(aba.linhas) && aba.linhas.length > 0 && aba.id_tipologia) {
                    const formDataSemProjetos = new FormData();

                    formDataSemProjetos.append('vao_obra', id_vao);
                    formDataSemProjetos.append('ativo', 'true');
                    formDataSemProjetos.append('tipologias', aba.id_tipologia);

                    // Verifica se a tipologia já foi associada a um projeto selecionado, caso contrário, salva separadamente
                    const isAssociatedWithProject = Object.values(this.projetosSelecionados).some(projeto =>
                        projeto.nomeDaLinha && aba.linhas.some(linha => linha.id === projeto.nomeDaLinha.id)
                    );

                    // alert(`Tipologia: ${aba.nome} - Já associada a projeto: ${isAssociatedWithProject}`);

                    if (!isAssociatedWithProject) {
                        // Adiciona as linhas específicas da tipologia atual
                        for (const linha of aba.linhas) {
                            if (linha.id) {
                                formDataSemProjetos.append('linhas', linha.id);
                            }
                        }

                        this.$store.dispatch("setLoading", true);
                        try {
                            await axios.post(`obra/linhas-projeto-vao-obra/`, formDataSemProjetos, {
                                headers: {
                                    Authorization: `Token ${token}`
                                }
                            });
                            // alert(`Tipologia ${aba.id_tipologia} com suas linhas salvas com sucesso (sem projetos).`);
                        } catch (error) {
                            // alert(`Erro ao salvar tipologia ${aba.id_tipologia} com suas linhas: ${error}`);
                            console.error(`Erro ao salvar tipologia ${aba.id_tipologia} com suas linhas:`, error);
                        } finally {
                            this.$store.dispatch("setLoading", false);
                        }
                    }
                }
            }
            // alert("Finalizado o salvamento de tipologias sem projetos.");
            // === FIM DAS ALTERAÇÕES ===
        },

        // Adiciona a tipologia e as linhas selecionadas como aba
        async adicionarTipologia() {
            // alert("clique")
            const tipologia = this.tipologias.find(t => t.id === this.tipologiaSelecionada);
            if (tipologia && this.linhasSelecionadas.length > 0) {
                const uniqueAbaId = `${tipologia.nome}_${this.abas.length}`;

                this.abas.push({
                    nome: `${tipologia.nome}`,
                    ativo: true,
                    id: uniqueAbaId,
                    id_tipologia: tipologia.id,
                    linhas: this.linhasSelecionadas.slice(),
                    imagem: tipologia.imagem
                });

                for (const linha of this.linhasSelecionadas) {
                    const projetos = await this.buscarProjetos(tipologia.id, linha.linha);
                    this.projetosPorLinha[`${uniqueAbaId}_${linha.linha}`] = projetos;
                }

                this.tipologiaSelecionada = null;
                this.linhasSelecionadas = [];
                this.linhas = [];
            }
        },

        voltarAba() {
            if (this.abaSelecionada > 0) {
                this.abaSelecionada--;
            }
        },

        proximaAba() {
            if (this.abaSelecionada < this.abas.length - 1) {
                this.abaSelecionada++;
            }
        },

        async onAvancarAba() {
            // Verificação de campos obrigatórios na aba "Geral"
            if (this.abaSelecionada === 0) {
                // Verifica se todos os campos obrigatórios estão preenchidos
                if (!this.vao.descricao || !this.vao.largura || !this.vao.altura || !this.vao.cor_componente || !this.vao.cor_perfil || !this.vao.cor_vidro) {
                    this.erro = true;
                    this.erroMessage = "Preencha todos os campos obrigatórios.";
                    setTimeout(() => {
                        this.erro = false;
                        this.erroMessage = '';
                    }, 3000);
                    return;
                }

                // Avançar para a próxima aba
                this.proximaAba();

            } else if (this.abaSelecionada === this.abas.length - 1) {
                await this.salvarVao();

            } else {
                this.proximaAba();
            }
        }

    }
};
</script>

<style scoped>
label {
    display: block;
    font-weight: bold;
}

input[type="file"],
input[type="number"],
input[type="text"],
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.abas-container {
    display: flex;
    margin-top: 15px;
    @apply mt-4 p-2 border-b rounded-lg
}

.aba {
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    @apply rounded-lg bg-white text-corBase border border-white
}

.aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.foto-tipologia {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    z-index: 10;
}

.linhas-abas-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 5px;
}

.linha-aba {
    padding: 10px;
    cursor: pointer;
    margin-top: 2px;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    @apply rounded-lg bg-white text-corBase border border-white
}

.linha-aba-selecionada {
    @apply bg-corBase text-white border border-white
}
</style>