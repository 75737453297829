<template>
    <FormularioContasReceber />
</template>

<script>
import FormularioContasReceber from '@/components/financeiro/ContasAReceber/FormularioContasReceber.vue';

export default {
    name: 'NovaContaAReceber',
    components: {
        FormularioContasReceber
    }
}
</script>