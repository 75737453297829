<template>
    <div>
        <div class="form-group">
            <label for="cep" class="font-bold">CEP</label>
            <input v-model="obra.cep" type="text" id="cep" @blur="buscarEndereco" maxlength="8"
                placeholder="Digite aqui..." />
        </div>

        <div class="form-group">
            <label for="endereco" class="font-bold">Logradouro</label>
            <input v-model="obra.endereco" type="text" id="endereco" placeholder="Digite aqui..." required />
        </div>

        <div class="form-group">
            <label for="numero" class="font-bold">Número</label>
            <input v-model="obra.numero" type="text" id="numero" placeholder="Digite aqui..." required
                @blur="gerarLinkGoogleMaps" />
        </div>

        <div class="form-group">
            <label for="apartamento">Apartamento</label>
            <input v-model="obra.apartamento" type="text" id="apartamento" placeholder="Digite aqui..." />
        </div>

        <div class="form-group">
            <label for="bloco">Bloco</label>
            <input v-model="obra.bloco" type="text" id="bloco" placeholder="Digite aqui..." />
        </div>

        <div class="form-group">
            <label for="complemento">Complemento</label>
            <input v-model="obra.complemento" type="text" id="complemento" placeholder="Digite aqui..." />
        </div>

        <div class="form-group">
            <label for="bairro">Bairro</label>
            <input v-model="obra.bairro" type="text" id="bairro" placeholder="Digite aqui..." required />
        </div>

        <div class="form-group">
            <label for="estado" class="font-bold">Estado</label>
            <v-select v-model="obra.estado" :options="estados" :reduce="estado => estado.sigla" label="nome"
                placeholder="Selecione um estado" @select="buscarCidades()" />
            {{ obra.estado }}
        </div>

        <div class="form-group">
            <label for="cidade" class="font-bold">Cidade</label>
            <v-select v-model="obra.cidade" :options="cidades" :reduce="cidade => cidade.nome" label="nome"
                placeholder="Selecione uma cidade" />
            <div class="form-group">
                <button @click.prevent="buscarGeolocalizacao" class="btn-geolocalizacao">
                    <i class="fa-solid fa-location-dot mr-2"></i> Usar minha localização
                </button>
            </div>
        </div>

        <div class="form-group">
            <p>Google Maps: {{ enderecoMaps }}</p>
        </div>

        <!-- Google Maps -->
        <div v-if="exibirMapa" ref="map" class="map-container"></div>

        <!-- Link do Google Maps -->
        <div v-if="enderecoPreenchido" class="flex items-center justify-center">
            <p class="text-blue-500 mx-2">Google Maps:</p>
            <a :href="obra.localizacao" target="_blank" class="link-maps">
                <i class="fa-solid fa-map-location-dot fa-2x"></i>
            </a>
        </div>
    </div>
    <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
</template>

<script>
import axios from 'axios';
import { Loader } from '@googlemaps/js-api-loader';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import vSelect from 'vue-select';

export default {
    name: 'Endereco',
    props: {
        obra: Object
    },
    components: {
        ErroRapido,
        vSelect,
    },
    data() {
        return {
            erro: false,
            erroMessage: '',
            exibirMapa: false,
            mapa: null,
            marcador: null,
            loader: null,
            enderecoNominatim: [],
            enderecoMaps: [],
            googleApiKey: 'AIzaSyDjTRtVy30k5V3Rp_2QiJdwgnxdul3cQ8k',

            estados: [],
            cidades: [],
        };
    },
    created() {
        // Cria uma instância do Loader uma vez com as bibliotecas corretas
        this.loader = new Loader({
            apiKey: this.googleApiKey,
            version: 'weekly',
            libraries: ['marker'], // Inclui a biblioteca marker
        });

        this.carregarEstados()
    },
    watch: {
        'obra.estado': {
            handler(novoEstado) {
                if (novoEstado) {
                    this.buscarCidades(novoEstado);
                }
            },
            immediate: false
        }
    },
    computed: {
        enderecoPreenchido() {
            return (
                this.obra.endereco !== '' &&
                this.obra.numero !== '' &&
                this.obra.bairro !== '' &&
                this.obra.cidade !== '' &&
                this.obra.estado !== ''
            );
        },

        enderecoCompleto() {
            // Incluindo o número no endereço completo
            return `${this.obra.endereco}, ${this.obra.numero}, ${this.obra.bairro}, ${this.obra.cidade}, ${this.obra.estado}`.trim();
        },
    },
    methods: {

        async carregarEstados() {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/estados/`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });

                this.estados = response.data.map((estado) => ({
                    sigla: estado.sigla,
                    nome: `${estado.nome} (${estado.sigla})`,
                }))
                    
            } catch (error) {
                console.error("Erro ao carregar estados:", error);
            }
        },

        async buscarCidades(estado) {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/municipios/?estado=${estado}`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });
                this.cidades = response.data.map((cidade) => ({
                    id: cidade.id,
                    nome: cidade.nome,
                }))
                    
            } catch (error) {
                console.error("Erro ao carregar cidades:", error);
            }
        },

        async buscarGeolocalizacao() {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;

                        // Exibe o mapa e define a localização
                        this.exibirMapa = true;
                        this.inicializarMapa(latitude, longitude);

                        try {
                            const token = await this.$store.dispatch("getAuthTokenCookie");
                            await this.$store.dispatch("fetchURLrequest");
                            const responseMaps = await axios.get(`obra/geocode/`, {
                                params: {
                                    lat: latitude,
                                    lng: longitude
                                },
                                headers: {
                                    'Authorization': `Token ${token}`
                                }
                            });
                            if (responseMaps.data.status === 'OK' && responseMaps.data.results.length > 0) {
                                const components = responseMaps.data.results[0].address_components;
                                console.log(components)

                                components.forEach(component => {
                                    if (component.types.includes("route")) this.obra.endereco = component.long_name;
                                    if (component.types.includes("street_number")) this.obra.numero = component.long_name;
                                    if (component.types.includes("sublocality") && component.types.includes("political")) this.obra.bairro = component.long_name;
                                    if (component.types.includes("administrative_area_level_2")) this.obra.cidade = component.long_name;
                                    if (component.types.includes("administrative_area_level_1")) this.obra.estado = component.short_name;
                                    if (component.types.includes("postal_code")) this.obra.cep = component.long_name;
                                });
                                this.gerarLinkGoogleMaps()
                                this.enderecoMaps = responseMaps.data.results[0].formatted_address;
                            } else {
                                this.exibirErro('Erro ao obter endereço do Google Maps.');
                            }
                        } catch (error) {
                            this.exibirErro('Erro ao obter a localização atual.');
                        }
                    },
                    (error) => this.exibirErro('Erro ao acessar a localização. Verifique as permissões.')
                );
            } else {
                this.exibirErro('Geolocalização não suportada pelo navegador.');
            }
        },

        inicializarMapa(latitude, longitude) {
            this.loader.load().then(() => {
                // Cria o mapa centrado em uma posição inicial (ou nas coordenadas fornecidas)
                this.mapa = new google.maps.Map(this.$refs.map, {
                    center: { lat: latitude, lng: longitude },
                    zoom: 15,
                    mapId: '64a7391d4599c326'
                });

                // Define um marcador inicial na posição clicada pelo usuário
                this.marcador = new google.maps.Marker({
                    map: this.mapa,
                    position: { lat: latitude, lng: longitude },
                    draggable: true, // Permite arrastar o marcador
                });

                // Adiciona evento para capturar cliques no mapa e definir nova posição do marcador
                this.mapa.addListener('click', (event) => {
                    const posicaoClicada = event.latLng;
                    this.marcador.setPosition(posicaoClicada);

                    // Obtém o endereço usando geocodificação reversa
                    this.buscarEnderecoPorCoordenadas(posicaoClicada.lat(), posicaoClicada.lng());
                });

                // Adiciona evento para capturar o final do arraste do marcador e obter o endereço
                this.marcador.addListener('dragend', () => {
                    const posicaoArrastada = this.marcador.getPosition();
                    this.buscarEnderecoPorCoordenadas(posicaoArrastada.lat(), posicaoArrastada.lng());
                });
            }).catch((error) => {
                console.error("Erro ao carregar o Google Maps:", error);
                this.exibirErro("Erro ao carregar o mapa.");
            });
        },

        async buscarEnderecoPorCoordenadas(latitude, longitude) {
            try {

                // Faz a requisição para a API de Geocodificação Reversa do Google
                const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
                    params: {
                        latlng: `${latitude},${longitude}`,
                        // key: 'AIzaSyBXW-iFRVNr4SHoK64O3T1pKxuBuqwLtOQ',
                        key: 'AIzaSyAvLUkHVk5FCoZQbqThHic8AA4T_QuGOBI',
                    }
                });

                if (response.data.status === 'OK' && response.data.results.length > 0) {
                    const addressComponents = response.data.results[0].address_components;
                    this.enderecoMaps = response.data.results[0].formatted_address;

                    // Atualiza o formulário com os dados de endereço
                    addressComponents.forEach(component => {
                        if (component.types.includes("route")) this.obra.endereco = component.long_name;
                        if (component.types.includes("street_number")) this.obra.numero = component.long_name;
                        if (component.types.includes("sublocality") && component.types.includes("political")) this.obra.bairro = component.long_name;
                        if (component.types.includes("administrative_area_level_2")) this.obra.cidade = component.long_name;
                        if (component.types.includes("administrative_area_level_1")) this.obra.estado = component.short_name;
                        if (component.types.includes("postal_code")) this.obra.cep = component.long_name;
                    });
                    this.gerarLinkGoogleMaps()
                } else {
                    this.exibirErro('Não foi possível obter o endereço para esta localização.');
                }
            } catch (error) {
                console.error("Erro ao buscar endereço:", error);
                this.exibirErro("Erro ao obter o endereço.");
            }
        },

        async buscarEndereco() {
            const cep = this.obra.cep.replace(/\D/g, '');
            if (cep.length === 8) {
                try {
                    const token = this.$store.state.token;
                    await this.$store.dispatch('fetchURLrequest');
                    const response = await axios.get(`cep/${cep}`, {
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
                    if (!response.data.erro) {
                        this.obra.endereco = response.data.logradouro;
                        this.obra.bairro = response.data.bairro;
                        this.obra.cidade = response.data.localidade;
                        this.obra.estado = response.data.uf;
                        this.gerarLinkGoogleMaps();  // Gera o link do Google Maps após buscar o endereço
                    } else {
                        this.exibirErro('Ocorreu um erro ao buscar CEP.');
                    }
                } catch (error) {
                    this.exibirErro('Ocorreu um erro ao buscar CEP.');
                }
            } else {
                this.exibirErro('Ocorreu um erro ao buscar CEP.');
            }
        },

        gerarLinkGoogleMaps() {
            // Verifica se todos os campos estão preenchidos para gerar o link
            console.log(this.enderecoPreenchido)
            if (this.enderecoPreenchido) {
                // Gera o link com o número incluído
                this.obra.localizacao = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.enderecoCompleto)}`;
            }
        },

        exibirErro(mensagem) {
            this.erro = true;
            this.erroMessage = mensagem;
            setTimeout(() => {
                this.erro = false;
                this.erroMessage = '';
            }, 3000);
        }
    }
};
</script>

<style scoped>
.map-container {
    width: 100%;
    height: 400px;
    /* Defina uma altura específica para o mapa */
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.etapas-navegacao {
    display: flex;
    justify-content: center;
    overflow-x: auto;
}

.etapas-navegacao button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    margin: 20px;
}

.etapas-navegacao .ativo {
    background-color: #1f2937;
    color: white;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    /* font-weight: bold; */
}

input,
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.link-maps {
    @apply text-blue-500;
}
</style>
