<template>
  <div class="container-formulario">
    <!-- Cabeçalho -->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Tabelas de Perfil</h1>
      </div>

      <router-link to="/precificacao" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Filtros e Ações -->
    <div class="flex gap-4 mb-6 items-end w-[100%]">
      <div class="w-full">
        <label class="text-corBase">Fornecedor</label>
        <v-select v-model="selectedFornecedor" :options="listaFornecedores" label="nome" class="w-full z-10"
          :reduce="fornecedor => fornecedor.id">
          <template #no-options="{ loading }">
            <span v-if="!loading">
              Nenhum fornecedor encontrado.
            </span>
            <span v-else>
              Carregando opções...
            </span>
          </template>
        </v-select>
      </div>
      <div>
        <button class="btn-add-fisica" @click="getPerfis(1)">Pesquisar</button>
      </div>
      <div>
        <button @click="$router.push('/criar-tabela-perfil')" class="w-32 bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-1 rounded-sm px-4">Nova Tabela
        </button>
      </div>
    </div>

    <!-- Tabela de Perfil -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr class="border-b">
            <th class="py-2 px-4 bg-gray-200 text-left">Ações</th>
            <th class="py-2 px-4 bg-gray-200 text-left">Nome da Tabela</th>
            <th class="py-2 px-4 bg-gray-200 text-left">Data de Criação</th>
            <th class="py-2 px-4 bg-gray-200 text-left">Margem Lucro ML (%)</th>
            <th class="py-2 px-4 bg-gray-200 text-left">Margem Lucro Balcão (%)</th>
            <th class="py-2 px-4 bg-gray-200 text-left">Desconto (%)</th>
            <th class="py-2 px-4 bg-gray-200 text-left">Fornecedor</th>
            <th class="py-2 px-4 bg-gray-200 text-center">Padrão</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="perfil in perfisFiltrados" :key="perfil.id" class="hover:bg-gray-100 border-b">
            <td class="py-2 px-4 flex gap-2">
              <button @click="deletePerfil(perfil.id)" class="text-gray-500" title="Excluir Perfil">
                <i class="fas fa-times"></i>
              </button>
              <router-link :to="`/alterar-perfil/${perfil.id}`" class="text-gray-500" title="Alterar Tabela de Perfil">
                <i class="fas fa-paperclip"></i>
              </router-link>
              <button @click="duplicatePerfil(perfil.id)" class="text-gray-500" title="Duplicar Perfil">
                <i class="fas fa-clone"></i>
              </button>
            </td>
            <td class="py-2 px-4">{{ perfil.nome }}</td>
            <td class="py-2 px-4">{{ formatarDataProdutos(perfil.criado_em) }}</td>
            <td class="py-2 px-4">{{ perfil.margem_lucro_ml }}%</td>
            <td class="py-2 px-4">{{ perfil.margem_lucro_balcao }}%</td>
            <td class="py-2 px-4">{{ perfil.desconto }}%</td>
            <td class="py-2 px-4">
              {{ perfil.fornecedor?.pessoa.nome_razao || 'Sem fornecedor definido' }}
            </td>
            <td class="py-2 px-4 text-center">
              <input type="checkbox" v-model="perfil.is_padrao" @change="togglePadrao(perfil.id)" />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Botões da paginação -->
      <Pagination v-if="perfisFiltrados.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

  <SucessoRapido v-if="sucesso === true" mensagem="Alteração feita com sucesso." />
</template>

<script>
import formatarData from '@/utils/FormatarData';
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import axios from 'axios';
import Select2 from "vue3-select2-component";
import vSelect from 'vue-select';
import Pagination from '@/utils/Pagination.vue';

export default {
  name: "PerfilConfig",
  components: {
    Select2,
    vSelect,
    Pagination,
    SucessoRapido,
  },
  data() {
    return {
      selectedFornecedor: '',
      listaFornecedores: [],
      perfisFiltrados: [],
      countItens: 0,
      paginaAtual: 1,
      sucesso: false
    };
  },
  created() {
    this.getPerfis(1);
    this.getFornecedores()
  },
  methods: {

    async getFornecedores() {
      const token = this.$store.state.token;
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get('pessoas/fornecedor/?tipo_fornecedor_nome=Perfil&ativo=True&page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        // console.log(response.data.results)
        this.listaFornecedores = response.data.results.map(fornecedor => ({
          id: fornecedor.id,
          nome: fornecedor.pessoa.nome_razao
        }));
      } catch (error) {
        console.error(error)
      }
    },

    // Função para obter a lista de Perfis do back-end
    async getPerfis(pagina) {
      const token = this.$store.state.token;
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`precificacao/tabelas-perfil/?fornecedor=${this.selectedFornecedor || ''}&page=${pagina}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.perfisFiltrados = response.data.results;
        this.countItens = response.data.count
        console.log(this.perfisFiltrados);
      } catch (error) {
        console.error('Erro ao buscar tabelas de Perfil:', error);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getPerfis(pagina);
    },

    // Função para deletar Perfil (desativar)
    async deletePerfil(id) {
      const token = this.$store.state.token;
      await this.$store.dispatch('fetchURLrequest');
      try {
        await axios.patch(`precificacao/tabelas-perfil/${id}/`, { ativo: 'False' }, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.getPerfis(1);
        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
        }, 3000);
      } catch (error) {
        console.error('Erro ao desativar Perfil:', error);
      }
    },

    // Função para duplicar Perfil
    async duplicatePerfil(id) {
      const token = this.$store.state.token;
      await this.$store.dispatch('fetchURLrequest');
      try {
        const perfil = this.perfisFiltrados.find(v => v.id === id);
        const novoPerfil = { ...perfil, id: undefined }; // Cria uma cópia sem o ID
        const token = this.$store.state.token;
        await axios.post('precificacao/tabelas-perfil/', novoPerfil, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.getPerfis(1);
        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
        }, 3000);
      } catch (error) {
        console.error('Erro ao duplicar Perfil:', error);
      }
    },

    // Função para alternar o status de "Padrão"
    async togglePadrao(id) {
      const token = this.$store.state.token;
      await this.$store.dispatch('fetchURLrequest');
      try {
        const perfil = this.perfisFiltrados.find(v => v.id === id);
        perfil.is_padrao = !perfil.is_padrao;
        await axios.put(`precificacao/tabelas-perfil/${id}/`, perfil, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
      } catch (error) {
        console.error('Erro ao alterar padrão do Perfil:', error);
      }
    },

    formatarDataProdutos(dataISO) {
      return formatarData(dataISO)
    },

  }
};
</script>

<style scoped>
.container-cards-produtos {
  @apply flex flex-wrap px-3 py-3;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
}

thead th {
  background-color: #f8f9fa;
}

tr:hover {
  background-color: #f1f1f1;
}
</style>
