<template>
    <!-- Modal -->
    <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-[25000]">
        <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl max-h-screen overflow-y-auto h-auto">
            <!-- Cabeçalho do modal -->
            <div class="cabecalho-formulario flex justify-between mb-4">
                <h1 class="text-xl text-corBase font-bold">Dados Pessoais:</h1>

                <!-- Botão para fechar o modal -->
                <button @click.prevent="$emit('fecharModal')" class="text-xl text-red font-bold">
                    <i class="fas fa-times"></i>
                </button>
            </div>

            <!-- Conteúdo do modal -->
            <form @submit.prevent="cadastrarPessoa">

                <pessoa-fisica-dados :dadosPessoaFisica="dadosPessoaFisica" />
                <enderecos-campos :dadosEndereco="dadosEndereco" />
                <telefones-campos :dadosTelefone="dadosTelefone" />
                <emails-campos :dadosEmail="dadosEmail" />

                <div class="flex w-full justify-end">
                    <button class="btn-enviar" type="submit">Cadastrar</button>
                </div>
            </form>

            <SucessoRapido v-if="sucesso === true" mensagem="Pessoa criada com sucesso." />
            <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao criar a pessoa." />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import EnderecosCampos from "@/components/campos_cadastro/EnderecosCampos";
import TelefonesCampos from "@/components/campos_cadastro/TelefonesCampos";
import PessoaFisicaDados from "@/components/campos_cadastro/PessoaFisicaDados";
import EmailsCampos from "@/components/campos_cadastro/EmailsCampos";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";

export default {
    name: 'ModalCadastroPessoaFisica',

    components: {
        TelefonesCampos,
        EnderecosCampos,
        PessoaFisicaDados,
        EmailsCampos,
        SucessoRapido,
        ErroRapido
    },

    data() {
        return {
            dadosPessoaFisica: {
                nome_razao: '',
                tipo_pessoa_geral: '',
                cpf: '',
                rg: '',
                orgao_expeditor: '',
                nascimento: ''
            },
            dadosEndereco: [{
                logradouro: '',
                numero: '',
                complemento: '',
                cep: '',
                bairro: '',
                cidade: '',
                uf: '',
                referencia: '',
                tipo_endereco: ''
            }],
            dadosTelefone: [{
                ddd: '',
                telefone: '',
                tipo_telefone: ''
            }],
            dadosEmail: [{
                email_pessoa: '',
                tipo_email: ''
            }],
            sucesso: false,
            erro: false,
            errors: []
            
        };
    },

    emits: ['fecharModal', 'atualizarLista'],

    methods: {
        async cadastrarPessoa() {
            // console.log('chegou')
            try {
                const data = {
                    nome_razao: this.dadosPessoaFisica.nome_razao,
                    nome: this.dadosPessoaFisica.nome_razao,
                    cpf: this.dadosPessoaFisica.cpf.replace(/[^0-9]/g, ''),
                    rg: this.dadosPessoaFisica.rg ? this.dadosPessoaFisica.rg.replace(/[^0-9]/g, '') : null,
                    orgao_expeditor: this.dadosPessoaFisica.orgao_expeditor || null,
                    nascimento: this.dadosPessoaFisica.nascimento || null,
                    tipo_pessoa_geral: this.dadosPessoaFisica.tipo_pessoa_geral || null,
                    enderecos: this.dadosEndereco,
                    telefones: this.dadosTelefone,
                    emails: this.dadosEmail
                };

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const response = await axios.post('pessoas/', data, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                });

                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('atualizarLista');
                    this.$emit('fecharModal');
                }, 3000);
            } catch (error) {
                if (error.response) {
                    for (const property in error.response.data) {
                        this.errors.push(`${property}: ${error.response.data[property]}`);
                    }
                } else if (error.message) {
                    this.errors.push('Something went wrong. Please try again!');
                }
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },

    }
};
</script>

<style scoped>
/* estilização para o modal */
.btn-enviar {
    @apply bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2;
}

.cabecalho-formulario {
    @apply flex justify-between mb-2;
}

.container-formulario {
    @apply flex flex-col bg-white p-4 mt-4 rounded-3xl w-full;
}

.input-campos {
    @apply w-full bg-white border-2 border-corBase/5 border-b-corBase/20 p-2 focus:border-b-corBase/60 text-corBase focus:border-b-corBase/80 outline-none focus:transition focus:duration-500;
}

/* Para estilizar a barra de rolagem */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.7);
}
</style>