<template>
    <FormularioContasPagar />
</template>

<script>
import FormularioContasPagar from '@/components/financeiro/ContasAPagar/FormularioContasPagar.vue';

export default {
    name: 'NovaContaAPagar',
    components: {
        FormularioContasPagar
    }
}
</script>