<template>

    <div class="container-formulario">

        <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
        <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase">Dados Método Pagamento:</h1>

            <router-link to="/metodos-pagamento" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Escopo do formulario de cadastro mobile-->
        <form @submit.prevent="cadastrarMetodoPagamento">
            <!--        PARTE DE DADOS DO PRODUTO-->
            <div class="div-produtos">
                <div class="campo-chapa">
                    <label class="font-bold text-corBase">Método</label>
                    <input type="text" v-model="metodo" class="input-form-chapa" required autofocus>
                </div>
            </div>


            <div class="flex w-full justify-end">
                <button class="btn-enviar" type="submit">Cadastrar</button>
            </div>
        </form>

    </div>
    <MensagemSucesso v-if="sucesso == true" rotas="/metodos-pagamento"
        mensagem="Método de Pagamento cadastrado com sucesso!" />

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
    name: "MetodoPagamento",


    data() {
        return {
            sucesso: false,
            metodo: '',
        }
    },

    components: {
        MensagemSucesso
    },

    created() {
    },

    computed: {
        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },

        isadmin() {
            return this.$store.state.isAdminUser
        },
    },

    methods: {


        async cadastrarMetodoPagamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            this.sucesso = false

            const data = new FormData();
            data.append('ativo', 'true')
            data.append('metodo', this.metodo)

            try {
                const response = await axios.post('financeiro/metodos-pagamento/', data, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response.data)
                this.sucesso = true
                this.metodo = ''
            } catch (error) {
                console.error(error)
            }


        },


    }

}
</script>

<style lang="scss"></style>