<template>


  <div class="container-formulario">

    <div class="cabecalho-formulario">
      <h1 class="titulo-cadastro">Cadastro Pessoa Jurídica</h1>

      <router-link to="/pessoa-juridica/lista" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <form @submit.prevent="cadastroPessoaJuridica">

      <pessoa-juridica-campos :dadosPessoaJuridica="dadosPessoaJuridica"/>

      <enderecos-campos :dadosEndereco="dadosEndereco"/>

      <telefones-campos :dadosTelefone="dadosTelefone"/>

      <emails-campos :dadosEmail="dadosEmail"/>

      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>

    </form>

  </div>
  <mensagem-sucesso v-if="sucesso === true" mensagem="Pessoa Juridica Cadastrada com Sucesso!"
                    rotas="/pessoa-juridica/lista"/>

</template>

<script>
import axios from "axios";
import TipoEnderecoModal from "@/components/midais/modaisCadastro/TipoEnderecoModal";
import TipoTelefoneModal from "@/components/midais/modaisCadastro/TipoTelefoneModal";
import SucessoCadastroPessoaJuridica from "@/components/toasts/toast_sucesso/SucessoCadastroPessoaJuridica";
import PessoaFisicaModal from "@/components/midais/modaisCadastro/PessoaFisicaModal";
import EnderecosCampos from "@/components/campos_cadastro/EnderecosCampos";
import TelefonesCampos from "@/components/campos_cadastro/TelefonesCampos";
import PessoaJuridicaCampos from "@/components/campos_cadastro/PessoaJuridicaCampos";
import EmailsCampos from "@/components/campos_cadastro/EmailsCampos";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: 'CadastroPessoaJuridica',
  components: {
    SucessoCadastroPessoaJuridica,
    TipoTelefoneModal,
    TipoEnderecoModal,
    PessoaFisicaModal,
    EnderecosCampos,
    TelefonesCampos,
    PessoaJuridicaCampos,
    EmailsCampos,
    MensagemSucesso
  },

  data() {
    return {
      dadosPessoaJuridica: {
        cnpj: '',
        razao_social: '',
        nome_fantasia: '',
        responsavel: {},
        iE: '',
        suframa: '',
        situacao_fiscal: ''
      },

      dadosEndereco: [
        {
          logradouro: '',
          numero: '',
          complemento: '',
          cep: '',
          bairro: '',
          cidade: '',
          uf: '',
          referencia: '',
          tipo_endereco: '',
        }
      ],

      dadosTelefone: [
        {
          ddd: '',
          telefone: '',
          tipo_telefone: '',
        }
      ],

      dadosEmail: [{
        email_pessoa: '',
        tipo_email: '',
      }],

      sucesso: false,
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    async cadastroPessoaJuridica() {

      try {

        const data = {
          nome_razao: this.dadosPessoaJuridica.razao_social,
          nome_fantasia: this.dadosPessoaJuridica.nome_fantasia,
          cnpj: this.dadosPessoaJuridica.cnpj.replace(/[^0-9]/g, ''),
          iE: this.dadosPessoaJuridica.iE,
          suframa: this.dadosPessoaJuridica.suframa,
          responsavel: {
            id: this.dadosPessoaJuridica.responsavel.id,
            tipo_pessoa_geral: this.dadosPessoaJuridica.responsavel.tipo_pessoa_geral_id,
          },
          situacao_fiscal: this.dadosPessoaJuridica.situacao_fiscal,
          enderecos: this.dadosEndereco,
          telefones: this.dadosTelefone,
          emails: this.dadosEmail
        };

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.post('pessoas/', data, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response)
        this.sucesso = true
      } catch (error) {
        console.error(error)
      }
    },


  }

}
</script>

<style lang="scss">

</style>


