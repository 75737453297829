<template>
    <!-- Formulário responsivo -->
    <div>
        <!-- Escopo do formulário de cadastro -->
        <form @submit.prevent="alterarDadosProjeto" enctype="multipart/form-data">
            <div class="w-full mx-auto mt-8">
                <div class="space-y-4">
                    <!-- Seção 1 - Dados do Projeto -->
                    <div>
                        <div class="p-4 flex flex-col gap-4 border border-corBase/60">
                            <!-- Linha 1 -->
                            <div class="flex flex-col gap-4 md:flex-row pb-4">
                                <div class="w-full">
                                    <label class="font-bold text-corBase">Sistemista</label>
                                    <v-select v-model="sistemista" :options="lista_sistemistas" label="text"
                                        class="bg-white" @option:selected="limparLinhaSistema()">
                                    </v-select>
                                </div>
                                <div class="w-full">
                                    <label class="font-bold text-corBase">Linha/Sistema</label>
                                    <v-select v-model="linha" :options="lista_linhas" label="text"
                                        class="input-disabled bg-white" :disabled="!sistemista">
                                    </v-select>
                                </div>
                                <div class="w-full">
                                    <label class="font-bold text-corBase">Modelo</label>
                                    <v-select v-model="modelo" :options="lista_modelos" label="text" class="bg-white"
                                        @option:selected="limparTipologia()">
                                    </v-select>
                                </div>
                            </div>

                            <!-- Linha 2 -->
                            <div class="flex gap-5 pb-4">

                                <div class="w-2/3">
                                    <label class="font-bold text-corBase">Tipologia</label>
                                    <v-select v-model="tipologia" :options="lista_tipologias" label="text"
                                        :reduce="(option) => option.id" class="input-disabled bg-white"
                                        :disabled="!modelo"></v-select>
                                </div>

                                <div class="w-1/3 flex gap-5">
                                    <!-- Status -->
                                    <div class="w-2/3">
                                        <label class="font-bold text-corBase">Status</label>
                                        <v-select v-model="status_projeto" class="w-full z-15 bg-white"
                                            :options="lista_status" :settings="{ width: '100%' }"
                                            label="text"></v-select>
                                    </div>

                                    <div class="w-1/3 text-center flex flex-col justify-center items-center">
                                        <label class="font-bold text-corBase cursor-pointer"
                                            @click.prevent="togglePrivado()">
                                            <div class="flex flex-col items-center px-2 py-1">
                                                <!-- Ícone do cadeado -->
                                                <i
                                                    :class="`fas ${privado ? 'fa-lock' : 'fa-unlock'} fa-xl text-corBase`"></i>
                                                <!-- Texto abaixo do ícone -->
                                                <p class="mt-2">{{ privado ? 'Privado' : 'Público' }}</p>
                                            </div>
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <!-- Linha 3 -->
                            <div class="flex gap-5 pb-4">

                                <div class="w-1/3">
                                    <label class="font-bold text-corBase">Cod. Projeto</label>
                                    <input type="text" class="input-campos" v-model="cod_projeto">
                                </div>

                                <div class="w-2/3">
                                    <label class="font-bold text-corBase">Descrição</label>
                                    <input type="text" maxlength="90" class="input-campos" v-model="descricao_fixa">
                                </div>

                                <div class="w-1/4">
                                    <label class="font-bold text-corBase">Unidade</label>
                                    <v-select v-model="unidade" class="w-full z-15 bg-white"
                                        :options="lista_unidades_medida" :settings="{ width: '100%' }"
                                        label="text"></v-select>
                                </div>

                            </div>
                            <!-- Linha 4 -->
                            <div class="flex flex-col pb-4">
                                <div class="w-full">
                                    <label class="font-bold text-corBase">Descrição Detalhada</label>
                                    <input type="text" class="input-campos" v-model="descricao_detalhada">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Botão de Salvar -->
            <div class="flex w-full gap-4 justify-end mt-4">
                <button class="btn-enviar" type="submit">Salvar</button>
            </div>
        </form>
    </div>
    <!-- Mensagem de sucesso -->
    <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso" />
    <listagem-erros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
</template>



<script>
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";
import Search from "@/utils/Search.vue";

export default {
    name: 'ProjetoDadosComponent',

    components: {
        MensagemSucesso,
        SucessoRapido,
        ListagemErros,
        vSelect,
        Search
    },

    data() {
        return {
            projeto_id: '',
            // variaveis dos formularios
            sistemista: '',
            linha: '',
            modelo: '',

            tipologia: '',
            privado: false,
            status_projeto: '',

            unidade: '',

            cod_projeto: '',
            descricao_fixa: '',

            descricao_detalhada: '',

            // viaveis para lista
            lista_sistemistas: [],
            lista_linhas: [],
            lista_modelos: [],

            lista_tipologias: [],
            lista_status: [],

            lista_unidades_medida: [],

            sucesso: false,

            exibirErros: false,
            erros: {}

        }
    },

    props: ['dadosProjeto'],

    created() {
        this.projeto_id = this.$route.params.id
        this.getListaSistemistas()
        this.getListaModelos()
        this.getListaStatus()
        this.getListaUnidadeMedida()
        this.defineVariaveisProjeto()
    },

    computed: {
        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },
    },

    watch: {
        sucesso() {
            if (this.sucesso) {
                setTimeout(() => {
                    this.sucesso = false
                }, 2000)
            }
        },

        sistemista(newValue) {
            if (newValue) {
                this.getListaLinhas(newValue)
            }

            if (!newValue) {
                this.linha = ''
                this.lista_linhas = []
            }
        },

        'modelo': {
            handler(novoModelo) {
                if (novoModelo) {
                    this.getListaTipologia(novoModelo);
                }
            },
            immediate: false
        },

        status_projeto(newValue) {
            if (newValue.text === 'Desenvolvimento' || newValue.text === 'Manutenção') {
                this.privado = true
            }
        }

    },

    methods: {

        limparLinhaSistema() {
            this.linha = ''
        },

        limparTipologia() {
            this.tipologia = ''
        },

        defineVariaveisProjeto() {
            this.cod_projeto = this.dadosProjeto.codigo
            this.descricao_fixa = this.dadosProjeto.descricao_fixa
            this.descricao_detalhada = this.dadosProjeto.descricao_detalhada

            this.modelo = {
                id: this.dadosProjeto.modelo_variacao?.id || '',
                text: this.dadosProjeto.modelo_variacao?.descricao || ''
            }

            this.sistemista = {
                id: this.dadosProjeto.sistemista?.id || '',
                text: this.dadosProjeto.sistemista?.nome || ''
            }

            this.tipologia = {
                id: this.dadosProjeto.tipologia?.id || '',
                text: this.dadosProjeto.tipologia?.nome || ''
            }

            this.linha = {
                id: this.dadosProjeto.linha_sistema?.id || '',
                text: this.dadosProjeto.linha_sistema?.linha || ''
            }

            this.status_projeto = {
                id: this.dadosProjeto.status_projeto?.id || '',
                text: this.dadosProjeto.status_projeto?.descricao || ''
            }

            this.unidade = {
                id: this.dadosProjeto.unidade?.id || '',
                text: this.dadosProjeto.unidade?.nome || ''
            }
        },

        togglePrivado() {
            if (this.status_projeto.text === 'Desenvolvimento' || this.status_projeto.text === 'Manutenção') {
                this.privado = true
            } else {
                this.privado = !this.privado
            }
        },

        async alterarDadosProjeto() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                console.log(this.sistemista)

                const updatedData = {
                    codigo: this.cod_projeto,
                    descricao_fixa: this.descricao_fixa,
                    descricao_detalhada: this.descricao_detalhada,
                    sistemista: this.sistemista?.id || '',
                    modelo_variacao: this.modelo?.id || '',
                    tipologia: this.tipologia?.id || '',
                    status_projeto: this.status_projeto?.id || '',
                    linha_sistema: this.linha?.id || ''
                }

                await axios.patch(`projeto/projeto/${this.dadosProjeto.id}/`, updatedData, {
                    headers: {
                        'Authorization': `Token ${token}`
                    },
                })
                this.sucesso = true
            } catch (error) {
                console.error(error)
                if (error.response && error.response.data) {
                    if (typeof error.response.data === 'string' && error.response.data.includes('<!DOCTYPE html>')) {
                        this.erros = {
                            geral: ["Já existe um projeto com este Sistemista, Linha/Sistema, Modelo e Tipologia."],
                        };
                    } else if (Array.isArray(error.response.data)) {
                        this.erros = { detalhes: error.response.data };
                    } else if (typeof error.response.data === 'object') {
                        this.erros = error.response.data;
                    } else {
                        this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
                    }
                } else {
                    this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
                }
                this.toggleErros();
                this.$store.dispatch("setLoading", false);

            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },


        // get das listas usadas no projeto

        async getListaSistemistas() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get('projeto/projetista-sistemista/?page_size=999', {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.lista_sistemistas = response.data.results.map(item => ({
                        id: item.id,
                        text: item.nome
                    }))
                })
                .catch(error => {
                    console.error(error)
                })

        },

        async getListaModelos() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get('projeto/modelo-variacao/?page_size=999', {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.lista_modelos = response.data.results.map(item => ({
                        id: item.id,
                        text: item.descricao
                    }));

                    console.log('MODELOS VARIAÇÃO')
                    console.log(this.lista_modelos)
                    console.log('-------------------')
                })
                .catch(error => {
                    console.error(error)
                })
        },

        async getListaTipologia(modelo) {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get(`projeto/tipologia/?modelo=${modelo.id}`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.lista_tipologias = response.data.results.map(item => ({
                        id: item.id,
                        text: item.nome
                    }))
                })
                .catch(error => {
                    console.error(error)
                })

        },

        async getListaLinhas(sistemistaId) {
            // console.log('---------------')
            // console.log(sistemistaId)
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get(`projeto/linha-sistema/?projetista_sistemista_id=${sistemistaId.id}&page_size=999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.lista_linhas = response.data.results.map(item => ({
                        id: item.id,
                        text: item.linha
                    }));
                })
                .catch(error => {
                    console.error(error)
                })
        },


        async getListaStatus() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get('projeto/status-projeto', {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.lista_status = response.data.results.map(item => ({
                        id: item.id,
                        text: item.descricao
                    }));
                })
                .catch(error => {
                    console.error(error)
                })
        },

        async getListaUnidadeMedida() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get('produtos/unidade-medida/?page_size=999', {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results)
                    this.lista_unidades_medida = response.data.results.map(item => ({
                        id: item.id,
                        text: item.nome
                    }))
                })
                .catch(error => {
                    console.error(error)
                })

        },

    }

}

</script>


<style lang="scss" scoped>
.input-disabled {
    position: relative;
    transition: opacity 0.3s ease, cursor 0.3s ease;

    &:disabled {
        opacity: 0.6; // Deixa o botão opaco
        cursor: not-allowed; // Muda o cursor para bloqueado

        // Estilização para a mensagem flutuante
        &::after {
            content: 'Selecione um sistemista'; // Mensagem flutuante
            position: absolute;
            top: -35px; // Posição acima do botão
            left: -10%;
            transform: translateX(-50%);
            background-color: rgba(0, 0, 0, 0.75); // Fundo da mensagem
            color: white; // Cor da mensagem
            padding: 5px 10px; // Espaçamento interno
            border-radius: 4px; // Bordas arredondadas
            font-size: 12px; // Tamanho da fonte
            white-space: nowrap;
            opacity: 0; // Inicialmente invisível
            transition: opacity 0.3s ease;
            pointer-events: none; // Previne que a mensagem receba cliques
        }

        &:hover::after {
            opacity: 1; // Torna a mensagem visível ao passar o mouse
        }
    }
}
</style>