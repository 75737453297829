<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white" @submit.prevent="putDadosEditado">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Linha</h2>

            <div>
              <div class="md:flex md:gap-12">
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Código:</label>
                  <input type="text" required class="input-campos text-base" v-model="linhaSistema.codigo">
                </div>
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Linha:</label>
                  <input type="text" required class="input-campos text-base" v-model="linhaSistema.linha">
                </div>
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Bitola:</label>
                  <input type="number" class="input-campos text-base" v-model="linhaSistema.bitola">
                </div>
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Sistemista:</label>
                  <!-- <Select2 v-model="linhaSistema.projetistasistemistas.id"
                           class="w-full"
                           :options="projetistas_sistemistas"
                           :settings="{ width: '100%'}"
                  /> -->
                  <v-select v-model="linhaSistema.projetistasistemistas" :options="projetistas_sistemistas" label="nome" class="bg-white" />
                </div>

                <div class="flex flex-col mt-2 w-full justify-start ">
                  <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                  <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                    <i v-if="linhaSistema.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                    <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                  </span>
                </div>


              </div>

            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" type="submit">
                Salvar
              </button>
            </div>
          </form>
        </div>
        <SucessoRapido v-if="sucesso === true" mensagem="Linha atualizada com sucesso!" />
        <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Linha."/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import vSelect from "vue-select";

export default {
  name: "EditarModeloVariacao",
  components: {
    vSelect,
    SucessoRapido,
    ErroRapido
  },
  data() {
    return {

      projetistas_sistemistas: [],

      sucesso: false,
      erro: false,

    }
  },
  props: ["linhaSistema"],
  emits: ["fecharModal", "atualizarLista"],

  created() {
    this.getListaProjetistaSistemista()
    // console.log(this.linhaSistema)

  },

  methods: {
    toggleAtivo(event) {
      event.preventDefault()
      this.linhaSistema.ativo = !this.linhaSistema.ativo
    },

    async putDadosEditado() {

      const dadosAtualizados = {
        id: this.linhaSistema.id,
        ativo: this.linhaSistema.ativo,
        codigo: this.linhaSistema.codigo,
        linha: this.linhaSistema.linha,
        bitola: this.linhaSistema.bitola,
        projetistasistemistas: this.linhaSistema.projetistasistemistas?.id
      }

      // console.log(this.linhaSistema.projetistasistemistas)


      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`projeto/linha-sistema/${this.linhaSistema.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response);

        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);
        

      } catch (error) {
        console.error(error)
        this.erro = true
        setTimeout(() => {
          this.erro = false;
        }, 3000);
      }
    },

    async getListaProjetistaSistemista() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/projetista-sistemista/?ativo=True&page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.projetistas_sistemistas = response.data.results
            // console.log(response.data.results)
          })
          .catch(error => {
            console.error(error)
          })
    }

  }
}
</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%]  shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
  @apply text-corBase
}
</style>