<template>
    <div>
        <div class="container-modal-editar">
            <div class="sombra-modal">
                <div class="conteudo-modal-editar">
                    <form class="form-editar text-white" @submit.prevent="putDadosEditado">
                        <div class="flex justify-end">
                            <button @click.prevent="$emit('fecharModal')">
                                <i class="fas fa-close text-2xl text-corBase"></i>
                            </button>
                        </div>
                        <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Local Vidro</h2>

                        <div>
                            <div class="md:flex md:gap-12">
                                <div class="flex flex-col mt-2 w-full justify-start">
                                    <label class="text-base text-corBase font-bold mt-2">Descrição:</label>
                                    <input type="text" required class="input-campos text-base"
                                        v-model="local.descricao">
                                </div>


                                <div class="flex flex-col mt-2 w-full justify-start ">
                                    <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                                    <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                                        <i v-if="local.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                                        <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                                    </span>
                                </div>


                            </div>

                        </div>

                        <!--Botão-->
                        <div class="btn-formulario-modal">
                            <button class="btn-formulario-salvar" type="submit">
                                Salvar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <SucessoRapido v-if="sucesso === true" mensagem="Local do Vidro atualizado com sucesso." />
        <ErroRapido v-if="erro === true" mensagem="Local do Vidro com este nome já existe." />
    </div>
</template>

<script>
import axios from "axios";
// import ToastError from "@/components/toasts/toast_erro/ToastError";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
    name: "EditarLocalVidro",
    components: {
        ErroRapido,
        SucessoRapido
    },
    data() {
        return {
            sucesso: false,
            erro: false,

        }
    },
    props: ["local"],
    emits: ["fecharModal", "atualizarLista"],


    methods: {
        toggleAtivo() {
            this.local.ativo = !this.local.ativo
        },

        async putDadosEditado() {
            const dadosAtualizados = new FormData();
            dadosAtualizados.append('ativo', this.local.ativo)
            dadosAtualizados.append('descricao', this.local.descricao)

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest')
                const response = await axios.patch(`projeto/area-vidro/${this.local.id}/`, dadosAtualizados, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                });
                console.log(response);
                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal')
                    this.$emit('atualizarLista')
                }, 3000);

            } catch (error) {
                console.error(error)
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },


    }
}
</script>

<style lang="scss"></style>