<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Vãos da Obra</h1>
            </div>

            <router-link to="/obras" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Filtros e Ações -->
        <div class="flex flex-wrap items-end gap-4 mb-6 w-full">
            <!-- Filtro Local -->
            <div class="w-full md:flex-1">
                <label class="text-corBase">Local</label>
                <v-select v-model="localFilter" :options="listaLocaisVao" label="nome"
                    :reduce="localFilter => localFilter.id" class="w-full" />
            </div>

            <!-- Filtro Código Arquitetônico -->
            <div class="w-full md:flex-1">
                <label class="text-corBase">Código Arquitetônico</label>
                <input v-model="codigoFilter" class="w-full z-10" type="text">
            </div>

            <!-- Botões -->
            <div class="w-full md:w-auto flex gap-4 md:ml-auto mt-4 md:mt-0">
                <button class="btn-add-fisica" @click="getVaos(1)">Pesquisar</button>
                <button @click="$router.push(`/criar-vao/${this.$route.params.id}`)"
                    class="btn-salvar whitespace-nowrap">
                    Novo Vão
                </button>
            </div>
        </div>



        <!-- Listagem dos itens da obra -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Foto</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Descrição</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Código Arquitetônico</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Local</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Altura (MM)</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Largura (MM)</th>
                        <th class="py-2 px-4 bg-gray-200 border-b"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="vao in listaVaos" :key="vao.id">
                        <tr class="escopo-tabela">
                            <td class="py-2 px-4 flex gap-2">
                                <button @click="deleteVao(vao.id)" class="text-gray-500" title="Excluir Vão">
                                    <i class="fas fa-times"></i>
                                </button>
                                <button @click="$router.push(`/editar-vao/${$route.params.id}/${vao.id}`)"
                                    class="text-gray-500" title="Editar Vão">
                                    <i class="fas fa-paperclip"></i>
                                </button>
                            </td>
                            <td class="py-2 px-4 border-b">
                                <!-- Verifica se o vão tem uma imagem -->
                                <img v-if="vao.imagem" :src="vao.imagem" alt="Foto do Vão" class="foto-vao"
                                    @click.prevent="openPreviewImage(vao.imagem)" />

                                <!-- Se não houver imagem, exibe uma mensagem alternativa -->
                                <img v-else class="foto-vao"
                                    src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                    alt="Sem foto">
                            </td>
                            <td class="py-2 px-4 border-b">{{ vao.descricao }}</td>
                            <td class="py-2 px-4 border-b">{{ vao.codigo_arquitetonico || 'S/ código' }}</td>
                            <td class="py-2 px-4 border-b">{{ vao.local_vao?.nome || 'S/ local' }}</td>
                            <td class="py-2 px-4 border-b">{{ vao.altura }}</td>
                            <td class="py-2 px-4 border-b">{{ vao.largura }}</td>
                            <td class="py-2 px-4 border-b">
                                <button @click="selecionarVao(vao)" class="text-gray-500" title="Abrir Projetos do Vão">
                                    <i
                                        :class="vaosSelecionados.some(v => v.id === vao.id) ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
                                </button>
                            </td>
                        </tr>

                        <!-- Detalhes dos Projetos e Linhas relacionadas -->
                        <tr :id="`vao-${vao.id}`" v-if="vaosSelecionados.some(v => v.id === vao.id)"
                            class="bg-gray-200">
                            <td colspan="8" class="px-4 py-2">
                                <div>
                                    <ul v-if="vao.detalhes && vao.detalhes.length > 0"
                                        class="flex flex-wrap justify-center gap-6">

                                        <!-- Itera sobre cada detalhe de `vao.detalhes` -->
                                        <li v-for="(detalhe, index) in vao.detalhes" :key="index"
                                            class="w-full sm:w-1/2 lg:w-1/4 p-4 bg-white border border-corBase/40 shadow-md rounded-md flex flex-col items-center">

                                            <!-- {{ detalhe }} -->
                                            <!-- Verifica e exibe o projeto -->
                                            <div v-if="detalhe.projeto && detalhe.projeto.length > 0"
                                                class="text-center items-center justify-center mb-4">
                                                <input type="radio" v-model="vao.selectedProjetoIndex" :value="index"
                                                    class="mb-2">
                                                <div class="items-center justify-center flex">
                                                    <img src="@/assets/imagens/fotos/janela2folhas.png"
                                                        alt="Imagem do Projeto"
                                                        class="w-32 h-32 object-cover rounded mb-2">
                                                </div>

                                                <div v-for="(proj, i) in detalhe.projeto" :key="i">
                                                    <strong class="block text-base font-semibold">Projeto:</strong>
                                                    <span class="text-gray-700 whitespace-nowrap text-sm">{{
                                                        proj.descricao_fixa }}</span>
                                                </div>
                                            </div>

                                            <!-- Verifica e exibe as tipologias associadas -->
                                            <div v-if="detalhe.tipologias && detalhe.tipologias.length > 0 && detalhe.projeto.length == 0"
                                                class="text-center mt-2">
                                                <span v-for="(tipologia, i) in detalhe.tipologias" :key="i"
                                                    class="text-gray-700 whitespace-nowrap text-sm">
                                                    <div class="items-center justify-center flex">
                                                        <img :src="tipologia.imagem.url_documento"
                                                            alt="Imagem da Tipologia"
                                                            class="w-32 h-32 object-cover rounded mb-2">
                                                    </div>
                                                    <strong class="block text-base font-semibold ">Tipologia:</strong>
                                                    <span>{{ tipologia.nome }}</span>
                                                    <span v-if="i < detalhe.tipologias.length - 1">,

                                                    </span>
                                                </span>
                                            </div>

                                            <!-- Verifica e exibe as linhas associadas -->
                                            <div v-if="detalhe.linhas && detalhe.linhas.length > 0"
                                                class="text-center mt-2">
                                                <strong class="block text-base font-semibold">Linha:</strong>
                                                <span v-for="(linha, i) in detalhe.linhas" :key="i"
                                                    class="text-gray-700 whitespace-nowrap text-sm">
                                                    {{ linha.linha }}<span v-if="i < detalhe.linhas.length - 1">,
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>

                                    <!-- Mensagem se não houver projetos nem tipologias -->
                                    <p v-else class="text-center text-gray-500">Sem projetos ou tipologias cadastrados
                                        para este vão.</p>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <Pagination v-if="listaVaos.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
        </div>

        <div class="flex justify-end mt-4">
            <button class="btn-salvar" @click.prevent="gerarOrcamento()">Gerar Orçamento</button>
        </div>
    </div>

    <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
    <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
    <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
    <!-- <ModalVariaveisOrcamento v-if="mostrarModalVariaveis" :id="idOrcamento"
        @fecharModalVariaveis="fecharModalVariaveis" /> -->
</template>


<script>
import axios from 'axios';
import Pagination from '@/utils/Pagination.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';
import vSelect from 'vue-select';
// import ModalVariaveisOrcamento from '@/components/cadastros_orcamentos_componentes/ModalVariaveisOrcamento.vue';

export default {
    name: "ListarVaos",

    data() {
        return {
            listaVaos: [],
            vaosSelecionados: [],
            linhasProjetoVao: [],
            listaLocaisVao: [],

            projetos: [],
            linhas: [],

            preview_imagem: null,

            paginaAtual: 1,
            countItens: '',

            sucesso: false,
            sucessoMessage: '',
            erro: false,
            erroMessage: '',

            codigoFilter: '',
            localFilter: '',

            idOrcamento: '',
            mostrarModalVariaveis: false,
        };
    },

    components: {
        Pagination,
        SucessoRapido,
        ErroRapido,
        PreviewImage,
        vSelect,
        // ModalVariaveisOrcamento
    },

    created() {
        // this.getVaos(1);
        this.getLocaisVao()
    },

    watch: {
        vaosSelecionados: {
            deep: true,
            handler(newVal) {
                newVal.forEach((vao, index) => {
                    if (vao.selectedProjetoIndex !== null) {
                        // Determina o índice do próximo vão
                        const currentIndex = this.listaVaos.findIndex(v => v.id === vao.id);
                        const nextIndex = currentIndex + 1;

                        // Verifica se o próximo índice está dentro dos limites da lista de vãos
                        if (nextIndex < this.listaVaos.length) {
                            const nextVao = this.listaVaos[nextIndex];
                            const isAlreadySelected = this.vaosSelecionados.some(v => v.id === nextVao.id);

                            // Se o próximo vão não estiver selecionado, apenas abre o próximo
                            if (!isAlreadySelected) {
                                this.selecionarVao(nextVao);

                                // Aguardar a abertura do próximo vão e rolar suavemente até ele
                                this.$nextTick(() => {
                                    const vaoElement = document.getElementById(`vao-${nextVao.id}`);
                                    if (vaoElement) {
                                        vaoElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                    }
                                });
                            }
                        }
                    }
                });
            }
        }
    },

    methods: {

        // fecharModalVariaveis() {
        //     this.mostrarModalVariaveis = false;
        // },

        // Buscar lista de locais do vão
        async getLocaisVao() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`obra/local-vao/?ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                // console.log(response.data.results)
                this.listaLocaisVao = response.data.results
            } catch (error) {
                console.error(error);
            }
        },

        // Buscar lista de vãos da obra
        async getVaos(pagina) {
            this.vaosSelecionados = []
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(`obra/vao-obra/?obra=${this.$route.params.id}&local_vao=${this.localFilter || ''}&codigo_arquitetonico=${this.codigoFilter}&page=${pagina}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.listaVaos = response.data.results;
                // console.log(this.listaVaos)
                this.countItens = response.data.count;
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Buscar Projetos e Linhas do Vão selecionado
        async getLinhasProjetoVao(vao_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`obra/linhas-projeto-vao-obra/?vao=${vao_id}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                // console.log(response.data.results)

                // Inicializa um array para armazenar cada item de projeto com suas linhas e tipologias
                const linhasProjetoVao = response.data.results.map(item => ({
                    projeto: Array.isArray(item.projeto) ? item.projeto : (item.projeto ? [item.projeto] : []),
                    linhas: Array.isArray(item.linhas) ? item.linhas : (item.linhas ? [item.linhas] : []),
                    tipologias: Array.isArray(item.tipologias) ? item.tipologias : (item.tipologias ? [item.tipologias] : [])
                }));

                console.log(linhasProjetoVao)

                const vaoIndex = this.vaosSelecionados.findIndex(v => v.id === vao_id);

                if (vaoIndex !== -1) {
                    // Atualiza o vão com a estrutura completa de `linhasProjetoVao`
                    this.vaosSelecionados[vaoIndex].detalhes = linhasProjetoVao;
                } else {
                    // Caso o vão não esteja em vaosSelecionados, adiciona-o com a estrutura completa
                    this.vaosSelecionados.push({
                        id: vao_id,
                        detalhes: linhasProjetoVao
                    });
                }

                // console.log(this.vaosSelecionados[vaoIndex]);
            } catch (error) {
                console.error('Erro ao buscar projetos:', error);
            }
        },

        // Deletar vão da lista
        async deleteVao(vao_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                await axios.patch(`obra/vao-obra/${vao_id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.getVaos(1);
            } catch (error) {
                console.error(error);
            }
        },

        async gerarOrcamento() {
            const orcamentoDetalhes = this.vaosSelecionados.map(vao => {
                const projetoSelecionado = vao.detalhes[vao.selectedProjetoIndex];
                console.log(projetoSelecionado)

                // Verifica se existe um projeto selecionado
                if (projetoSelecionado) {
                    return {
                        descricaoVao: vao.descricao,
                        projetoNome: projetoSelecionado.projeto[0].descricao_fixa,
                        projetoId: projetoSelecionado.projeto[0].id,
                        linha: projetoSelecionado.linhas[0].nome,
                        linhaId: projetoSelecionado.linhas[0].id,
                        // item_orcamento: projetoSelecionado.item_orcamento
                    };
                }
            }).filter(item => item !== undefined);

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            // Fazer requisição para cada item do orçamento
            for (const detalhe of orcamentoDetalhes) {
                try {
                    // const response = await axios.get(`engine/ae9251f3-488b-4e6b-ac54-0117e08f22fd/`, {
                    //     headers: {
                    //         Authorization: `Token ${token}`
                    //     }
                    // });

                    // console.log(`Response para projeto ${detalhe.projetoNome}:`, response.data);
                } catch (error) {
                    console.error(`Erro ao gerar orçamento para o projeto ${detalhe.projetoNome}:`, error);
                }
            }

            console.log("Detalhes do Orçamento:", orcamentoDetalhes);
            this.sucesso = true;
            this.sucessoMessage = 'Orçamento gerado com sucesso.';
            setTimeout(() => {
                this.sucesso = false;
                this.sucessoMessage = '';
            }, 3000);
        },

        // Abrir modal quando clicar em um vão
        async selecionarVao(vao) {
            const vaoIndex = this.vaosSelecionados.findIndex(v => v.id === vao.id);

            if (vaoIndex === -1) {
                if (!vao.hasOwnProperty('selectedProjetoIndex')) {
                    vao.selectedProjetoIndex = null;
                }

                this.vaosSelecionados.push(vao);
                await this.getLinhasProjetoVao(vao.id);

                // Rolar suavemente até o próximo vão após adicionar
                this.$nextTick(() => {
                    const vaoElement = document.getElementById(`vao-${vao.id}`);
                    if (vaoElement) {
                        vaoElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });

            } else {
                this.vaosSelecionados.splice(vaoIndex, 1);
            }
        },

        async mostrarVariaveis(orcamentoId) {
            this.idOrcamento = orcamentoId
            this.mostrarModalVariaveis = true
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getVaos(pagina);
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },
    }
}
</script>


<style scoped>
input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.foto-vao {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}
</style>
