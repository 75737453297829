<template>
    <div class="container-formulario">
        <div class="cabecalho-dashboard">
            <h1>Nova Conta a Receber</h1>

            <button @click="$router.push(`/contas-a-receber`)" class="text-2xl">
                <i class="fas fa-chevron-left mr-1 "></i>
            </button>
        </div>

        <form @submit.prevent="salvarConta">
            <!-- Seleção do Cliente -->
            <div class="my-4">
                <label for="cliente" class="block text-gray-700 font-semibold mb-2">Cliente</label>
                <v-select v-model="form.cliente" :options="clientes" label="text" :reduce="cliente => cliente.id"
                    class="w-full" placeholder="Selecione o cliente"></v-select>
            </div>

            <!-- Valor -->
            <div class="mb-4">
                <label for="valor" class="block text-gray-700 font-semibold mb-2">Valor (R$)</label>
                <input v-model.number="form.valor" type="number" id="valor"
                    class="w-full border border-gray-300 rounded-lg px-4 py-2" placeholder="Digite o valor" required />
            </div>

            <!-- Quantidade de Parcelas -->
            <div class="mb-4">
                <label for="parcelas" class="block text-gray-700 font-semibold mb-2">Quantidade de Parcelas</label>
                <input v-model.number="form.parcelas" type="number" id="parcelas"
                    class="w-full border border-gray-300 rounded-lg px-4 py-2" placeholder="Digite o número de parcelas"
                    min="1" required />
            </div>

            <!-- Data da Primeira Parcela -->
            <div class="mb-4">
                <label for="primeira-parcela" class="block text-gray-700 font-semibold mb-2">
                    Data da Primeira Parcela
                </label>
                <input v-model="form.dataPrimeiraParcela" type="date" id="primeira-parcela"
                    class="w-full border border-gray-300 rounded-lg px-4 py-2" required />
                <p class="text-sm text-gray-500 mt-2">
                    Dica: Para evitar buracos, considere usar datas até o dia 28.
                </p>
            </div>

            <!-- Intervalo entre Parcelas -->
            <div class="mb-4">
                <label for="intervalo-parcelas" class="block text-gray-700 font-semibold mb-2">
                    Intervalo entre Parcelas (dias)
                </label>
                <input v-model.number="form.intervaloParcelas" type="number" id="intervalo-parcelas"
                    class="w-full border border-gray-300 rounded-lg px-4 py-2" placeholder="Digite o intervalo em dias"
                    min="1" required />
                <p class="text-sm text-gray-500 mt-2">
                    Dica: O sistema calcula fevereiro com 28 dias e os outros meses com 30, independentemente do ano.
                </p>
            </div>

            <!-- Método de Pagamento -->
            <div class="mb-6">
                <label for="metodo-pagamento" class="block text-gray-700 font-semibold mb-2">Método de Pagamento</label>
                <v-select v-model="form.metodoPagamento" :options="metodosPagamento" label="text"
                    :reduce="metodo => metodo.id" class="w-full"
                    placeholder="Selecione o método de pagamento"></v-select>
            </div>

            <!-- Botões -->
            <div class="flex w-full justify-end itens-center mt-6">
                <button type="button" class="btn-cancelar" @click="$router.push(`/contas-a-receber`)">
                    Cancelar
                </button>
                <button type="submit" class="btn-salvar" :disabled="!podeSalvar">
                    Salvar
                </button>
            </div>
        </form>

        <!-- Tabela de Parcelas -->
        <div v-if="form.valor && form.parcelas && form.dataPrimeiraParcela && form.intervaloParcelas" class="mt-6">
            <h2 class="text-lg font-bold mb-4">Detalhamento das Parcelas</h2>
            <div class="container-modelo-tabela">
                <table class="container-tabela overflow-auto">
                    <thead class="cabecalho-tabela border-b">
                        <tr>
                            <th class="px-4 py-2">Parcela</th>
                            <th class="px-4 py-2">Data</th>
                            <th class="px-4 py-2">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(parcela, index) in parcelasDetalhadas" :key="index" class="border-b">
                            <td class="px-4 py-2">{{ index + 1 }}</td>
                            <td class="px-4 py-2">
                                <input type="date" v-model="parcela.data"
                                    class="w-full border border-gray-300 rounded-lg px-2 py-1" />
                            </td>
                            <td class="px-4 py-2">
                                <input type="number" v-model.number="parcela.valor"
                                    class="w-full border border-gray-300 rounded-lg px-2 py-1" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-red-500 mt-2" v-if="valorRestante !== 0">
                A soma dos valores deve ser igual ao valor total. (Restam: {{ valorRestante }})
            </div>
        </div>
    </div>
    <SucessoRapido v-if="sucesso === true" mensagem="Conta a Receber criada com sucesso!" />
    <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
</template>

<script>
import axios from 'axios'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'FormularioContasReceber',

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido
    },

    data() {
        return {
            form: {
                cliente: null,
                valor: '1000',
                parcelas: '5',
                intervaloParcelas: '',
                dataPrimeiraParcela: '2024-11-18',
                metodoPagamento: "",
            },

            parcelasDetalhadas: [],

            clientes: [],
            metodosPagamento: [],

            sucesso: false,
            erro: false,
            erroMessage: ''
        };
    },

    computed: {

        valorRestante() {
            const somaParcelas = this.parcelasDetalhadas.reduce((total, parcela) => total + parcela.valor, 0);
            return this.form.valor - somaParcelas;
        },

        podeSalvar() {
            return this.valorRestante === 0;
        },

        // parcelasDetalhadas() {
        //     const parcelas = [];
        //     const mesesDias = [];
        //     if (
        //         this.form.valor &&
        //         this.form.parcelas &&
        //         this.form.dataPrimeiraParcela &&
        //         this.form.intervaloParcelas
        //     ) {
        //         const valorParcela = this.form.valor / this.form.parcelas;

        //         const [year, month, day] = this.form.dataPrimeiraParcela.split('-');
        //         let dataAtual = new Date(year, month - 1, day);

        //         // Ajustar o horário para evitar problemas de fuso
        //         dataAtual.setHours(12, 0, 0, 0);

        //         console.log(`Data inicial: ${dataAtual.toLocaleDateString()}`);
        //         console.log(`Valor por parcela: R$ ${valorParcela.toFixed(2)}`);
        //         console.log(`Intervalo entre parcelas: ${this.form.intervaloParcelas} dias`);

        //         for (let i = 0; i < this.form.parcelas; i++) {
        //             const dataParcela = new Date(dataAtual);

        //             // Aplicar a regra de meses com 30 dias e fevereiro com 28
        //             const diasNoMesAtual = this.getDiasNoMes(dataParcela);
        //             const dia = Math.min(dataParcela.getDate(), diasNoMesAtual);
        //             dataParcela.setDate(dia);

        //             console.log(`Mês ${dataParcela.getMonth() + 1}: ${diasNoMesAtual} dias`);

        //             mesesDias.push({
        //                 mes: dataParcela.getMonth() + 1,
        //                 dias: diasNoMesAtual,
        //             });

        //             parcelas.push({
        //                 data: dataParcela.toLocaleDateString(),
        //                 valor: valorParcela,
        //             });

        //             // Incrementar a data manualmente, respeitando a regra de 30 dias por mês
        //             const diasIncremento = this.form.intervaloParcelas;
        //             for (let j = 0; j < diasIncremento; j++) {
        //                 dataAtual.setDate(dataAtual.getDate() + 1);

        //                 // Simular meses com 30 dias e fevereiro com 28
        //                 const diasMesSimulado = this.getDiasNoMes(dataAtual);
        //                 if (dataAtual.getDate() > diasMesSimulado) {
        //                     dataAtual.setDate(1);
        //                     dataAtual.setMonth(dataAtual.getMonth() + 1);
        //                 }
        //             }
        //         }
        //     }
        //     return parcelas;
        // },
    },

    watch: {
        "form.valor": "atualizarParcelas",
        "form.parcelas": "atualizarParcelas",
        "form.dataPrimeiraParcela": "atualizarParcelas",
        "form.intervaloParcelas": "atualizarParcelas",
    },

    created() {
        this.getClientes();
        this.getMetodosPagamento();
    },

    methods: {

        atualizarParcelas() {
            // Atualiza o detalhamento das parcelas ao editar o formulário principal
            if (
                this.form.valor &&
                this.form.parcelas &&
                this.form.dataPrimeiraParcela &&
                this.form.intervaloParcelas
            ) {
                const valorParcela = (this.form.valor / this.form.parcelas).toFixed(2);
                const [year, month, day] = this.form.dataPrimeiraParcela.split("-");
                let dataAtual = new Date(year, month - 1, day);

                // Gera as parcelas com base no intervalo
                this.parcelasDetalhadas = Array.from({ length: this.form.parcelas }, (_, i) => {
                    const dataParcela = new Date(dataAtual);
                    const parcela = {
                        data: dataParcela.toISOString().split("T")[0],
                        valor: parseFloat(valorParcela),
                    };

                    // Incrementa para a próxima parcela
                    dataAtual.setDate(dataAtual.getDate() + this.form.intervaloParcelas);
                    return parcela;
                });
            }
        },

        getDiasNoMes(data) {
            const mes = data.getMonth();
            const dias = mes === 1 ? 28 : 30; // Fevereiro = 28, outros meses = 30
            console.log(`Calculando dias no mês ${mes + 1}: ${dias} dias`);
            return dias;
        },

        async getClientes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoas/fisicas/?ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                // console.log(response.data.results)
                this.clientes = response.data.results.map(pessoa => ({
                    id: pessoa.id,
                    text: pessoa.nome_razao,
                }));
                // console.log(this.clientes)
            } catch (error) {
                console.error(error)
            }
        },

        async getMetodosPagamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`financeiro/metodos-pagamento/?ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response.data)
                this.metodosPagamento = response.data.results.map(metodo => ({
                    id: metodo.id,
                    text: metodo.metodo,
                }));
            } catch (error) {
                console.error(error)
            }
        },

        async salvarConta() {

            if (this.valorRestante !== 0) {
                this.erro = true;
                this.erroMessage = 'A soma dos valores das parcelas não é igual ao valor total.'
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = ''
                }, 3000);
                return;
            }

            // Exibe os dados completos do formulário
            console.log("Dados salvos:", this.form);

            // Exibe cada parcela no console
            this.parcelasDetalhadas.forEach((parcela, index) => {
                console.log(`Parcela ${index + 1}:`, parcela);
            });

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                // const response = await axios.post(`contas-a-receber/`, this.form, {
                //     headers: {
                //         Authorization: `Token ${token}`
                //     }
                // })

                // console.log(response.data)
                this.salvarParcelas(response.data.id)
            } catch (error) {
                console.error(error)
                this.erro = true
                this.erroMessage = 'Ocorreu um erro ao criar a conta.'
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = ''
                }, 3000);
            }
        },

        async salvarParcelas(id_conta) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {

                const formData = new FormData();
                formData.append('conta', id_conta);

                // const response = await axios.post(`contas-a-receber/parcelas/`, formData, {
                //     headers: {
                //         Authorization: `Token ${token}`
                //     }
                // })

                // console.log(response.data)
                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
            } catch (error) {
                console.error(error)
                this.erro = true
                this.erroMessage = 'Ocorreu um erro ao salvar as parcelas.'
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = ''
                }, 3000);
            }
        }

    },
};
</script>

<style scoped></style>