<template>

    <!-- formulario para mais de 768 pixels -->
    <section class="">
        <!-- Escopo do formulario de cadastro mobile-->
        <form @submit.prevent="alterarDadosProjeto" enctype="multipart/form-data">
            <div class="w-full mx-auto mt-8">
                <div class="space-y-4">

                    <div>
                        <div class="p-4 flex flex-col border border-corBase/60">
                            <div class="flex gap-5 pb-4">

                                <div class="w-full">
                                    <label class="font-bold text-corBase">Tempo de Produção (minutos)</label>
                                    <input type="number" min="0" v-model="tempo_producao">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex w-full gap-8 justify-end">
                <button class="btn-enviar" type="submit">Salvar</button>
            </div>
        </form>
    </section>

    <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso" />
    <listagem-erros v-if="exibirErros == true" :erros="erros" @fecharModal="toggleErros()" />
</template>


<script>
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import axios from "axios";
import Select2 from "vue3-select2-component";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";
import Search from "@/utils/Search.vue";

export default {
    name: 'VendasProjeto',

    components: {
        MensagemSucesso,
        Select2,
        SucessoRapido,
        ListagemErros,
        vSelect,
        Search
    },

    data() {
        return {
            projeto_id: '',
            tempo_producao: '',

            sucesso: false,

            exibirErros: false,
            erros: {}

        }
    },

    props: ['dadosProjeto'],

    created() {
        this.projeto_id = this.$route.params.id,
            this.defineVariaveisProjeto()
    },

    computed: {
        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },
    },

    watch: {
        sucesso() {
            if (this.sucesso) {
                setTimeout(() => {
                    this.sucesso = false
                }, 2000)
            }
        },

        sistemista(newValue) {
            if (newValue) {
                this.getListaLinhas(newValue)
            }

            if (!newValue) {
                this.linha = ''
                this.lista_linhas = []
            }
        },

        'modelo': {
            handler(novoModelo) {
                if (novoModelo) {
                    this.getListaTipologia(novoModelo);
                }
            },
            immediate: false
        }

    },

    methods: {

        limitarDigitosNCM() {
            let ncmString = String(this.ncm);

            // Remove caracteres inválidos como '-' ou 'e'
            ncmString = ncmString.replace(/[^0-9]/g, '');

            // Limita a 8 dígitos
            if (ncmString.length > 8) {
                ncmString = ncmString.slice(0, 8);
            }

            this.ncm = ncmString;

            // console.log('Valor ajustado do NCM:', this.ncm);
        },

        defineVariaveisProjeto() {
            this.ncm = this.dadosProjeto.ncm
            this.cfop_saida = this.dadosProjeto.cfop_saida
            this.cfop_fora_uf = this.dadosProjeto.cfop_fora_uf
        },

        async alterarDadosProjeto() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const updatedData = {
                    ncm: this.ncm,
                    cfop_saida: this.cfop_saida,
                    cfop_fora_uf: this.cfop_fora_uf,
                }

                await axios.patch(`projeto/projeto/${this.dadosProjeto.id}/`, updatedData, {
                    headers: {
                        'Authorization': `Token ${token}`
                    },
                })
                this.sucesso = true
            } catch (error) {
                console.error(error)
                if (error.response && error.response.data) {
                    this.erros = error.response.data;
                } else {
                    this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
                }
                this.toggleErros();
                this.$store.dispatch("setLoading", false);

            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

    }

}

</script>


<style scoped>
input{
    width: 100%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}
</style>