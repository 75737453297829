<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
            <p class="cursor-pointer" @click.prevent="$emit('fecharModal')">X</p>
            <p class="text-gray-700">Parametrizar</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ParametrizarProjetos',

    data() {
        return {

        }
    },

    props: [],
    emits: ['fecharModal'],

    methods: {

    },
}
</script>

<style scoped></style>