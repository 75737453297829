<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="px-3 py-3">#</th>
          <th scope="col" class="px-3 py-3">Perfil</th>
          <th scope="col" class="px-3 py-3">Descrição</th>
          <th scope="col" class="py-3">QTDE/Fórmula</th>
          <th scope="col" class="py-3">Medida/Fórmula</th>
          <th scope="col" class="py-3">Condição</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(element, index) in perfis" :key="index" class="escopo-tabela">
          <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap">
            {{ index + 1 }}
          </th>
          <td class="px-3 py-2 text-xs">
            {{ element.perfil_id.codigo_base }}
          </td>
          <td class="px-3 py-2 text-xs">
            {{ element.perfil_id.nome_base }}
          </td>
          <td class="py-2 text-xs w-[8vw]">
            <input class="input-campos" v-model="element.qtd_formula">
          </td>
          <td class="py-2 text-xs w-[8vw]">
            <input class="input-campos" v-model="element.medida_formula">
          </td>
          <td class="py-2 text-xs w-[8vw]">
            <input class="input-campos" v-model="element.condicao">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ListaPerfisParaEdicao',

  props: {
    perfis: Array,
  },
  methods: {
    atualizarUnicoPerfil(perfil, index) {
      // Lógica para atualizar perfil único
    }
  }
};
</script>

<style scoped>
.container-modelo-tabela {
  width: 100%;
  margin: auto;
}

.container-tabela {
  width: 100%;
  border-collapse: collapse;
}

.cabecalho-tabela th {
  text-align: left;
  background-color: #f9fafb;
  border-bottom: 1px solid #d1d5db;
}

.escopo-tabela td, 
.escopo-tabela th {
  border-bottom: 1px solid #d1d5db;
}

.input-campos {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}
</style>
